import {
    whiteColor,
    primaryColor
} from "assets/jss/material-dashboard-react.js";


const drawerWidth = 240;
const styles = theme => ({
    root: {
        display: 'flex',
    },

    inputStyle: {
        color: 'white',
        padding: 0,
        maxWidth: 60,
        borderBottom: '2px solid #3f51b5'
    },
    pdfControl: {
        flexDirection: "row",
        display: "flex",
        alignItems: 'center',
        color: "white",
        backgroundColor: 'rgba(0,0,0,1)',
        position: 'fixed',
        zIndex: '1',
        // width: '100%',
        bottom: 0,
        maxHeight: 35,
        justifyContent: "center",
        
    }



});

export default styles;
