import React from "react";
import moment from 'moment';

import CardMenu from "./CardMenu";

import IconButton from '@material-ui/core/IconButton';
//@material-ui/icons
import DescriptionIcon from '@material-ui/icons/Description';
import ForumTwoToneIcon from '@material-ui/icons/ForumTwoTone';
import PublishTwoToneIcon from '@material-ui/icons/PublishTwoTone';
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone';
import AssignmentTurnedInTwoToneIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';


const AssignmentCard = (props) => {
    const {
        data,
        onClickUpload,
        onClickFeedback,
        cdate
    } = props;

    const bgColor = ['subject_bg--green', 'subject_bg--orange', 'subject_bg--violet', 'subject_bg--attended']

    // handle download 
    const handleDownload = () => {
        if (Object.keys(data).length) {
            if (data.attemptAttachmentList) {
                const file = data.attemptAttachmentList[0];
                fileDowload(file)
            }
        }
    }

    // handle download answer key
    const handleDownloadAnswerkey = () => {
        if (Object.keys(data).length) {
            if (data.answerKeyList) {
                const file = data.answerKeyList[0];
                fileDowload(file)
            }
        }
    }

    // handle download question paper
    const handleDownloadQuestionPaper = () => {
        if (Object.keys(data).length) {
            if (data.attachmentList) {
                const file = data.attachmentList[0];
                fileDowload(file)
            }
        }
    }



    const fileDowload = (file) => {
        if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('downloadFile', file.fileUrl);
        } else {
            fetch(file.fileUrl)
                .then(res => res.blob()) // Gets the response and returns it as a blob
                .then(blob => {
                    let objectURL = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = objectURL;
                    document.body.appendChild(link);
                    link.setAttribute('download', file.fileName);
                    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                    // Remove link from body
                    document.body.removeChild(link);
                }).catch((error) => {
                    console.log(error.message)
                });
        }

    }


    let dueDate = moment(data.dueDate).format("YYYY-MM-DD")

    //OLD
    // let status__label = 'active';
    // let status_color = 'bg--green';

    // if (data.isSubmitted) {
    //     status__label = 'finish'
    //     status_color = 'bg--violet';
    // } else if (moment(cdate).isAfter(dueDate)) {
    //     status__label = 'OVERDUE'
    //     status_color = 'bg--orange';
    // }

    let status__label = '';
    let status_color = '';
    if (data.orderById === 1) {
        status__label = 'active'
        status_color = 'bg--green';
    } else if (data.orderById === 2) {
        status__label = 'OVERDUE'
        status_color = 'bg--orange';
    } else if (data.orderById === 3) {
        status__label = 'finish'
        status_color = 'bg--violet';
    }



    return (
        <a className="link__item">
            <div className="inner-wrapper-col">
                <div className="wrapper wrapper-1">
                    <div className="inner-wrapper">
                        <span className="meta title">{data.chapterName}</span>
                        <span className="meta name">{data.subjectName}: {data.topicName}</span>
                    </div>
                </div>
            </div>
            <div className="inner-wrapper-col">
                <div className="wrapper wrapper-4">
                    <div className={`new-status ${status_color}`}>
                        <span className="meta content__label">Status : </span>
                        <span className={`new-status__label`}><em>{status__label}</em>
                        </span>
                    </div>
                </div>
                <div className="wrapper wrapper-5">
                    <div className="inner-wrapper">
                        <span className="meta date date__start">
                            <span className="content__label">Start Date : </span>
                            {moment(data.startDate).format('DD/MM/YYYY hh:mm a')}
                        </span>
                    </div>
                </div>
                <div className="wrapper wrapper-4">
                    <div className="inner-wrapper">

                        <span className="meta date date__end">
                            <span className="content__label">End Date : </span>
                            {moment(data.dueDate).format('DD/MM/YYYY hh:mm a')}
                        </span>
                    </div>
                </div>
                <div className="wrapper wrapper-5">
                    <div className="inner-wrapper">
                        <span className="meta">
                            <span className="content__label">Marks : </span>
                            <span className="marks">{data.score}/{data.mark}</span>
                            {/* <em>Duration: 25 min</em> */}
                        </span>
                    </div>
                </div>
                <div className="wrapper wrapper-6">
                    <div className="btn--menu">
                        <CardMenu
                            data={data}
                            cdate={cdate}
                            dueDate={dueDate}
                            onDownloadQuestionPaper={handleDownloadQuestionPaper}
                            onDownloadSolvedPaper={handleDownload}
                            onUploadPaper={() => onClickUpload(data)}
                            onDownloadAnswerkey={handleDownloadAnswerkey}
                            onClickFeedback={() => onClickFeedback(data)}
                        />
                    </div>
                    <div className="btn--group">
                        <button type="button"
                            className="btn"
                            onClick={handleDownloadQuestionPaper}>
                            <DescriptionIcon className="btn--blue btn--icon me-1 svg" />
                            <span>question paper</span>
                        </button>
                        {data.isSubmitted && data.isScorePublish
                            ? <button type="button" className="btn" onClick={handleDownload}>
                                <GetAppTwoToneIcon className="btn--blue btn--icon me-1 svg" />
                                <span> download</span>
                            </button>
                            : data.isSubmitted && moment(cdate).isAfter(dueDate) ?
                                <button type="button" className="btn" onClick={handleDownload}>
                                    <GetAppTwoToneIcon className="btn--blue btn--icon me-1 svg" />
                                    <span> download</span>
                                </button>
                                : <button
                                    type="button"
                                    className={`btn ${moment(cdate).isAfter(dueDate) ? "disabled btn__disabled" : ""}`}
                                    onClick={() => onClickUpload(data)}>
                                    <PublishTwoToneIcon className={`btn--icon me-1 svg ${moment(cdate).isAfter(dueDate) ? "btn--disabled" : "btn--blue"}`} />
                                    <span>{data.isSubmitted ? 'reupload' : "Upload"}</span>
                                </button>
                        }
                        <button
                            type="button"
                            className={`btn ${(data.answerKeyList ? data.answerKeyList.length ? true : false : false) && moment(cdate).isAfter(dueDate) ? "" : "disabled btn__disabled"}`}
                            onClick={handleDownloadAnswerkey}>
                            <AssignmentTurnedInTwoToneIcon className={`btn--icon me-1 svg ${(data.answerKeyList ? data.answerKeyList.length ? true : false : false) && moment(cdate).isAfter(dueDate) ? "btn--blue" : "btn--disabled"}`} />
                            <span>answer key</span>
                        </button>

                        <button
                            type="button"
                            className={`btn  ${data.isSubmitted && data.isScorePublish ? "" : "disabled btn__disabled"}`}
                            onClick={() => onClickFeedback(data)} >
                            <ForumTwoToneIcon className={`btn--icon me-1 svg ${data.isSubmitted && data.isScorePublish ? "btn--red" : "btn--disabled"}`} />
                            <span>feedback</span>
                        </button>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default AssignmentCard