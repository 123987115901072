import * as actionTypes from 'store/definitions/actionTypes'

const initialState = {
    configs: {},
    error: false,
    loading: true,
}


const reducer = (state = initialState, action) => {
    switch (action.type) {

        // -- Fetch app configs
        case actionTypes.FETCH_APPCONFIGS_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SET_APPCONFIGS:
            return {
                ...state,
                configs: {
                    ...action.appConfigs
                },
                error: false,
                loading: false
            }
        case actionTypes.FETCH_APPCONFIGS_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }

        default:
            return state;
    }
}

export default reducer