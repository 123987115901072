import * as actionTypes from 'store/definitions/actionTypes'

const initialState = {
    userprogress: {
        questProgress: [],
        missionProgress: [],
        operationProgress: [],
        contentProgress: []
    },
    error: false,
    loading: true,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_PROGRESS_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SET_USER_PROGRESS:
            return {
                ...state,
                userprogress: action.userprogress,
                error: false,
                loading: false
            }
        case actionTypes.FETCH_USER_PROGRESS_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }
        default:
            return state;
    }

}


export default reducer