import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  nested_chapter_in:{
    paddingTop: '15px !important'
  },
  nested_chapter_out:{
    paddingTop: '15px !important',
    marginBottom: -15,
    transition: '0.50s all ease'
  },
  nested: {
    // paddingLeft: theme.spacing(6),
    paddingBottom: 0,
    paddingTop: '10px !important'
  },
  nestedL1: {
    // paddingLeft: theme.spacing(12),
     paddingBottom: 0,
     paddingTop: '10px !important'
  },
  chapter: {
    fontWeight: '500 !important',
    fontSize: '16px !important',
    fontFamily: 'Roboto, sans-serif',
    lineHeight: '1.2',
    marginBottom: 2,
    color: '#46494B',
    display: 'block'
  },
  topic:{
    fontWeight: '500 !important',
    fontSize: '14px !important',
    fontFamily: 'Roboto, sans-serif',
    lineHeight: '1.2',
    marginBottom: 2,
    color: '#46494B',
    display: 'block'
  },
  divider:{
    backgroundColor: '#46494B',
    marginTop: 15,
  },
  divider1:{
    backgroundColor: '#46494B',
    marginTop: 15,
    paddingBottom: 5
  },
  searchbarConatiner: {
    background: '#ed1c2424 0% 0% no-repeat padding-box',
    borderRadius: '6px 0px 0px 0px',
    padding: '20px  20px'
},
}));

export default useStyles;