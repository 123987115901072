import React, { useEffect, useState } from 'react';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import SettingsVoiceTwoToneIcon from '@material-ui/icons/SettingsVoiceTwoTone';
const MessagesIcon = ({ type }) => {
    if (type === 1)
        return (<EmailTwoToneIcon fontSize='small' />)
    if (type === 2)
        return (<SettingsVoiceTwoToneIcon fontSize='small' />)
    if (type === 3)
        return (<NotificationsActiveTwoToneIcon fontSize='small' />)
    return (<EmailTwoToneIcon fontSize='small' />)
}

export default MessagesIcon