import * as actionTypes from 'store/definitions/actionTypes'

const initialState = {

    master_course: [],
    master_course_error: false,
    master_course_loading: true,

    course_list: [
        {
            courseCode: "",
            courseName: "",
            endDate: "",
            isFree: true,
            startDate: "",
        }
    ],
    current_course: {},
    error: false,
    loading: true,

    post_assign_course_error: false,
    post_assign_course_loading: false

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //--Master Course List -------
        case actionTypes.FETCH_MASTERCOURSE_START:
            return {
                ...state,
                master_course_loading: true
            }

        case actionTypes.SET_MASTERCOURSE:
            return {
                ...state,
                master_course: action.mastercourse.course
            }

        //--- Assigned Course List-------
        case actionTypes.FETCH_USERCOURSE_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SET_USERCOURSE:
            return {
                ...state,
                course_list: action.usercourse.courseList,
                error: false,
                loading: false
            }
        case actionTypes.FETCH_USERCOURSE_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }
        case actionTypes.SET_CURRENT_COURSE:
            return {
                ...state,
                error: false,
                loading: false,
                current_course: action.current_course
            }
        // ------- Post Assign Course -------------
        case actionTypes.POST_ASSIGN_COURSE_START:
            return {
                ...state,
                post_assign_course_error: false,
                post_assign_course_loading: true
            }

        case actionTypes.POST_ASSIGN_COURSE_SUCCESS:
            return {
                ...state,
                post_assign_course_error: false,
                post_assign_course_loading: false
            }

        case actionTypes.POST_ASSIGN_COURSE_FAILED:
            return {
                ...state,
                post_assign_course_error: true,
                post_assign_course_loading: false
            }

        default:
            return state;
    }

}


export default reducer