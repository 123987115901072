import React, { useEffect, useState } from "react";

//Redux
import { connect } from 'react-redux'

import { Doughnut } from 'react-chartjs-2';

//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

//Custom Components
import GridItem from "shared/components/Grid/GridItem.js";
import GridContainer from "shared/components/Grid/GridContainer.js"
import Body from "shared/components/Card/CardBody.js";
import Button from "shared/components/CustomButtons/Button.js";


// styles
import Styles from "assets/jss/components/views/assessmentStyle";
const useStyles = makeStyles(Styles);


const QuestionAnswers = (props) => {
    const classes = useStyles();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    // console.log(props.test);
       
    const data = {
        datasets: [{
            data: [props.test.skipQuestionCount,props.test.rightAnswerCount,props.test.wrongAnswerCount],
            backgroundColor: ['#F574AC', '#51D887', '#EEC833']
        }],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: ['Skip', 'Correct', 'Incorrect'],
    };

    const legend = {
        position: "bottom"
    }

    useEffect(() => {
        if (props.test.testAttemptedDuration > 0) {
            let totalSeconds = Number(props.test.testAttemptedDuration);
            totalSeconds %= 3600;
            const newMinutes = Math.floor(totalSeconds / 60);
            const newSeconds = totalSeconds % 60;
            setMinutes(newMinutes)
            setSeconds(newSeconds)
        }
    }, [])

    return (
        <React.Fragment>
            <GridContainer>
                <GridItem xs={12} sm={12} md={10} className={classes.centerContainer}>
                    {props.loading
                        ? <Skeleton className={classes.centerContainer} animation="wave" variant="circle" width={'15rem'} height={'15rem'} />
                        : <Doughnut data={data} legend={legend} />
                    }
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={classes.centerContainer} >
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        <div style={{width:props.loading ?'100%':""}}>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Correct : ${props.test.rightAnswerCount}`}
                            </Typography>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Incorrect : ${props.test.wrongAnswerCount}`}
                            </Typography>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Skipped : ${props.test.skipQuestionCount}`}
                            </Typography>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Score : ${props.test.marksObtained + " / " + props.test.totalMarks}`}
                            </Typography>
                            <Typography variant="body2" style={{ fontWeight: "bold" }} gutterBottom={true}>
                                {props.loading ? <Skeleton /> : `Time Taken : ${minutes + ":" + (seconds < 10 ? `0${seconds}` : seconds)} minutes`}
                            </Typography>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </React.Fragment>
    )
}


const mapSateToProps = state => {
    return {
        loading: state.questions.post_test_loading,
        test: state.content.current_test,
    }
}

const mapDispatchToProps = dispatch => {
    return {


    }
}


export default connect(mapSateToProps, mapDispatchToProps)(QuestionAnswers);
