import * as actionTypes from '../actionTypes'

const initialState = {
    todyasOnlineClasses: [],
    upcomingOnlineClasses: [],
    pastOnlineClasses: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TODAYS_ONLINE_CLASSES:
            return {
                ...state,
                todyasOnlineClasses: action.schedule
            }
        case actionTypes.SET_PAST_ONLINE_CLASSES:
            return {
                ...state,
                pastOnlineClasses: action.schedule
            }
        case actionTypes.SET_UPCOMING_ONLINE_CLASSES:
            return {
                ...state,
                upcomingOnlineClasses: action.schedule
            }
        default:
            return state;
    }

}


export default reducer