import { fade, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    height: 32,
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },

    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #E8E8E8',
    borderRadius: '40px',
    opacity: 1
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0000003b',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    letterSpacing: '0.25px',
    color: '#00000099',
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    // [theme.breakpoints.up('md')]: {
    //   width: '20ch',
    // },
  },
}));

export default useStyles;