import React, { useEffect, useState } from 'react';
// nodejs library to set properties for components
import classNames from "classnames";
import PropTypes from "prop-types";
import moment from 'moment'
//@material-ui/core
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
//components
import Searchbar from "components/Views/Messaging/components/Searchbar/Searchbar";
//@material-ui/icons
import AttachmentIcon from '@material-ui/icons/Attachment';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import MessagesIcon from './MessagesIcon'
// custom style
import useStyles from "./styles/messagesCardStyle";

const SkeletonCard = (props) => {
    const classes = useStyles();
    return (<React.Fragment>
        {['SK0011', 'DK0101', 'PL0111', 'LK1111'].map((v, i) =>
            <Card className={classes.cardStyle} key={v}>
                <CardContent>
                    <Skeleton animation="wave" variant="rect" />
                    <div style={{ height: 5 }}></div>
                    <Skeleton animation="wave" variant="rect" height={30} />
                </CardContent>

            </Card>
        )}
    </React.Fragment>
    )
}

const Messages = (props) => {
    const classes = useStyles();
    const { message,
        onClickRow,
        activeId,
        onClickStar
    } = props;

    const activeMessage = classNames({
        [classes.cardStyle]: true,
        [classes.avctiveCard]: true
    });

    const unreadMessage = classNames({
        [classes.cardStyle]: true,
        [classes.unreadCard]: true
    });


    const messageClass = message.messageId === activeId
        ? activeMessage
        : message.isRead
            ? classes.cardStyle
            : unreadMessage

    return (
        <Card
            key={"CARD" + message.messageId}
            className={messageClass}
        >

            <CardActionArea onClick={() => onClickRow ? onClickRow(message) : null}>
                <CardContent className={classes.cardContent}>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>

                            <div className={classes.senderContainer}>
                                <Avatar className={classes.small}>
                                    <MessagesIcon type={message.typeId}></MessagesIcon>
                                </Avatar>
                                <Typography variant="caption" className={classes.senderName} >
                                    {message.firstName + " " + message.lastName}
                                </Typography>

                            </div>
                            <Typography variant="caption" style={{ fontWeight: '500' }}  >
                                {`${moment(message.sendOn).format('DD-MM-YYYY')}`}
                            </Typography>
                            {/* {message.attachmentList && <AttachmentIcon color='secondary' />} */}
                        </div>

                        <div className={classes.subjectContainer}>
                            <Typography variant="subtitle2" className={classes.subject} >
                                {message.subject}
                            </Typography>
                        </div>

                        <div className={classes.messageBodyContainer}>
                            <Typography variant="subtitle2" className={classes.messageText} >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: message.messageBody
                                    }}
                                ></div>
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </CardActionArea>
            {message.typeId !== 6 &&
                <IconButton aria-label="star" className={classes.buttonStar} onClick={() => onClickStar(message)}>
                    {message.isStarred
                        ? <StarIcon fontSize="small" style={{ color: '#ED1C24' }} />
                        : <StarBorderIcon fontSize="small" />
                    }
                </IconButton>}
        </Card>
    )
}



const MessagesCard = (props) => {
    const classes = useStyles();
    const { loading,
        data,
        noDataMessage,
        onClickRow,
        onChangeStarStatus,
        activeId,
        onSerch
    } = props;


    const [isStarFilter, setStarFilter] = useState(false)

    const btnInboxFilter = classNames({
        [classes.filterButton]: true,
        [classes.activeButton]: !isStarFilter
    });

    const btnStarredFilter = classNames({
        [classes.filterButton]: true,
        [classes.activeButton]: isStarFilter
    });

    if (loading) {
        return (<SkeletonCard></SkeletonCard>)
    }


    return (
        <React.Fragment>


            <div className={classes.searchbarConatiner}>
                <Searchbar inputChange={onSerch} />
            </div>

            <div className={classes.fliterButtonContainer}>
                <Button className={btnInboxFilter} size='small' onClick={() => setStarFilter(false)}>Inbox</Button>
                <Button className={btnStarredFilter} size='small' onClick={() => setStarFilter(true)}>Starred</Button>
            </div>


            <div style={{ height: 'calc(100vh - 150px)', overflowY: 'scroll' }}>
                {data.length ?
                    isStarFilter
                        ? data.filter(msg => msg.isStarred).map((message, idex) => (
                            <Messages
                                key={message.mappingId}
                                message={message}
                                onClickRow={onClickRow}
                                activeId={activeId}
                                onClickStar={onChangeStarStatus}
                            ></Messages>
                        ))
                        : data.map((message, idex) => (
                            <Messages
                                key={message.mappingId}
                                message={message}
                                onClickRow={onClickRow}
                                onClickStar={onChangeStarStatus}
                                activeId={activeId}
                            ></Messages>
                        ))
                    : <Card className={classes.cardStyle} style={{ height: '100%' }}>
                        <CardContent style={{ justifyContent: "center", display: 'flex' }}>
                            <Typography variant="body1" color="textSecondary">
                                {noDataMessage}
                            </Typography>

                        </CardContent>
                    </Card>
                }
            </div>


        </React.Fragment>
    );
}

MessagesCard.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array,
    noDataMessage: PropTypes.string,
    onClickRow: PropTypes.func
};

export default MessagesCard;