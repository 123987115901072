import React, { useEffect, useState, Suspense, lazy } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

// @material-ui/core
import { useTheme } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';


//Custom Components
import GridItem from "shared/components/Grid/GridItem.js";
import GridContainer from "shared/components/Grid/GridContainer.js"
import OperationCards from 'components/Views/Tasks/ContentTree/ContentTree'

//View
import SubjectsTab from 'components/Views/Tasks/Subjects/SubjectsTab'

// styles
import useStyles from "assets/jss/components/views/taskStyle";
import './style.css'

const ContentViewer = lazy(() => import('components/Views/Tasks/ContentViewer/ContentViewer'));


const Operations = (props) => {
    const { page__title } = props
    const dispatch = useDispatch()
    const classes = useStyles();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const [fullscreen, setFullscreen] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState('');

    const userId = useSelector(state => state.user.userinfo.userId)
    const courseList = useSelector(state => state.course.course_list);
    const contentList = useSelector(state => state.content.contentlist)
    const content = useSelector(state => state.content.current_content)

    const contentLoading = useSelector(state => state.content.loading)
    const contentError = useSelector(state => state.content.error)

    const setScreen = isFullscreen => {
        setFullscreen(isFullscreen)
    }

    useEffect(() => {
        if (page__title)
            page__title('courses content')
        dispatch(questActions.clearCurrentContent())
        const storedCourse = localStorage.getItem('selectedCourse');
        if (storedCourse && courseList.find(course => course.courseCode === storedCourse)) {
            setSelectedCourse(storedCourse);
        } else {
            setSelectedCourse(courseList[0]?.courseCode || '');
        }
    }, [])

    useEffect(() => {
        if (contentError === false && contentLoading === false)
            dispatch(questActions.fetchSessionRecordingStructure())
    }, [contentLoading])

    // const handleCourseChange = (event) => {
    //     setSelectedCourse(event.target.value);
    // };

    /*----------------------- Change Current Course--------------------*/
    const handleCourseChange = (event) => {
        const selectedCourseCode = event.target.value;
        setSelectedCourse(selectedCourseCode);
        localStorage.setItem('selectedCourse', selectedCourseCode);
        const selectedCourse = courseList.find(course => course.courseCode === selectedCourseCode);
        dispatch(questActions.setCurrentCourse(selectedCourse));
        
        dispatch(questActions.initContentList(selectedCourseCode, userId));
    }
    

    return (
        <div className="page-content__body" style={{ padding: 0, paddingTop: 48 }}>
            <div style={{ marginLeft: 15 , marginTop: -8 }}>
                <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-simple-select-label">Select Course</InputLabel> */}
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCourse}
                        onChange={handleCourseChange}
                        placeholder="Select Course"
                    >
                        {courseList.map((course) => (
                            <MenuItem key={course.courseId} value={course.courseCode}>
                                {course.courseName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {/* <div className="component shadow "> */}

                {!fullscreen && <SubjectsTab
                    contentList={contentList}
                    onSubjectChange={setScreen}
                />}
                {sm
                    ? <GridItem xs={12} sm={12} md={4} className="operation-container">
                        <div className={`${classes.operationCardContainer}`}>
                            <OperationCards contentList={contentList}  ></OperationCards>
                        </div>
                    </GridItem>
                    : <GridContainer className={classes.gridContainer}>
                        {fullscreen
                            ? null
                            : <GridItem xs={12} sm={12} md={3} className="operation-container">
                                <div className={`${classes.operationCardContainer}`}>
                                    <OperationCards contentList={contentList}  ></OperationCards>
                                </div>
                            </GridItem>
                        }
                        <GridItem xs={12} sm={12} md={fullscreen ? 12 : 9} className={fullscreen ? 'topics-container-fullscreen' : "topics-container"} >
                            <div className={fullscreen ? classes.fullscreenContainer : `${classes.vedioContainer} `}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ContentViewer
                                        contentType={content.contentTypeCode}
                                        platform='web'
                                        navigation={false}
                                        wrapperStyle={true}
                                        fullscreen={fullscreen}
                                        setFullscreen={setScreen}
                                    />
                                </Suspense>
                            </div>
                        </GridItem>
                    </GridContainer>
                }
            </div>
        // </div>
    )
}


export default Operations;
