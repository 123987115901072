
//Messaging
export const SET_MESSAGE_AND_ANNOUNCEMENTS = "SET_MESSAGE_AND_ANNOUNCEMENTS"

// new
export const SET_INBOX_MESSAGES = "SET_INBOX_MESSAGES"
export const SET_CURRENRT_INBOX_MESSAGES = "SET_CURRENRT_INBOX_MESSAGES"
export const SET_SENT_MESSAGES = "SET_SENT_MESSAGES"
export const SET_RECIPIENT_LIST = "SET_RECIPIENT_LIST"
export const SET_ANNOUNCEMENTS = "SET_ANNOUNCEMENTS"





