import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
//import Custom Components
import GridItem from "shared/components/Grid/GridItem.js";
import GridContainer from "shared/components/Grid/GridContainer.js"
import Button from "shared/components/CustomButtons/Button.js";
import { TextInput } from "shared/components/Inputs/Inputs"
import Typography from '@material-ui/core/Typography';

import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';

// custom style
import useStyles from "assets/jss/components/views/signupStyle";

//validations
import { checkNull, checkMobileNumber, checkEmail } from 'shared/utils/validations'

//Redux
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'store/actions/index'

//util
import { hrefUrl, eventTracking } from 'shared/utils/commonfunctions'


const FLName = ({ onNext }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const configs = useSelector(state => state.appconfigs.configs);
  let history = useHistory();
  const ref = React.createRef();

  const [state, setState] = useState({
    contactNumber: '',
    firstName: "",
    lastName: "",
    email: "",
    pincode: "",

    erroremail: "",
    errorpincode: "",
    errorcontactNumber: "",
    errorfirstName: "",
    errorlastName: ""
  })

  const [isMobileValidate, setMobileValidation] = useState(false)
  const [isOTPValidate, setOTPValidation] = useState(false) //fffffffffffffffff

  const [getOTPLoading, setGetOTPLoading] = useState(false)
  const [registerLoading, setRegisterLoading] = useState(false)

  const [varifyOTPLoading, setVarifyOTPLoading] = useState(false)
  const [verificationCode, setVerificationCode] = useState("");
  const [errorverificationCode, setErrorVerificationCode] = useState("");

  const [alert, setAlert] = useState({ message: '', type: "" })

  useEffect(() => {
    dispatch(actions.setInitialUser())
  }, [dispatch])


  const handleGetOTP = async () => {
    let validationObject = {};
    checkNull("contactNumber", state.contactNumber, "Mobile number is required", validationObject);
    checkMobileNumber("contactNumber", state.contactNumber, "Mobile number is not valid", validationObject);
    if (Object.keys(validationObject).length != 0) {
      setState({
        ...state,
        errorcontactNumber: "",
        ...validationObject
      });
    } else {
      setState({ ...state, errorcontactNumber: "" });
      setGetOTPLoading(true)
      dispatch(actions.requestForOTP(state.contactNumber)).then(() => {
        //Event Tracking
        eventTracking("GetOTP", { 'MobileNumber': state.contactNumber, 'Result': "Success" })

        setMobileValidation(true)
        setGetOTPLoading(false)
      }).catch(error => {
        //Event Tracking
        eventTracking("GetOTP", { 'MobileNumber': state.contactNumber, 'Result': "Fail" })

        setGetOTPLoading(false)
        setAlert({ type: 'error', message: error.message })
      });
    }
  }

  const handleNumberEdit = () => {
    dispatch(actions.setInitialUser())
    setMobileValidation(false);
    setOTPValidation(false);
    setVerificationCode("")
    clearInputs({
      contactNumber: '',
      firstName: "",
      lastName: "",
      email: "",
      pincode: "",
    })
  }

  const handleOTPVerifiation = async () => {
    let validationObject = {};
    checkNull("verificationCode", verificationCode, "OTP is required", validationObject);
    if (Object.keys(validationObject).length != 0) {
      setErrorVerificationCode(validationObject.errorverificationCode);
    } else {
      setVarifyOTPLoading(true)
      dispatch(actions.VerifyOTP(verificationCode, state.contactNumber)).then(() => {
        //Event Tracking
        eventTracking("VerifyOTP", { 'MobileNumber': state.contactNumber, 'Result': "Success" })

        setOTPValidation(true)
        setVarifyOTPLoading(false)
      }).catch(error => {
        //Event Tracking
        eventTracking("VerifyOTP", { 'MobileNumber': state.contactNumber, 'Result': "Fail" })

        setVarifyOTPLoading(false)
        setAlert({
          type: 'error',
          message: 'Invalid OTP'
        })
      });
    }
  }


  const clearAlert = () => {
    setAlert({ message: '', type: "" })
  }

  const handleRegisterUser = async () => {
    let validationObject = {};
    checkNull("firstName", state.firstName, "First Name is required", validationObject);
    checkNull("lastName", state.lastName, "Last Name is required", validationObject);
    checkNull("email", state.email, "Email is required", validationObject);
    checkNull("pincode", state.pincode, "Pincode is required", validationObject);

    checkEmail("email", state.email, "Email is not valid", validationObject)
    if (Object.keys(validationObject).length != 0) {
      clearInputs(validationObject)
    } else {
      await clearInputs()
      dispatch(actions.setRegistration({ ...state, otpno: verificationCode }))

      setRegisterLoading(true)
      dispatch(actions.GetPincodeInfo(state.pincode)).then(() => {
        selfRegistration()
      }).catch(error => {
        setRegisterLoading(false)
        setAlert({ type: 'error', message: error.message })
        setState({ ...state, errorpincode: error.message });
      });
    }
  }

  const selfRegistration = () => {
    dispatch(actions.registerNewUser()).then(() => {
      //Event Tracking
      eventTracking("Registration", { 'MobileNumber': state.contactNumber, 'Result': "Success" })
      setRegisterLoading(false)
      onNext()
    }).catch(error => {
      //Event Tracking
      eventTracking("Registration", { 'MobileNumber': state.contactNumber, 'Result': "Fail" })
      setRegisterLoading(false)
      setAlert({ type: 'error', message: error.message })
    });
  }


  const clearInputs = (validationObject = {}) => {
    setState({
      ...state,
      erroremail: "",
      errorpincode: "",
      errorcontactNumber: "",
      errorfirstName: "",
      errorlastName: "",
      ...validationObject
    });
  }

  return (
    <div style={{ width: '100%', padding: '0px 30px' }}>
      <GridContainer >
        <GridItem xs={12} sm={6} md={6}>
          <TextInput
            required
            disabled={isMobileValidate ? true : false}
            type="number"
            className={classes.number}
            id="MOBILE_NUMBER"
            autoFocus={true}
            label="Your Mobile Number"
            value={state.contactNumber}
            errorText={state.errorcontactNumber}
            onChange={(e) => setState({ ...state, contactNumber: e.target.value })}
            InputProps={{
              startAdornment: <InputAdornment position="start" style={{ paddingBottom: 5 }}>+91</InputAdornment>,
              endAdornment: <React.Fragment>
                {isMobileValidate && <Tooltip title="Edit Mobile No.">
                  <IconButton size="small" onClick={handleNumberEdit}>
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>}
              </React.Fragment>,
            }}
            onInput={(e) => {
              e.target.value = e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : ""
            }}
          />
        </GridItem >
        <GridItem xs={12} sm={6} md={6}>
          {isMobileValidate
            ? <TextInput
              required
              label="One Time Password"
              disabled={isOTPValidate ? true : false}
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              errorText={errorverificationCode}
              InputProps={{
                endAdornment: <Tooltip title="Resend OTP">
                  <IconButton size="small" onClick={handleGetOTP}>
                    <SyncIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>,
              }}
            />
            : <div className={classes.getOTPBtnContainer} >
              <Button
                fullWidth
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={handleGetOTP}
                disabled={getOTPLoading}
              >
                {getOTPLoading
                  ? 'Please Wait'
                  : 'Get OTP'
                }
              </Button>

              {getOTPLoading &&
                <div className={classes.buttonProgress}>
                  <CircularProgress size={15} style={{ color: 'white' }} />
                </div>}
            </div>
          }
        </GridItem>

        {!isOTPValidate && <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
          <div className={classes.verifyOTPButton} >
            <Button
              variant="contained"
              color="primary"
              onClick={handleOTPVerifiation}
              className={classes.button}
              disabled={!isMobileValidate || varifyOTPLoading || verificationCode.length < 6}
            >
              {varifyOTPLoading
                ? 'Please Wait'
                : 'Verify OTP'
              }
            </Button>
            {varifyOTPLoading &&
              <div className={classes.buttonProgress}>
                <CircularProgress size={15} style={{ color: 'white' }} />
              </div>}
          </div>
        </GridItem>}
      </GridContainer>

      {isOTPValidate && <GridContainer >
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer >
            <GridItem xs={12} sm={6} md={6} style={{ paddingTop: 5 }}>
              <TextInput
                label="Child's First Name"
                value={state.firstName}
                errorText={state.errorfirstName}
                onChange={(e) => setState({ ...state, firstName: e.target.value })}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6} style={{ paddingTop: 5 }}>
              <TextInput
                label="Child's Last Name"
                value={state.lastName}
                errorText={state.errorlastName}
                onChange={(e) => setState({ ...state, lastName: e.target.value })}
              />
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} style={{ paddingTop: 5 }}>
          <TextInput
            label="Your Email Id"
            value={state.email}
            errorText={state.erroremail}
            onChange={(e) => setState({ ...state, email: e.target.value })}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={6} style={{ paddingTop: 5 }}>
          <TextInput
            type="number"
            className={classes.number}
            label="Pincode"
            value={state.pincode}
            errorText={state.errorpincode}
            onChange={(e) => setState({ ...state, pincode: e.target.value })}
            onInput={(e) => {
              e.target.value = e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 6) : ""
            }}
          />
        </GridItem>


        <GridItem xs={12} sm={12} md={12} className={classes.actionsGridItem}>
          <div className={classes.actionsContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={registerLoading}
              onClick={handleRegisterUser}
            >
              {registerLoading
                ? 'Please Wait'
                : 'verify & proceed'
              }

            </Button>
            {registerLoading &&
              <div className={classes.buttonProceed}>
                <CircularProgress size={15} style={{ color: 'white' }} />
              </div>}
          </div>
        </GridItem>
      </GridContainer >}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', padding: 10 }}>
          <Typography note="true" variant="caption" paragraph display="inline" style={{ letterSpacing: 'normal' }}>
            {'Already a user? '}
            <span onClick={() => history.replace('/')} className={classes.hyperText}>Login here</span>
          </Typography>
        </GridItem>
      </GridContainer>

      <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{textAlign: 'center' }}>
                    <Typography note="true" variant="caption" paragraph display="inline" style={{ letterSpacing: 'normal' }}>
                        {'By Booking a demo, you agree to our '}
                        <span onClick={() => hrefUrl(configs.tandcurl)} className={classes.hyperText}>Terms and Conditions</span>
                        {' and that you have read our '}
                        <span className={classes.hyperText} onClick={() => hrefUrl(configs.prvpolurl)}>Privacy Policy.</span>
                    </Typography>
                </GridItem>
            </GridContainer>


      <Snackbar open={alert.message.length !== 0 ? true : false} autoHideDuration={6000} onClose={clearAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        {alert.message.length !== 0
          ? <MuiAlert elevation={6} variant="filled" onClose={clearAlert} severity={alert.type}>
            {alert.message}
          </MuiAlert>
          : null
        }
      </Snackbar>

    </div>

  )
}

export default FLName;