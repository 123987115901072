import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  addIcon: {
    // marginRight: theme.spacing(1),
  },
  mainContainer: {
    // maxHeight: '78.5vh',
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'calc(100vh - 205px)',
    },
  },
  gridContainerStyle: {
    margin: 0,
    height: '100%',
    overflowY: 'overlay',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      //   height: '60vh',
    },
  },
  gridItemStyle: {
    padding: 0
  },
}));

export default useStyles;