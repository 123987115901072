import React, { useEffect, useState, lazy, Suspense } from "react";
import moment from 'moment';
//@material-ui/core
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
//@material-ui/icons
import DescriptionIcon from '@material-ui/icons/Description';
import ForumTwoToneIcon from '@material-ui/icons/ForumTwoTone';
import PublishTwoToneIcon from '@material-ui/icons/PublishTwoTone';
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone';
import AssignmentTurnedInTwoToneIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
// core components
import GridItem from "shared/components/Grid/GridItem.js";
import GridContainer from "shared/components/Grid/GridContainer.js"
import PageNavigation from 'shared/components/Navbars/PageNavigation'
import PDFView from 'shared/components/PDFView/PDFView'


const AssignmentFeedback = lazy(() => import('components/Views/AssignmentFeedback'));
const AssignmentUpload = lazy(() => import('components/Views/AssignmentUpload'));
const SkeletonCard = lazy(() => import('./components/SkeletonCard'));

const PDFViewer = (props) => {

    const { navigation, wrapperStyle } = props;

    const dispatch = useDispatch()
    const content = useSelector(state => state.content.current_content)
    const assignment = useSelector(state => state.assignments.current_assignment)

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({ open: false, severity: "success", message: "" })

    const [feedbackDialog, setFeedbackDialog] = useState(false)
    const [uploadDialog, setuploadDialog] = useState(false)
    const [fileURL, setFileURL] = useState("")

    const [cdate, setCdate] = useState(moment().format("YYYY-MM-DD"))
    const [dueDate, setDueDate] = useState(moment().format("YYYY-MM-DD"))

    const [isPdfLoadSuccess, setPdfLoadSuccess] = useState(false)

    useEffect(() => {
        fetchCurrentAssignment()
    }, [dispatch])

    const fetchCurrentAssignment = () => {
        setLoading(true)
        dispatch(questActions.fetchCurrentAssignment(content.assignmentId)).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error);
        });
    }

    useEffect(() => {
        if (Object.keys(assignment).length) {
            setDueDate(moment(assignment.dueDate).format("YYYY-MM-DD"))
            if (assignment.attachmentList)
                setFileURL(assignment.attachmentList[0].fileUrl)
            else
                setFileURL("")
        }

    }, [assignment])

    // useEffect(() => {
    //     let accessTime = 0;
    //     const timer = setInterval(() => {
    //         accessTime++
    //     }, 1000);
    //     return () => {
    //         if (accessTime > 10) {
    //             dispatch(questActions.postContentAccess(content, accessTime))
    //         }
    //         clearTimeout(timer)
    //     };
    // }, []);

    // handle click feedback 
    const handleFeedback = () => {
        setFeedbackDialog(true)
    }

    // handle click upload 
    const handleUpload = () => {
        setuploadDialog(true)
    }

    // close Dialogs
    const closeDialogs = (isSuccess) => {
        if (isSuccess === false) {
            setAlert({ open: true, severity: 'error', message: "something went wrong!" })
        } else {
            setuploadDialog(false)
            setFeedbackDialog(false)
            if (isSuccess === true) {
                fetchCurrentAssignment()
                setAlert({ open: true, severity: 'success', message: "assignment submit successfully" })
            }
        }
    }

    // handle Answe rKey 
    const handleAnswerKey = () => {
        if (Object.keys(assignment).length) {
            if (assignment.answerKeyList)
                setFileURL(assignment.answerKeyList[0].fileUrl)
        }
    }


    // handle download 
    const handleDownload = () => {
        if (Object.keys(assignment).length) {
            if (assignment.attemptAttachmentList) {
                const file = assignment.attemptAttachmentList[0];
                if (typeof window.flutter_inappwebview !== 'undefined') {
                    window.flutter_inappwebview.callHandler('downloadFile', file.fileUrl);
                } else {
                    fetch(file.fileUrl)
                        .then(res => res.blob()) // Gets the response and returns it as a blob
                        .then(blob => {
                            let objectURL = URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = objectURL;
                            document.body.appendChild(link);
                            link.setAttribute('download', file.fileName);
                            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                            // Remove link from body
                            document.body.removeChild(link);
                        }).catch((error) => {
                            console.log(error.message)
                        });
                }

            }

        }
    }


    // handle Alert Close
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, severity: "success", message: "" })
    }


    if (loading) {
        return (
            <div className="center-container">
                {navigation && <PageNavigation logoText={content.courseContentDisplayName} />}
                <div className="wrapper wrapper-3" style={{ paddingTop: wrapperStyle ? 0 : 60, position: 'fixed', height: 40, zIndex: 2, backgroundColor: '#ffff' }}>
                    <Suspense fallback={<div > Loading...</div>}>
                        <SkeletonCard />
                    </Suspense>
                </div>
            </div>
        )
    }

    return (
        <div className="center-container">
            {/* Dialog */}
            <Dialog open={feedbackDialog || uploadDialog} maxWidth="sm" fullWidth={true} >
                {feedbackDialog && <Suspense fallback={<div > Loading...</div>}>
                    <AssignmentFeedback onClose={closeDialogs} data={assignment} />
                </Suspense>}
                {uploadDialog && <Suspense fallback={<div > Loading...</div>}>
                    <AssignmentUpload onClose={closeDialogs} data={assignment} />
                </Suspense>}
            </Dialog>

            {/*-----PageNavigation----- */}
            {navigation && <PageNavigation logoText={content.courseContentDisplayName} />}

            {isPdfLoadSuccess &&
                <div className="wrapper wrapper-3"
                    style={{
                        marginTop: wrapperStyle ? 0 : 52,
                        position: 'fixed',
                        height: 45,
                        zIndex: 2,
                        backgroundColor: '#ffff',
                        width: wrapperStyle ? 0 : '100%',
                    }}>

                    <div className="component-assessment">
                        <div className="btn--group">
                            <button type="button" className="btn"                           >
                                <DescriptionIcon className="btn--blue btn--icon me-1 svg" />
                                <span>question paper</span>
                            </button>

                            {assignment.isSubmitted && assignment.isScorePublish
                                ? <button type="button" className="btn" onClick={handleDownload}>
                                    <GetAppTwoToneIcon className="btn--blue btn--icon me-1 svg" />
                                    <span> download</span>
                                </button>
                                : assignment.isSubmitted && moment(cdate).isAfter(dueDate) ?
                                    <button type="button" className="btn" onClick={handleDownload}>
                                        <GetAppTwoToneIcon className="btn--blue btn--icon me-1 svg" />
                                        <span> download</span>
                                    </button>

                                    : <button type="button"
                                        className={`btn ${moment(cdate).isAfter(dueDate) ? "disabled btn__disabled" : ""}`}
                                        onClick={handleUpload}>
                                        <PublishTwoToneIcon className={`btn--icon me-1 svg ${moment(cdate).isAfter(dueDate) ? "btn--disabled" : "btn--blue"}`} />
                                        <span>{assignment.isSubmitted ? 'reupload' : "Upload"}</span>
                                    </button>
                            }

                            <button
                                type="button"
                                className={`btn ${assignment.isSubmitted && moment(cdate).isAfter(dueDate) ? "" : "disabled btn__disabled"}`}
                                onClick={handleAnswerKey}>
                                <AssignmentTurnedInTwoToneIcon className={`btn--icon me-1 svg ${assignment.isSubmitted && moment(cdate).isAfter(dueDate) ? "btn--blue" : "btn--disabled"}`} />
                                <span>answer key</span>
                            </button>

                            <button
                                type="button"
                                className={`btn  ${assignment.isSubmitted && assignment.isScorePublish ? "" : "disabled btn__disabled"}`}
                                onClick={handleFeedback} >
                                <ForumTwoToneIcon className={`btn--icon me-1 svg ${assignment.isSubmitted && assignment.isScorePublish ? "btn--red" : "btn--disabled"}`} />
                                <span>feedback</span>
                            </button>
                        </div>
                    </div>
                </div>
            }

            {/*-----page-container----- */}
            <div className={`${wrapperStyle ? `content-container-task ${isPdfLoadSuccess ? 'toppadding' : ''}` : 'assignment-viewer'}`}            >
                <PDFView
                    fileurl={fileURL}
                    password=""
                    onPdfLoad={setPdfLoadSuccess}
                />
            </div>


            {/* Snackbar */}
            <Snackbar open={alert.open} autoHideDuration={2000} onClose={handleAlertClose}>
                {alert.open
                    ? <Alert elevation={6} onClose={handleAlertClose} severity={alert.severity} >
                        {alert.message}
                    </Alert>
                    : null
                }
            </Snackbar>
        </div>
    );
}


PDFViewer.defaultProps = {
    navigation: true,
    wrapperStyle: false,
};

export default PDFViewer;