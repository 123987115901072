import {
  dangerColor,
  hyperText
} from "assets/jss/material-dashboard-react.js";
//.....styles..........
const signinStyle = theme => ({
  container:{
    
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    // paddingTop: theme.spacing() * 5,
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      backgroundColor:'#fff',
      width: "50%",
      minHeight: 'inherit',
      marginLeft: 'auto',
      marginRight:0,
    },
  },
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing() * 5,
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      minHeight: 550,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    textAlign: 'center',
  },
  paper: {
    borderRadius: 12,
    // boxShadow: '0px 8px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: `${theme.spacing() * 2}px ${theme.spacing() * 3}px ${theme.spacing() * 3}px`,
    padding: `${theme.spacing() * 6}px ${theme.spacing() * 6}px ${theme.spacing() * 6}px`,
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      boxShadow:'none'
    }
  },
  avatar: {

    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    // maxWidth: '328px',
    width: '100%', // Fix IE11 issue.
    // marginTop: theme.spacing(),
  },
  submit: {
    borderRadius: 20,
    // maxWidth: '328px',
    maxHeight: '48px',
    marginTop: theme.spacing() * 3,
    textTransform: 'none'
  },
  close: {
    padding: theme.spacing() / 2,
  },
  hyperText,
  labelRootError: {
    color: dangerColor[0],
    marginTop: 0,
    position: 'absolute',
    bottom: '-20px'
  },
  checkboxStyle: {
    // padding:'7px 30px'
    //paddingTop: 7,
  },
  checkboxLabelStyle: {
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "capitalize",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
  },

  forgotPasswordContainer: {
    display: 'flex',
    justifyContent: 'end'
  },
  forgotPassword: {
    margin: 0
  },
  forgotPasswordLable: {
    // paddingTop: '15px',
    marginLeft: 0,
    marginRight: 0,
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "capitalize",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
  },
  imageStyle: {
    width: '70%',
    height: '70%',
    marginBottom: 15,
    // display: 'flex'
  },

  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },


});

export default signinStyle;