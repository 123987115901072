import React from "react";
// @material-ui/core
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonCard = (props) => {
    return (
        <div className="component-prevsession">
            <div className="card shadow bg-white">
                <div className="card__head">
                    <h6 className="card__title">previous sessions</h6>
                </div>
                <div className="card__body">
                    <div className="link__list">
                        {['001', '010', '011', '111'].map((data, idex) => (
                            <a className="link__item" key={idex + "SPRIV"}>
                                <span className="meta title"> <Skeleton className="meta title" animation="wave" /></span>
                                <span className="meta name"><Skeleton animation="wave" /></span>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonCard;