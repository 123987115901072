import axiosConfig from "shared/interceptor/axiosInstance";


export class DashboardServices {

    static fetchTestList(
        onSuccess,
        onError,
        onFinal
    ) {
        return axiosConfig().post('/OnlineTest/GetTestList', {
            "type": 0, // type==0 -- ALL Test, type==1 -- OnGoing Test, type==2 -- UpComing Test,type==3 -- Missed Test
            "testTypeId": "0",  //--1 = Objective & 2 = Subjective / Pass "0" for all
            "subjectId": 0,
            "pageNumber": 1,
            "pageSize": 3
        }).then(response => {
            onSuccess(response.data || [])
        }).catch(error => {
            onError(error)
        }).finally(onFinal)
    }

    static fetchAssignmentList(
        onSuccess,
        onError,
        onFinal
    ) {
        return axiosConfig().post('/AppCore/V3/GetAssignmentList', {
            "typeId": '1', //1--Assignment / 2--Test / 0--All
            "pageNumber": 1,
            "pageSize": 3,
            "search": "",
            "subjectId": 0
        }).then(response => {
            onSuccess(response.data || [])
        }).catch(error => {
            onError(error)
        }).finally(onFinal);
    }

}