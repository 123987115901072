import * as actionTypes from 'store/definitions/actionTypes'

const initialState = {
    notification: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NOTIFICATION:
            return {
                ...state,
                notification: action.data
            }
        default:
            return state;
    }

}


export default reducer