import * as actionTypes from '../definitions/actionTypes';
import axiosConfig from "shared/interceptor/axiosInstance";



// -----------------------Assignment-----------------
export const setAssignments = (assignments) => {
    return {
        type: actionTypes.SET_ACTIVE_ASSIGNMENTS,
        assignments: assignments,
    }
};


export const fetchClassAssignments = (TypeId) => {
    return async (dispatch, getState) => {
        await axiosConfig().get('AppCore/V2/GetAssignmentList').then(response => {
            dispatch(setAssignments(response.data))
        }).catch(error => {
            console.log(error);
            throw new Error(error)
        });
    }
}



// -----------------------Current Assignment-----------------
export const setCurrentAssignment = (assignments) => {
    return {
        type: actionTypes.SET_CURRENT_ASSIGNMENT,
        assignments: assignments,
    }
};


export const fetchCurrentAssignment = (assignmentId) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('/AppCore/V2/GetAssignmentById', {
            params: {
                UserId: UserId,
                AssignmentId: assignmentId
            }
        }).then(response => {
            dispatch(setCurrentAssignment(response.data))
        }).catch(error => {
            console.log(error);
            throw new Error(error)
        });
    }
}

// -----------------------Submit Assignment-----------------
export const postAssignment = (mappingId, attachmentList, attemptText) => {
    return async (dispatch, getState) => {
        const formData = new FormData();
        formData.append('AssignmentMappingId', mappingId)

        attachmentList.forEach((element, index) => {
            formData.append(`AttemptAttachmentList[${index}].Id`, index + '');
            formData.append(`AttemptAttachmentList[${index}].FileName`, element.name);
            formData.append(`AttemptAttachmentList[${index}].FileSize`, element.size.toString());
            formData.append(`AttemptAttachmentList[${index}].FileType`, element.type);
            formData.append(`AttemptAttachmentList[${index}].IsActive`, 'true');
            formData.append(`AttemptAttachmentList[${index}].IsDeleted`, 'false');
            formData.append(`AttemptAttachmentList[${index}].IsNew`, 'true');
            formData.append(`AttemptAttachmentList[${index}].Index`, index + '');
            formData.append(`AttemptAttachmentList[${index}].FileUrl`, '');
            formData.append(`AttemptAttachmentList[${index}].File`, element);
        });
        formData.append('AttemptText', attemptText)
        formData.append('IsAttemptDraft', false)

        await axiosConfig().post('/AppCore/SubmitAssignment', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        ).then(response => {
             if (!response.data.isSuccess)
                throw new Error("something went wrong!")

        }).catch(error => {
            if (error.response)
                if (error.response.data)
                    throw new Error(error.response.data)
                else
                    throw new Error("something went wrong!")
            throw new Error(error.message)
        });
    }
}


