import * as actionTypes from '../definitions/actionTypes';
// import axios
import axiosConfig from "shared/interceptor/axiosInstance";


// Board List
export const setBoardList = (userInfo) => {
    return {
        type: actionTypes.SET_BOARD_LIST,
        payload: userInfo
    }
}


export const fetchBoardList = () => {
    return async (dispatch) => {
        await axiosConfig().get('/Content/GetBoardList').then(response => {
            dispatch(setBoardList(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response)
            else
                throw new Error("something went wrong!")
        });
    }
}

// Course List
export const setCourseList = (userInfo) => {
    return {
        type: actionTypes.SET_COURSE_LIST,
        payload: userInfo
    }
}


export const fetchCourseList = (InstitutionId = 1) => {
    return async (dispatch) => {
        await axiosConfig().get('/Common/GetCourseList_Registration', {
            params: {
                InstitutionId: InstitutionId
            }
        }).then(response => {
            dispatch(setCourseList(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response)
            else
                throw new Error("something went wrong!")
        });
    }
}

// Subject List
export const setSubjectList = (userInfo) => {
    return {
        type: actionTypes.SET_SUBJECT_LIST,
        payload: userInfo
    }
}


export const fetchSubjectList = (CourseCode, StructureId = 0) => {
    return async (dispatch) => {
        await axiosConfig().get('/Common/GetSubjectList_DemoSchedule', {
            params: {
                CourseCode: CourseCode,
                StructureId: StructureId
            }
        }).then(response => {
            dispatch(setSubjectList(response.data.structureList))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response)
            else
                throw new Error("something went wrong!")
        });
    }
}

// Schedule List
export const setScheduleList = (userInfo) => {
    return {
        type: actionTypes.SET_SCHEDULE_LIST,
        payload: userInfo
    }
}


export const fetchScheduleList = (courseId, subjectId) => {
    return async (dispatch) => {
        await axiosConfig().post('/Common/GetScheduleList_Registration', {
            "courseId": courseId,
            "subjectId": subjectId,
            "isDemo": true
        })
            .then(response => {
                dispatch(setScheduleList(response.data))
            }).catch(function (error) {
                if (error.response.data)
                    throw new Error(error.response)
                else
                    throw new Error("something went wrong!")
            });
    }
}


export const registerDemo = (Board, Course, Subject, Schedule, Renderat) => {
    return async (dispatch, getState) => {
        let user = getState().newuser.registerUser
        if (Renderat === 'home')
            user = getState().user.userinfo

        await axiosConfig().post('User/RegisterDemoUser', {
            "boardId": Board.boardId,
            "boardName": Board.boardName,
            "gradeId": Course.courseId,
            "gradeName": Course.courseName,
            "userId": user.userId,
            "subjectName": Subject.structureDisplayName,
            "isOTPVerified": true,
            "otpno": user.otpno || '123456',
            "scheduleList": [
                {
                    "courseCode": Course.courseCode,
                    "subjectId": Subject.structureId,
                    "subjectName": Subject.structureDisplayName,
                    "schduleId": Schedule.scheduleId,
                    "schduleName": Schedule.scheduleName,
                    "preferredtimetoCall": Schedule.startTime
                }
            ]
        }).then(response => {
            if (!response.data.isSuccess)
                throw new Error(response.data.message)
        }).catch(function (error) {
            if (error.response)
                if (error.response.data)
                    throw new Error(error.response.data)
                else
                    throw new Error("something went wrong!")
            throw new Error(error.message)
            // dispatch(fetchSharedDataFailed())
        });
    }
}













