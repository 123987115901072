import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';

// import material-ui/core
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';


// import material-ui/icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//import style
import styles from "assets/jss/components/customcomponents/pageNavigationStyle.js";

const useStyles = makeStyles(styles);

const AdminNavbarLinks = (props) => {
    const classes = useStyles();
    const { className } = props;
    const appBar = classNames({
        [classes.appBar]: true,
        [className]: className !== undefined
    });
    return (
        <React.Fragment>
            <CssBaseline />
            <div className={classes.root}>

                <AppBar className={appBar} >
                    <Toolbar className={classes.toolbar} variant="dense" classes={{ dense: classes.muiToolbarDense }}>
                        <div style={{ flexDirection: "row", display: "flex", alignItems: 'center', color:props.color }}>
                            <IconButton color={"inherit"} aria-label="ArrowBackIcon" onClick={() => props.back ? props.history.push(props.back) : props.history.goBack()}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                component="h1"
                                variant="body1"
                                color="inherit"
                                noWrap
                                className={classes.title}
                            >
                                {props.logoText}
                            </Typography>
                        </div>

                    </Toolbar>
                    {props.children}
                </AppBar>
            </div>
        </React.Fragment>

    );
}

AdminNavbarLinks.defaultProps = {
    color: 'inherit',
};
AdminNavbarLinks.propTypes = {
    classes: PropTypes.object,
    color:PropTypes.string
};
export default withRouter(AdminNavbarLinks);