import * as actionTypes from '../definitions/actionTypes';
// import axios
import axiosConfig from "shared/interceptor/axiosInstance";

export const fetchTestQuestionsStart = () => {
    return {
        type: actionTypes.FETCH_TESTQUESTIONS_START
    }
}

export const setTestQuestions = (questions) => {
    console.log("-setTestQuestions-");

    questions["qadd"] = questions.qadd.map(qsn => {
        if (qsn.options.optionItems) {
            qsn.options.optionItems = qsn.options.optionItems.map(opt => {
                opt['ischecked'] = false
                return opt
            })
        } else {
            if (qsn.questionTypeId === 'QTY004')
                qsn.options['optionItems'] = [{}, {}].map((opt, indx) => {
                    opt['id'] = "" + indx
                    opt['value'] = indx === 1 ? 'true' : 'false'
                    opt['ischecked'] = false
                    return opt
                })
        }
        return qsn
    })
    return {
        type: actionTypes.SET_TESTQUESTIONS,
        questions: questions
    }
}

export const fetchTestQuestionsFailed = () => {
    return {
        type: actionTypes.FETCH_TESTQUESTIONS_FAILED
    }
}

export const initQuestions = (testCode) => {
    return dispatch => {
        dispatch(fetchTestQuestionsStart());
        axiosConfig().get('AppCore/GetTestQuestionDetails', {
            params: {
                TestCode: testCode,
            }
        }).then(response => {
            dispatch(setTestQuestions(response.data))
            dispatch(initTestAnswers(response.data)) // init Answers Array For Post Assessment 
        }).catch((error) => {
            dispatch(fetchTestQuestionsFailed())
        });
    }
}

//-------- Set Test Answer------------------
export const initTestAnswers = (answers) => {
    return {
        type: actionTypes.SET_TEST_ANSWERS,
        answers: answers
    }
}

//-------- update Question Answer------------------
export const updateQuestioAnswer = (answer, questionCode) => {
    return {
        type: actionTypes.UPDATE_QUESTION_ANSWER,
        answer: answer,
        questionCode: questionCode
    }
}

//-------- update Answer duration------------------
export const updateAnswerDuration = (duration, questionCode) => {
    return {
        type: actionTypes.UPDATE_ANSWER_DURATION,
        duration: duration,
        questionCode: questionCode
    }
}

//----------- Post Test Attempt----------
export const postTestAttemptStart = () => {
    return {
        type: actionTypes.POST_TEST_ATTEMPT_START
    }
};

export const postTestAttemptSuccess = () => {
    return {
        type: actionTypes.POST_TEST_ATTEMPT_SUCCESS
    }
};

export const postTestAttemptFailed = () => {
    return {
        type: actionTypes.POST_TEST_ATTEMPT_FAILED
    }
};

export const postTestAttempt = () => {
    return (dispatch, getState) => {
        dispatch(postTestAttemptStart());

        const TODAY_DATE_TIME = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
        const userInfo = getState().user.userinfo
        const test = getState().content.current_test
        const answers = getState().questions.answers
        const formData = {
            "userCode": userInfo.userId,
            "courseCode": test.courseCode,
            "courseContentCode": test.courseContentCode,
            "testCode": test.testCode,
            "testActualDuration":+test.testTime+"",
            "testAttemptedDuration":test.testAttemptedDuration+"",
            "testTotalMarks": test.totalMarks+"",
            "marksObtained": test.marksObtained+"",
            "totalQuestion": test.totalQuestion+"",
            "skipQuestionCount": test.skipQuestionCount+"",
            "rightAnswerCount": test.rightAnswerCount+"",
            "wrongAnswerCount": test.wrongAnswerCount+"",
            "percentage": "",
            "attemptedTestDate": TODAY_DATE_TIME + "",
            "question": answers
        }
        axiosConfig().post('AppCore/SetTestDetails', formData)
            .then(response => {
                console.log(response);
                dispatch(postTestAttemptSuccess())
            }).catch((error) => {
                console.log(formData);
                dispatch(postTestAttemptFailed())
            });
    }
}


