import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader'
import screenfull from 'screenfull'

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import FullscreenExit from '@material-ui/icons/FullscreenExit';


import VolumeOffRoundedIcon from '@material-ui/icons/VolumeOffRounded';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import IconButton from '@material-ui/core/IconButton';
import Button from 'shared/components/CustomButtons/Button'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CustomMarkdown from 'shared/components/CustMarkdown/CustMarkdown'
import withStyles from '@material-ui/core/styles/withStyles';
//Redux
import { connect } from 'react-redux'

//import reducer 
import * as questActions from 'store/actions/index'

// import './reset.css'
import './style/defaults.css'
import './style/range.css'
import './style/ckin.css'
// styles
import styles from "assets/jss/components/views/assessmentStyle";
import { green } from '@material-ui/core/colors';
// import './App.css'

//axios
import { Token } from "shared/utils/commonfunctions";



import ReactPlayer from 'react-player'
import Duration from './Duration'


// const MULTIPLE_SOURCES = [
//     { src: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4', type: 'video/mp4' },
//     { src: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.ogv', type: 'video/ogv' },
//     { src: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.webm', type: 'video/webm' }
// ]


const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

class VideoPalyer extends Component {
    constructor(props) {
        super(props);
        this.mousetrailRef = React.createRef();
        this.mouseConlRef = React.createRef();
        this.nextContentTimer = React.createRef();
    }
    state = {
        progress: 0,
        url: this.props.fileurl ? this.props.fileurl : null,
        pip: false,
        timer: null,
        counter: 3,
        playing: true,
        controls: false,
        light: false,
        volume: 1,
        muted: false,
        played: 0,
        end: false,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        auth: true,
        loading: true,
        tokan: Token(),
        qpoint: [],
        markers: [],
        // markers: [
        //     { time: 60, text: "test1", position: 0 },
        //     { time: 80, text: "test2", position: 0 },
        //     { time: 120, text: "test3", position: 0 },
        //     { time: 370, text: "test4", position: 0 },
        // ]

        nextContent: {}
    }



    componentDidMount() {
        let timer = setInterval(this.tick, 1000);
        this.setState({ timer });
        this.mousetrailRef.current.addEventListener('mousemove', this.init)
        this.mouseConlRef.current.addEventListener('mouseenter', this.coninit)
        this.mouseConlRef.current.addEventListener('mouseleave', this.conleave)
    }

    init = (e) => {
        clearInterval(this.state.timer);
        this.setState({ counter: 3 });
        let timer = setInterval(this.tick, 1000);
        this.setState({ timer });

    }

    coninit = (e) => {
        this.mousetrailRef.current.removeEventListener('mousemove', this.init)
        clearInterval(this.state.timer);
    }

    conleave = (e) => {
        let timer = setInterval(this.tick, 1000);
        this.setState({ timer });
        this.mousetrailRef.current.addEventListener('mousemove', this.init)
    }

    tick = () => {
        if (this.state.counter == 0) clearInterval(this.state.timer);
        else
            this.setState({
                counter: this.state.counter - 1
            });
    };


    componentWillUnmount() {
        clearInterval(this.nextContentTimer.current);
        clearInterval(this.state.timer);
        // console.log("---------------------componentWillUnmount---------------------");
        if (this.props.platform === "mobile") {
            const accessTime = this.state.duration * this.state.played
            if (accessTime > 10)
                this.props.onPostContentAccess()
        }
    }



    handleReady = () => {
        //console.log('onPause')
        clearInterval(this.nextContentTimer.current);
        this.setState({ loading: false, end: false, progress: 0, playing: true, nextContent: {} })
    }
    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing })
        clearInterval(this.state.timer);
        this.setState({ counter: 3 });
        let timer = setInterval(this.tick, 1000);
        this.setState({ timer });
    }

    handleStop = () => {
        this.setState({ url: null, playing: false })
    }



    handleToggleLight = () => {
        this.setState({ light: !this.state.light })
    }

    handleToggleLoop = () => {
        this.setState({ loop: !this.state.loop })
    }

    handleVolumeChange = e => {
        // console.log(e.target.value);
        this.setState({ volume: parseFloat(e.target.value) })
    }

    handleToggleMuted = () => {
        this.setState({ muted: !this.state.muted, volume: this.state.muted ? 0.2 : 0 })
    }

    handleSetPlaybackRate = (value) => {
        // console.log(value);
        this.setState({ playbackRate: parseFloat(value) })
    }

    handleTogglePIP = () => {
        this.setState({ pip: !this.state.pip })
    }

    handlePlay = () => {
        // console.log('onPlay')
        this.setState({ playing: true })
    }

    handleEnablePIP = () => {
        // console.log('onEnablePIP')
        this.setState({ pip: true })
    }

    handleDisablePIP = () => {
        // console.log('onDisablePIP')
        this.setState({ pip: false })
    }

    handlePause = () => {
        // console.log('onPause')
        this.setState({ playing: false })
    }

    handleSeekMouseDown = e => {
        // console.log("handleSeekMouseDown");
        this.setState({ seeking: true })
    }

    handleSeekChange = e => {
        // console.log("handleSeekChange");
        this.setState({ played: parseFloat(e.target.value) })
    }

    handleSeekMouseUp = e => {
        // console.log('handleSeekMouseUp')
        this.setState({ seeking: false })
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleEnded = () => {
        let index = this.props.contentList.Add.contentstructure.findIndex(element => element.courseContentId === this.props.content.courseContentId)
        if (index >= 0 && index < this.props.contentList.Add.contentstructure.length - 1) {
            let nextItem = this.props.contentList.Add.contentstructure[index + 1]
            this.setState({ end: true, nextContent: nextItem })
            this.nextContentTimer.current = setInterval(async () => {
                if (this.state.progress >= 100) {
                    await this.props.setCurrentContent(nextItem)
                    switch (nextItem.ContentTypeCode) {
                        case ('14'):
                            nextItem.CourseContentFileUrl !== ""
                                ? this.props.history.push('/viewer')
                                : this.props.history.push('/contentviewer')
                            break;
                        case ('15'):
                            this.props.history.push('/instruction')
                            break;
                        case ('16'):
                            this.props.history.push('/pdf')
                            break;
                    }
                }
                else
                    this.setState(prevState => ({ progress: prevState.progress >= 100 ? 0 : prevState.progress + 10 }))
            }, 500);
        }
        this.setState({ playing: this.state.loop })
    }

    handleDuration = (duration) => {
        // console.log('onDuration', duration)
        let markers = this.state.markers.map(value => {
            let new_value = { ...value }
            // Is position within range of the duration?
            if (new_value.time <= duration) {
                // Calculate position in percentage and add to the #seekbar.
                let left = (new_value.time / duration) * 100 + '%';
                // Create marker and give it the left value.
                new_value.position = left

            }
            return new_value;
        })
        this.setState({ markers, duration })
    }

    handleAESencryption = (xhr, url) => {
        if (url.includes("kid")) {
            url = `${window.BASE_URL}ams/getkey?KID=${this.props.content.CourseContentCode}`
            xhr.open('GET', url, true);
        }
        if (url.includes("GetKey")) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.state.tokan)
        }

    }


    handleClickFullscreen = () => {
        if (typeof window.Android !== 'undefined') {
            window.Android.setDeviceOrientation(1)
            this.props.onChangeOrientation("landscape")
        } else {
            // screenfull.request(findDOMNode(this.player))
            this.props.setFullscreen(true)
        }
    }

    handleClickFullscreenExit = () => {
        if (typeof window.Android !== 'undefined') {
            window.Android.setDeviceOrientation(2)
            this.props.onChangeOrientation("portrait")
        } else {
            this.props.setFullscreen(false)
        }
    }


    renderLoadButton = (url, label, auth) => {
        return (
            <button onClick={() => this.load(url, auth)} style={{ margin: 5 }}>
                {label}
            </button>
        )
    }

    ref = player => {
        this.player = player
    }

    // ?-------------------------------------?
    handleProgress = async state => {

        let qpoint = await this.state.qpoint.map(value => {
            let new_value = { ...value }
            if (value.startPoint === parseInt(state.playedSeconds) + "") {
                this.setState({ playing: false })
                new_value.show = true
            }
            return new_value;
        })
        this.setState({ qpoint })


        // Update Content
        this.props.updateContentAccessDuration(this.state.duration * state.played)


        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    onClickOverlaysButton = async (qpdata, btndata) => {

        if (btndata.ActionType === 'Skip')
            this.player.seekTo(parseFloat(btndata.SkipTo))
        if (btndata.ActionType === 'CheckAnswer') {
            const CORRECT_ANSWER = qpdata.Answer ? qpdata.Answer.split(",").sort() : ""
            const YOUR_ANSWER = qpdata.Options.filter(data => data.ischecked === true).map(data => data.id)

            if (this.CheckAnswer(CORRECT_ANSWER, YOUR_ANSWER)) {
                if (btndata.CorrectAction.ActionType === 'Skip')
                    this.player.seekTo(parseFloat(btndata.CorrectAction.SkipTo))
            } else {
                if (btndata.WrongAction.ActionType === 'Skip')
                    this.player.seekTo(parseFloat(btndata.WrongAction.SkipTo))
            }
        }
        let qpoint = await this.state.qpoint.map(value => {
            let new_value = { ...value }
            new_value.show = false
            return new_value;
        })
        this.setState({ qpoint, playing: true })


    }

    CheckAnswer = (arr1, arr2) => {
        let newarr1 = [...new Set(arr1)]
        let newarr2 = [...new Set(arr2)]
        if (newarr1.length !== newarr2.length)
            return false;
        return newarr1.every(val => newarr2.includes(val))
    }

    handleChange = async (qid, opid) => {
        const qpoint = this.state.qpoint.map(currentQuestion => {
            let nqp = { ...currentQuestion }
            if (qid === nqp.id) {
                nqp.Options = [...nqp.Options].map(opt => {
                    let newOpt = { ...opt }
                    if (nqp.QuestionType === "SC")
                        newOpt.ischecked = false
                    if (opid === newOpt.id) {
                        if (nqp.QuestionType === "MC")
                            newOpt.ischecked = !newOpt.ischecked
                        else
                            newOpt.ischecked = true
                    }
                    return newOpt
                })
            }
            return nqp
        })
        this.setState({ qpoint })
    };

    handleCancelPlayNext = () => {
        clearInterval(this.nextContentTimer.current);
        this.setState({ end: false })
    }


    render() {
        // const {loaded,auth, tokan }= this.state
        const { url, playing, controls, light, volume, muted, loop, played, duration, playbackRate, pip, loading, counter, end, nextContent, progress } = this.state
        const { wrapperStyle, classes } = this.props
        // const SEPARATOR = ' · '

        return (

            <div className={wrapperStyle ? 'app-task' : 'app'} ref={this.mousetrailRef}>

                <section className='section' >
                    <div className={
                        wrapperStyle
                            ? 'player-wrapper-task'
                            : this.props.orientation === "portrait"
                                ? 'player-wrapper'
                                : 'player-wrapper-landscape'
                    }>

                        {/* <a href="javascript:;" className="getTime">Bookmark Here</a> */}
                        <ReactPlayer
                            ref={this.ref}
                            className='react-player'
                            width='100%'
                            height='100%'
                            url={this.props.fileurl}
                            config={{
                                file: {
                                    hlsOptions: {
                                        forceHLS: true,
                                        debug: false,
                                        xhrSetup: (xhr, url) => this.handleAESencryption(xhr, url)
                                    },
                                }
                            }}
                            playIcon={<button>Play</button>}
                            pip={pip}
                            playing={playing}
                            controls={controls}
                            light={light}
                            loop={loop}
                            playbackRate={playbackRate}
                            volume={volume}
                            muted={muted}
                            onReady={this.handleReady}
                            onStart={() => console.log('onStart')}
                            onPlay={this.handlePlay}
                            onEnablePIP={this.handleEnablePIP}
                            onDisablePIP={this.handleDisablePIP}
                            onPause={this.handlePause}
                            onBuffer={() => console.log('onBuffer')}
                            onSeek={e => console.log('onSeek', e)}
                            onEnded={this.handleEnded}
                            onError={e => console.log('onError', e)}
                            onProgress={this.handleProgress}
                            onDuration={this.handleDuration}
                            playsinline={true}

                        />

                        <IconButton className={` ${playing ? "is-playing default__button--big" : 'default__button--big'}`}>
                            {playing ? <PauseRoundedIcon fontSize="large" /> : <PlayArrowRoundedIcon fontSize="large" />}
                        </IconButton>

                        {loading ? (
                            <div className="loader-div">
                                <div className="loader">Loading...</div>
                            </div>)
                            : null}

                        <marquee onClick={this.handlePlayPause} className="marquee-controls-down" direction="down" behavior="scroll" height="100%" scrollamount="1" scrolldelay="80">
                            <marquee behavior="scroll"><font >{this.props.userInfo.userName}</font></marquee>
                        </marquee>

                        {!loading
                            ? <div className={`${counter !== 0 ? "speed-bar" : 'is-playing-control'}`}>
                                <button onClick={() => this.handleSetPlaybackRate(1)}><DirectionsWalkIcon /></button>
                                <button onClick={() => this.handleSetPlaybackRate(1.5)}><DirectionsRunIcon value={1.5} /></button>
                                <button onClick={() => this.handleSetPlaybackRate(2)}><DirectionsBikeIcon value={2} /></button>
                            </div>
                            : null
                        }

                        <div className="controls" ref={this.mouseConlRef}>
                            <div className="load-bar">
                                <input
                                    type='range' min={0} max={0.999999} step='any'
                                    value={played}
                                    className="slider"
                                    onMouseDown={this.handleSeekMouseDown}
                                    onChange={this.handleSeekChange}
                                    onMouseUp={this.handleSeekMouseUp}
                                    onTouchStart={this.handleSeekMouseDown}
                                    onTouchEnd={this.handleSeekMouseUp}
                                />
                                <progress className="load-progress" max={1} value={played} />

                                <div className="bookmark-bar">
                                    {this.state.markers.map((val, index) => {
                                        return (
                                            <div key={"vdi#" + index} style={{ left: val.position, position: 'absolute' }}>
                                                <button className="tooltip" data-title={val.text}></button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className={`${counter !== 0 ? "button-controls" : 'is-playing-control'}`}>
                                <div className="play-button">
                                    <IconButton onClick={this.handlePlayPause}>{playing ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}</IconButton>
                                </div>

                                {/* <div className="volume-button">
                                    <button onClick={this.handleToggleMuted}>{muted ? <VolumeOffRoundedIcon /> : <VolumeUpRoundedIcon />}</button>
                                    <input type='range' className="slider-volume" min={0} max={1} step='any' value={volume} onChange={this.handleVolumeChange} />
                                </div> */}

                                <div className="duration-info">
                                    <div><Duration seconds={duration * played} /> /  <Duration seconds={duration} ></Duration> </div>
                                </div>

                                <div className="fullscreen-button">
                                    {this.props.orientation === "portrait" ^ this.props.fullscreen
                                        ? <IconButton onClick={this.handleClickFullscreen}><FullscreenIcon /></IconButton>
                                        : <IconButton onClick={this.handleClickFullscreenExit}><FullscreenExit /></IconButton>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* next  video*/}
                        <div className={`${end ? "overlays overlays-next" : 'is-playing-control'}`}>
                            <div className={'div_content overlays_column'}>
                                <Typography variant={"body1"} style={{ color: '#fff' }}> up next </Typography>
                                <Typography variant={"h5"} style={{ color: '#fff' }}> {nextContent.courseContentDisplayName} </Typography>

                                <div className={'wrapper'}>
                                    <IconButton className='playicon'>
                                        <PlayArrowRoundedIcon fontSize="large" />
                                    </IconButton>
                                    <CircularProgress size={70} variant="determinate" value={progress} className={'fabProgress'} />
                                </div>



                                <Button link size={'sm'} color="primary" disableElevation onClick={this.handleCancelPlayNext}>
                                    Cancel
                                </Button>
                            </div>
                        </div>


                        {/* video questions */}
                        {this.state.qpoint.map((val, index) => {
                            return (
                                <div key={"vdi#" + index} className={"overlays"} style={{ visibility: val.show ? 'visible' : 'hidden' }}>
                                    {val.type === "I"
                                        ? <div className={'div_content'}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant={"h5"} style={{ color: '#fff' }}> {val.Infotext} </Typography>
                                                {val.buttons.map(data =>
                                                    <Button key={"bid#" + data.id} variant="contained" className={classes.testButton} onClick={() => this.onClickOverlaysButton(val, data)} color="primary" disableElevation>
                                                        {data.Name}
                                                    </Button>)
                                                }
                                            </div>
                                        </div>
                                        : val.type === 'Q'
                                            ? <div className={'div_content'}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant={"h5"} style={{ color: '#fff' }}> {val.Questiontext} </Typography>

                                                    <List className={classes.root}>

                                                        {val.Options !== undefined
                                                            ? val.Options.map(option =>
                                                                <ListItem
                                                                    style={{ color: '#fff' }}
                                                                    key={"option" + option.id} role={undefined} dense button
                                                                    onClick={() => this.handleChange(val.id, option.id)}
                                                                >
                                                                    <ListItemIcon >
                                                                        {val.QuestionType === "SC"
                                                                            ? <GreenRadio checked={option.ischecked} color="primary" />
                                                                            : val.QuestionType === "MC" ?
                                                                                <GreenCheckbox checked={option.ischecked} color="primary" />
                                                                                : null
                                                                        }
                                                                    </ListItemIcon>
                                                                    <ListItemText style={{ margin: 0 }} primary={<CustomMarkdown >{option.value}</CustomMarkdown>} />
                                                                </ListItem>
                                                            )
                                                            : null
                                                        }

                                                    </List>
                                                    {val.buttons.map(data =>
                                                        <Button key={"Qid#" + data.id} variant="contained" className={classes.testButton} onClick={() => this.onClickOverlaysButton(val, data)} color="primary" disableElevation>
                                                            {data.Name}
                                                        </Button>)
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            )
                        })}

                    </div>

                </section>

            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        previousContent: state.content.previous_content,
        content: state.content.current_content,
        userInfo: state.user.userinfo,
        currentCourse: state.course.current_course,
        contentList: state.content.contentlist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearCurrentContent: () => dispatch(questActions.clearCurrentContent()),
        updateContentAccessDuration: (accessDuration) => dispatch(questActions.updateContentAccessDuration(accessDuration)),
        onPostContentAccess: () => dispatch(questActions.postContentAccess()),
        setCurrentContent: (content) => dispatch(questActions.setCurrentContent(content)),
    }

}

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(VideoPalyer))));