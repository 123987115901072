import * as actionTypes from '../definitions/actionTypes';
// import axios
import axiosConfig from "shared/interceptor/axiosInstance";


//----- Fetch Content List------------------
export const fetchContentListStart = () => {
    return {
        type: actionTypes.FETCH_CONTENTLIST_START
    }
}

export const setContentList = (contentList, userProgress, courseCode, LevelMapping) => {
    return {
        type: actionTypes.SET_CONTENTLIST,
        contentlist: contentList,
        userprogress: userProgress,
        coursecode: courseCode,
        levelmapping: LevelMapping
    }
}

export const fetchContentListFailed = () => {
    return {
        type: actionTypes.FETCH_CONTENTLIST_FAILED
    }
}

export const initContentList = (courseCode, userId) => {
    return dispatch => {
        dispatch(fetchContentListStart());
        // /AppCore/GetCourseContentList
        axiosConfig().get('AppCore/GetCourseContentList', {
            params: {
                CourseCode: courseCode,
                UserId: userId
            }
        }).then(response => {
            dispatch(initUserProgress(response.data, courseCode, userId))
        }).catch(function (error) {
            dispatch(fetchContentListFailed())
        });
    }
}

export const initUserProgress = (ContentList, courseCode, UserId) => {
    return (dispatch, getState) => {
        const LevelMapping = getState().appconfigs.configs.levelmapping

        //temp
        //dispatch(setContentList(ContentList, { 'CourseProgress': [] }, courseCode, LevelMapping))


        axiosConfig().get('/AppCore/GetUserProgress', {
            params: {
                UserId: UserId,
                CourseCode: courseCode
            }
        }).then(response => {
            dispatch(setContentList(ContentList, response.data, courseCode, LevelMapping))
        }).catch(function (error) {
            dispatch(fetchContentListFailed())
        });
    }
}

//------------set current subject------------
export const setCurrentSubject = (current_subject) => {
    return {
        type: actionTypes.SET_CURRENT_SUBJECT,
        subject: current_subject
    }
}


//------------set current operation--------------
export const setCurrentOperation = (current_operation) => {
    return {
        type: actionTypes.SET_CURRENT_OPERATION,
        operation: current_operation
    }
}

//-----set current content-----------------
export const setCurrentContent = (current_content) => {
    return {
        type: actionTypes.SET_CURRENT_CONTENT,
        content: current_content
    }
}

//-----clear current content-----------------
export const clearCurrentContent = () => {
    return {
        type: actionTypes.CLEAR_CURRENT_CONTENT,
    }
}

//-----Update current content (marksObtained,pointCardSatus)-----------------
export const updateCurrentContent = (marksObtained, pointCardSatus, accessCount = 0) => {
    // console.log(accessCount);
    return {
        type: actionTypes.UPDATE_CURRENT_CONTENT,
        marksObtained: marksObtained,
        pointCardSatus: pointCardSatus,
        accessCount: accessCount
    }
}



//------------update Content AccessDuration--------------
export const updateContentAccessDuration = (accessDuration = 0) => {
    return {
        type: actionTypes.UPDATE_ACCESS_DURATION,
        accessDuration: accessDuration
    }
}


//-----set current Test-----------------
export const setCurrentTest = (currentTest) => {
    return {
        type: actionTypes.SET_CURRENT_TESTS,
        current_test: currentTest
    }
}

//-------- update Answer duration------------------
export const updateTestAttemptedDuration = (duration) => {
    return {
        type: actionTypes.UPDATE_TEST_ATTEMPT_DURATION,
        duration: duration,
    }
}

//-------- update TestAttempt Count------------------
// For check Current Test is attempted or not 
export const updateTestAttemptCount = (skipCount, rightCount, wrongCount, marksObtained) => {
    return {
        type: actionTypes.UPDATE_TEST_ATTEMPT_COUNT,
        skipCount,
        rightCount,
        wrongCount,
        marksObtained
    }
}



//----- Post Content Rating ------------------
export const postContentRatingtStart = () => {
    return {
        type: actionTypes.POST_CONTENT_RATING_START
    }
};

export const postContentRatingSuccess = () => {
    return {
        type: actionTypes.POST_CONTENT_RATING_SUCCESS
    }
};

export const postContentRatingFailed = () => {
    return {
        type: actionTypes.POST_CONTENT_RATING_FAILED
    }
};

export const postContentRating = (ContentId, Rating) => {
    return (dispatch, getState) => {
        dispatch(postContentRatingtStart());
        const userInfo = getState().user.userinfo

        axiosConfig().post('AppCore/SetContentRating', null, {
            params: {
                UserId: userInfo.userId,
                ContentId,
                Rating,
            }
        })
            .then(response => {
                dispatch(postContentRatingSuccess())
                dispatch(initContentRating(ContentId)) // fetch Content Rating
            }).catch((error) => {
                dispatch(postContentRatingFailed())
            });
    }
}

//----- Fetch Content Rating------------------
export const fetchContentRatingStart = () => {
    return {
        type: actionTypes.FETCH_CONTENT_RATING_START
    }
}

export const setContentRating = (contentRatings) => {
    return {
        type: actionTypes.SET_CONTENT_RATING,
        contentRatings: contentRatings,
    }
}

export const fetchContentRatingFailed = () => {
    return {
        type: actionTypes.FETCH_CONTENT_RATING_FAILED
    }
}

export const initContentRating = (ContentId) => {
    return (dispatch, getState) => {
        dispatch(fetchContentRatingStart());
        const userInfo = getState().user.userinfo;
        axiosConfig().get('/AppCore/GetContentRating', {
            params: {
                ContentId: ContentId,
                UserId: userInfo.userId
            }
        }).then(response => {
            dispatch(setContentRating(response.data))
        }).catch(error => {
            dispatch(fetchContentRatingFailed())
        });
    }
}



//----- Post Content Access  ------------------
export const postContentAccesstStart = () => {
    return {
        type: actionTypes.POST_CONTENT_ACCESS_START
    }
};

export const postContentAccessSuccess = () => {
    return {
        type: actionTypes.POST_CONTENT_ACCESS_SUCCESS
    }
};

export const postContentAccessFailed = () => {
    return {
        type: actionTypes.POST_CONTENT_ACCESS_FAILED
    }
};


export const postContentAccess = (cardSatus = true, accessCount = 1) => {
    return async (dispatch, getState) => {
        const userInfo = getState().user.userinfo;
        const currentContent = getState().content.current_content
        const TODAY_DATE_TIME = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')

        const BODY_DATA = {
            "userId": userInfo.userId,
            "courseCode": currentContent.courseCode,
            "courseContentCode": currentContent.courseContentCode,
            "contentTypeCode": currentContent.contentTypeCode,
            "accessDate": TODAY_DATE_TIME,
            "accessDuration": currentContent.accessDuration + ""
        }
        dispatch(postContentAccesstStart());
        await axiosConfig().post('/AppCore/SetContentAccessDetails', BODY_DATA).then(response => {
            dispatch(postContentAccessSuccess());
            dispatch(updateCurrentContent(currentContent.point, cardSatus, accessCount))// update content,current_content and current_course_progress
            dispatch(clearCurrentContent());
        }).catch((error) => {
            dispatch(postContentAccessFailed())
        });
    }
}

// -----------Get Session Recording Structure-------------------
export const fetchSessionRecordingStructureStart = () => {
    return {
        type: actionTypes.FETCH_SR_STRUCTURE_START
    }
};

export const fetchSessionRecordingStructureSuccess = (contentstructure) => {
    return {
        type: actionTypes.SET_SR_STRUCTURE,
        structure: contentstructure
    }
};

export const fetchSessionRecordingStructureFailed = () => {
    return {
        type: actionTypes.FETCH_SR_STRUCTURE_FAILED
    }
};


export const fetchSessionRecordingStructure = () => {
    return (dispatch, getState) => {
        const courseCode = getState().course.current_course.courseCode
        const contentList = getState().content.contentlist
        dispatch(fetchSessionRecordingStructureStart());
        axiosConfig().get('AppCore/GetSessionRecordingStructure', {
            params: {
                CourseCode: courseCode
            }
        }).then(response => {
            let contentstructure = [...contentList.Add.contentstructure]
            if (contentstructure.length && response.data.length) {
                let newContentstructure = contentstructure.map(content => {
                    let newContent = { ...content }
                    if (newContent.isContentLocked === true) {
                        response.data.forEach(data => {
                            if (data.structureId === newContent.structureId)
                                newContent.isContentLocked = false
                        })
                        return newContent
                    } else {
                        return newContent
                    }
                })
                dispatch(fetchSessionRecordingStructureSuccess(newContentstructure))
            }

        }).catch(function (error) {
            dispatch(fetchSessionRecordingStructureFailed())
            console.log(error);
        });
    }
}

