import React from "react";

// @material-ui/core
import Skeleton from '@material-ui/lab/Skeleton';


// swiper
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';


const SkeletonCard = (props) => {
    return (<React.Fragment>
  
      <div className="component component-grid component-livesession bg-white shadow">
        <h5 className="component__title">today's live session</h5>
  
        <Swiper
          spaceBetween={20}
          slidesPerView={2.3}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Navigation]}
        >
          {['001', '010','0101'].map((data, idex) => (
            <SwiperSlide key={idex + "SLLIVE"}>
              <div className="card">
                <div className="card__body">
                  <div className="wrapper">
                    <div className="content">
                      <Skeleton animation="wave" width="20%" />
                      <Skeleton animation="wave" width="80%" />
                      <Skeleton animation="wave" width="80%" />
                      <Skeleton animation="wave" variant="rect" width="25%" />
                    </div>
                    <div className="media media__img img-1by1">
                      <Skeleton animation="wave" variant="rect" width={70} height={70} />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
          }
        </Swiper>
      </div>
    </React.Fragment>
    )
  }

  export default SkeletonCard;