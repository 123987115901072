import * as actionTypes from '../actionTypes';
import axiosConfig from "shared/interceptor/axiosInstance";

//---Todays Online Classes---
export const setTodaysOnlineClasses = (MEETINGS_SCHEDULE) => {
    return {
        type: actionTypes.SET_TODAYS_ONLINE_CLASSES,
        schedule: MEETINGS_SCHEDULE
    }
}

export const fetchTodaysOnlineClasses = () => {
    return async (dispatch, getState) => {
        await axiosConfig().post('AppCore/V2/GetScheduleList', {
            "typeId": 1
        }).then(response => {
            dispatch(setTodaysOnlineClasses(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}


//---Upcoming Classes---
export const setUpcomingOnlineClasses = (MEETINGS_SCHEDULE) => {
    return {
        type: actionTypes.SET_UPCOMING_ONLINE_CLASSES,
        schedule: MEETINGS_SCHEDULE
    }
}

export const fetchUpcomingOnlineClasses = () => {
    return async (dispatch, getState) => {
        await axiosConfig().post('AppCore/V2/GetScheduleList', {
            "typeId": 3,
            "dayDuration": 2, //value should be positive for upconing sessions
            "subjectId": 0 // send 0 if no subject selected in dropdown
        }).then(response => {
            dispatch(setUpcomingOnlineClasses(response.data.reverse()))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}

//---Past Classes---
export const setPastOnlineClasses = (MEETINGS_SCHEDULE) => {
    return {
        type: actionTypes.SET_PAST_ONLINE_CLASSES,
        schedule: MEETINGS_SCHEDULE
    }
}

export const fetchPastOnlineClasses = () => {
    return async (dispatch, getState) => {
        await axiosConfig().post('AppCore/V2/GetScheduleList', {
            "typeId": 2,
            "dayDuration": -2, //value should be ngative for previous sessions
            "subjectId": 0 // send 0 if no subject selected in dropdown
        }).then(response => {
            dispatch(setPastOnlineClasses(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}

// --- Join Session ---
export const joinSession = (ScheduleId) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().post('AppCore/JoinSession', null, {
            params: {
                UserId:UserId,
                ScheduleId:ScheduleId
            }
        }).catch(function (error) {
            // if (error.response.data)
            //     throw new Error(error.response.data.error)
            // throw new Error(error.message);
        });
    }
}