import React from "react";
// @material-ui/core
import Skeleton from '@material-ui/lab/Skeleton';
import { Divider, Typography } from '@material-ui/core';

const SkeletonCard = (props) => {
    return (
        <div className="component component-grid component-counts">
            <div className="row">
                <div className="col-md-6">
                    <div className="shadow bg-white">
                        <Typography variant="subtitle1" className="title">{'Tests'}</Typography>
                        <Skeleton className="rating" animation="wave" />
                        <Skeleton className="rating" animation="wave" />
                        <Skeleton className="rating" animation="wave" />
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="shadow bg-white" >
                        <Typography variant="subtitle1" className="title">{'Tests'}</Typography>
                        <Skeleton className="rating" animation="wave" />
                        <Skeleton className="rating" animation="wave" />
                        <Skeleton className="rating" animation="wave" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonCard;