import * as actionTypes from 'store/definitions/actionTypes'

const initialState = {
    registerUser: {
        "firstName": "",
        "middleName": "",
        "lastName": "",
        "emailId": "",
        "contactNo": "",
        "pincode": "",
        "cityName": "",
        "stateName": "",
        "countryName": "",
        "isOTPVerified": true,
        "otpno": "",
        "userId": ""
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_REGISTER_USER:
            return {
                ...state,
                registerUser: { ...initialState.registerUser }
            }
        case actionTypes.SET_SELF_REGISTRATION_DATA:
            return {
                ...state,
                registerUser: {
                    ...state.registerUser,
                    "firstName": action.payload.firstName,
                    "middleName": action.payload.middleName,
                    "lastName": action.payload.lastName,
                    "emailId": action.payload.email,
                    "contactNo": action.payload.contactNumber,
                    "pincode": action.payload.pincode,
                    "isOTPVerified": true,
                    "otpno": action.payload.otpno,
                }
            }
        case actionTypes.SET_CITY_STATE_COUNTRY_DATA: {
            return {
                ...state,
                registerUser: {
                    ...state.registerUser,
                    "cityName": action.payload.district,
                    "stateName": action.payload.state,
                    "countryName": action.payload.country,
                }
            }
        }
        case actionTypes.SET_REGISTRATION_USERID: {
            return {
                ...state,
                registerUser: {
                    ...state.registerUser,
                    "userId": action.payload,
                }
            }
        }


        default:
            return state;
    }

}


export default reducer