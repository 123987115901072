import React, { useState,useEffect } from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
// core components
import PageNavigation from 'shared/components/Navbars/PageNavigation'

//import style
import 'assets/css/loader.css';

const DocView = (props) => {
    
    const { navigation,wrapperStyle } = props;
    
    const [loading, setLoading] = useState(true)
    const [display, setDisplay] = useState('none')

    const dispatch = useDispatch()
    const content = useSelector(state => state.content.current_content)
    const [accessDuration, setAccessDuration] = useState(0)

    const hideSpinner = () => {
        setLoading(false);
        setDisplay('block')
    };

    useEffect(() => {
        let accessTime=0;
        const timer = setInterval(() => {
            accessTime++
            setAccessDuration(accessTime)
        }, 1000);
        return () =>{
            clearTimeout(timer)};
      }, []);

      useEffect(() => {
        if (accessDuration > 10) {
            dispatch(questActions.updateContentAccessDuration(accessDuration))
        }
    }, [accessDuration])

    return (
        <div>
            {/*-----PageNavigation----- */}
            {navigation && <PageNavigation logoText={content.courseContentDisplayName}/>}

            {/*-----page-container----- */}
            <div className={`${wrapperStyle ? 'content-container-task' :'page-container-5015'}`}>
                  {loading ? (
                    <div style={{
                        height: 'calc(100vh - 160px)',
                        display: 'flex',
                        alignItems: 'center',
                        width:'100%'
                    }}>
                        <div className="loader">Loading...</div>
                    </div>

                ) : null}
                <iframe
                    title="HTMLViewer"
                    style={{
                        position: 'relative',
                        left: 0,
                        // bottom: 0,
                        right: 0,
                        width: '100%',
                        minHeight: 'calc(100vh - 160px)',
                        border: "none",
                        display: display,
                        padding:50
                    }}
                    onLoad={hideSpinner}
                    src={content.contentFileUrl}
                />

            </div>
        </div>
    );

}

DocView.defaultProps = {
    navigation: true,
    wrapperStyle: false,
};

export default DocView;

