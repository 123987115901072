import * as actionTypes from '../definitions/actionTypes';
// import axios
import axiosConfig from "shared/interceptor/axiosInstance";
// import for initialize User Quest List
import { initMasterCourse } from 'store/actions/userCourseList'

export const fetchUserInfoStart = () => {
    return {
        type: actionTypes.FETCH_USERINFO_START
    }
}

export const setUserInfo = (userInfo) => {
    return {
        type: actionTypes.SET_USERINFO,
        userinfo: userInfo
    }
}

export const fetchUserInfoFailed = () => {
    return {
        type: actionTypes.FETCH_USERINFO_FAILED
    }
}

export const initUserInfo = () => {
    return dispatch => {
        dispatch(fetchUserInfoStart());
        axiosConfig().get('/AppCore/GetUserDetails').then(response => {
            if (response.data.isSuccess === false)
                throw new Error(response.data.message);
            else {
                dispatch(setUserInfo(response.data))
                dispatch(initMasterCourse(response.data.userId))
            }
        }).catch(function (error) {
            dispatch(fetchUserInfoFailed())
        });
    }

}

//----- Post Change Password ------------------
export const postChangePasswordStart = () => {
    return {
        type: actionTypes.POST_CHANGE_PASSWORD_START
    }
};

export const postChangePasswordSuccess = () => {
    return {
        type: actionTypes.POST_CHANGE_PASSWORD_SUCCESS
    }
};

export const postChangePasswordFailed = () => {
    return {
        type: actionTypes.POST_CHANGE_PASSWORD_FAILED
    }
};


export const postChangePassword = (oldPassword, newPassword) => {
    return dispatch => {
        dispatch(postChangePasswordStart());
        axiosConfig().get('/QuestUser/ChangePassword', {
            params: {
                oldPassword: oldPassword,
                newPassword: newPassword
            }
        })
            .then(response => {
                dispatch(postChangePasswordSuccess())
            }).catch((error) => {
                console.log(error);
                dispatch(postChangePasswordFailed())
            });
    }
}

///-----  Logout User ------------------
export const logoutUser = () => dispatch => {
    dispatch({
        type: 'LOG_OUT'
    })
}

//---setAuthError----
export const setAuthError = (auth) => {
    return {
        type: actionTypes.AUTH_FAILED,
        auth: auth
    }
};