import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import moment from 'moment';
//import Custom Components
import Autocomplete from "shared/components/Autocomplete/Autocomplete"
import GridItem from "shared/components/Grid/GridItem.js";
import GridContainer from "shared/components/Grid/GridContainer.js"
import Button from "shared/components/CustomButtons/Button.js";

//@material-ui
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//Redux
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'store/actions/index'

//util
import { hrefUrl, eventTracking } from 'shared/utils/commonfunctions'

// custom style
import useStyles from "./Styles";


const LoginDetails = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();
    const { renderat } = props

    const [registerLoading, setRegisterLoading] = useState(false)
    const [alert, setAlert] = useState({ message: '', type: "" })

    const stateCourseList = useSelector(state => state.demoSchedule.CourseList);
    const stateSubjectList = useSelector(state => state.demoSchedule.SubjectList);
    const stateScheduleList = useSelector(state => state.demoSchedule.ScheduleList);
    const configs = useSelector(state => state.appconfigs.configs);

    const BoardList = useSelector(state => state.demoSchedule.BoardList)
    const [selectedBoard, setSelectedBoard] = React.useState(null);
    const [errorBoard, setErrorBoard] = useState("")

    const [courseList, setCourseList] = React.useState([])
    const [selectedCourse, setSelectedCourse] = React.useState(null);
    const [errorCourse, setErrorCourse] = useState("")

    const [selectedSubject, setSelectedSubject] = React.useState(null);
    const [errorSubject, setErrorSubject] = useState("")

    const [selectedSchedule, setSelectedSchedule] = React.useState(null);
    const [errorSchedule, setErrorSchedule] = useState("")

    useEffect(() => {
        fetchBoardList()
        fetchCourseList()
    }, [dispatch])

    const fetchBoardList = async () => {
        dispatch(actions.fetchBoardList())
            .then()
            .catch(error => {
                setAlert({ type: 'error', message: error.message })
            });
    }

    const fetchCourseList = async () => {
        dispatch(actions.fetchCourseList())
            .then()
            .catch(error => {
                setAlert({ type: 'error', message: error.message })
            });
    }


    //Board
    const onChnageBoard = (event, newValue) => {
        setSelectedBoard(newValue)
        if (newValue)
            setCourseList(stateCourseList.filter(data => data.boardId === newValue.boardId))
        else
            setCourseList([])
        setSelectedCourse(null)
    }

    // Course
    const onChnageCourse = (event, newValue) => {
        setSelectedCourse(newValue)
        if (newValue)
            fetchSubjectList(newValue.courseCode)
        else
            dispatch(actions.setSubjectList([]))
        setSelectedSubject(null)
    }

    const fetchSubjectList = async (courseCode) => {
        dispatch(actions.fetchSubjectList(courseCode))
            .then()
            .catch(error => {
                setAlert({ type: 'error', message: error.message })
            });
    }

    // Subject
    const onChnageSubject = (event, newValue) => {
        setSelectedSubject(newValue)
        if (newValue)
            fetchScheduleList(selectedCourse.courseId, newValue.structureId)
        else
            dispatch(actions.setScheduleList([]))
        setSelectedSchedule(null)
    }

    const fetchScheduleList = async (courseCode, structureId) => {
        dispatch(actions.fetchScheduleList(courseCode, structureId))
            .then()
            .catch(error => {
                setAlert({ type: 'error', message: error.message })
            });
    }

    // Schedule
    const onChnageSchedule = (event, newValue) => {
        setSelectedSchedule(newValue)

    }

    const clearAlert = () => {
        setAlert({ message: '', type: "" })
    }


    const handleRegisterDemo = () => {
        if (validateUserInput()) {
            setRegisterLoading(true)

            const eventData = {
                Board: selectedBoard.boardName,
                Course: selectedCourse.courseDisplayName,
                Subject: selectedSubject.structureDisplayName,
                Schedule: selectedSchedule.scheduleName
            };

            dispatch(actions.registerDemo(selectedBoard, selectedCourse, selectedSubject, selectedSchedule, renderat))
                .then(() => {
                    //Event Tracking
                    eventTracking("BookDemoCompleted", { ...eventData, 'Result': "Success" })
                    setRegisterLoading(false)
                    if (renderat === 'home')
                        history.push('/home/schedules')
                    else
                        history.push('/thankyou')
                })
                .catch(error => {
                    //Event Tracking
                    eventTracking("BookDemoCompleted", { ...eventData, 'Result': "Fail" })
                    
                    setRegisterLoading(false)
                    setAlert({ type: 'error', message: error.message })
                });
        }
    }

    const validateUserInput = () => {
        let validate = true

        if (selectedBoard == null) {
            setErrorBoard("Board is required")
            validate = false
        } else {
            setErrorBoard("")
        }

        if (selectedCourse == null) {
            setErrorCourse("Course is required")
            validate = false
        } else {
            setErrorCourse("")
        }

        if (selectedSubject == null) {
            setErrorSubject("Subject is required")
            validate = false
        } else {
            setErrorSubject("")
        }

        if (selectedSchedule == null) {
            setErrorSchedule("Schedule is required")
            validate = false
        } else {
            setErrorSchedule("")
        }

        return validate
    }

    return (
        <React.Fragment>
            <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                    <Autocomplete
                        debug
                        options={BoardList}
                        value={selectedBoard}
                        onChange={onChnageBoard}
                        getOptionLabel={(option) => option.boardName ? option.boardName : ""}
                        labelText="Board"
                        errorText={errorBoard}
                        placeholder='Select Board'
                    />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <Autocomplete
                        options={courseList}
                        value={selectedCourse}
                        onChange={onChnageCourse}
                        getOptionLabel={(option) => option.courseDisplayName ? option.courseDisplayName : ""}
                        labelText="Course"
                        placeholder="Select Course"
                        errorText={errorCourse}
                    />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <Autocomplete
                        options={stateSubjectList}
                        value={selectedSubject}
                        onChange={onChnageSubject}
                        getOptionLabel={(option) => option.structureDisplayName ? option.structureDisplayName : ""}
                        labelText="Subject"
                        placeholder="Select Subject"
                        errorText={errorSubject}
                    />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <Autocomplete
                        options={stateScheduleList}
                        value={selectedSchedule}
                        onChange={onChnageSchedule}
                        getOptionLabel={(option) => option.scheduleName ? option.scheduleName + " " + moment(option.startTime).format('DD/MM/YYYY LT') : ""}
                        labelText="Schedule"
                        placeholder="Select Schedule"
                        errorText={errorSchedule}
                        renderOption={(option, { selected }) => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {option.scheduleName ? option.scheduleName : ""}
                                <div className={classes.autosubheader}>{option.startTime ? moment(option.startTime).format('DD/MM/YYYY LT') : ""}</div>
                            </div>
                        )}
                    />
                </GridItem>


                <GridItem xs={12} sm={12} md={12} className={classes.actionsGridItem}>
                    <div className={classes.actionsContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            disabled={registerLoading}
                            onClick={handleRegisterDemo}
                        >
                            {registerLoading ? 'Please Wait' : 'Book Demo'}
                        </Button>
                        {registerLoading &&
                            <div className={classes.buttonProceed}>
                                <CircularProgress size={15} style={{ color: 'white' }} />
                            </div>}
                    </div>
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                    <Typography note="true" variant="caption" paragraph display="inline" style={{ letterSpacing: 'normal' }}>
                        {'By Booking a demo, you agree to our '}
                        <span onClick={() => hrefUrl(configs.tandcurl)} className={classes.hyperText}>Terms and Conditions</span>
                        {' and that you have read our '}
                        <span className={classes.hyperText} onClick={() => hrefUrl(configs.prvpolurl)}>Privacy Policy.</span>
                    </Typography>
                </GridItem>
            </GridContainer>

            <Snackbar open={alert.message.length !== 0 ? true : false} autoHideDuration={6000} onClose={clearAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                {alert.message.length !== 0
                    ? <MuiAlert elevation={6} variant="filled" onClose={clearAlert} severity={alert.type}>
                        {alert.message}
                    </MuiAlert>
                    : null
                }
            </Snackbar>
        </React.Fragment>

    )
}

export default LoginDetails;