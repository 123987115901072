import * as actionTypes from '../definitions/actionTypes';
// import axios
import axiosConfig from "shared/interceptor/axiosInstance";


export const setNotification = (NOTIFICATION) => {
    return {
        type: actionTypes.SET_NOTIFICATION,
        data: NOTIFICATION
    }
}

export const fetchNotifications = () => {
    return async (dispatch, getState) => {
        await axiosConfig().get('/AppCore/GetNotification').then(response => {
            dispatch(setNotification(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}

export const updateNotifications = (mappingId) => {
    return async (dispatch, getState) => {
        let newNotificationList = getState().notification.notification.filter(data => data.mappingId !== mappingId)
        dispatch(setNotification(newNotificationList))
    }
}