import React, { useEffect, useState, Suspense, lazy } from "react";
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

// @material-ui/core
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

//Custom Components
import GridItem from "shared/components/Grid/GridItem.js";
import GridContainer from "shared/components/Grid/GridContainer.js"


//View
import OperationCards from './ContentTree/ContentTree'
import SubjectsTab from './Subjects/SubjectsTab'

// styles
import useStyles from "assets/jss/components/views/taskStyle";
import './style.css'

const ContentViewer = lazy(() => import('components/Views/ContentViewer/RecordingViewer/RecordingViewer'));


const Operations = (props) => {
    const { page__title } = props
    const dispatch = useDispatch()
    const classes = useStyles();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const [fullscreen, setFullscreen] = useState(false)

    const contentList = useSelector(state => state.content.contentlist)
    const contentLoading = useSelector(state => state.content.loading)
    const contentError = useSelector(state => state.content.error)
    const current_recorded_sessions = useSelector(state => state.recordedSessions.current_session)

    const setScreen = isFullscreen => {
        setFullscreen(isFullscreen)
    }

    useEffect(() => {
        page__title('Recorded Sessions')
    }, [])

    useEffect(() => {
        if (contentError === false && contentLoading === false) {
            dispatch(questActions.setCurrentSession({}))
            dispatch(questActions.fetchRecordedSessions())
        }
    }, [contentLoading])



    const handleContent = (Content) => {
        dispatch(questActions.setCurrentSession(Content))
        if (sm) {
            props.history.push('/recordingviewer')
        }

    }

    return (
        <div className="page-content__body" style={{ padding: 0, paddingTop: 48 }}>


                <SubjectsTab 
                contentList={contentList} 
                onSubjectChange={setScreen}
                />
                {sm
                    ? <GridItem xs={12} sm={12} md={4} className="operation-container">
                        <div className={`${classes.operationCardContainer}`} >
                            <OperationCards contentList={contentList} onClick={handleContent} currentSessions={current_recorded_sessions} ></OperationCards>
                        </div>
                    </GridItem>
                    : <GridContainer className={classes.gridContainer}>
                        {fullscreen
                            ? null
                            : <GridItem xs={12} sm={12} md={3} className="operation-container">
                                <div className={`${classes.operationCardContainer}`} style={{height: 'calc(100vh - 10px)'}}>
                                    <OperationCards contentList={contentList} onClick={handleContent} currentSessions={current_recorded_sessions}></OperationCards>
                                </div>
                            </GridItem>
                        }
                        <GridItem xs={12} sm={12} md={fullscreen ? 12 : 9} className={fullscreen ? 'topics-container-fullscreen' : "topics-container"} >
                            {Object.keys(current_recorded_sessions).length !==0
                                ? <div className={fullscreen ? classes.fullscreenContainer : `${classes.vedioContainer} `}>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <ContentViewer
                                            platform='web'
                                            navigation={false}
                                            wrapperStyle={true}
                                            fullscreen={fullscreen}
                                            setFullscreen={setScreen}
                                        />
                                    </Suspense>
                                </div>
                                : null
                            }
                        </GridItem>
                    </GridContainer>
                }
            </div>
        
    )
}


export default withRouter(Operations);
