import React, { useEffect, useState } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
//redux
import { connect, useDispatch } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { Divider, LinearProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
// @material-ui/icons/
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

//Custom Components
import GridItem from "shared/components/Grid/GridItem.js";
import GridContainer from "shared/components/Grid/GridContainer.js"
import Header from "shared/components/Card/CardHeader.js";
import CardBody from "shared/components/Card/CardBody.js";
import Button from "shared/components/CustomButtons/Button.js";
import PageNavigation from 'shared/components/Navbars/PageNavigation';
import Card from "shared/components/Card/Card.js";
import Dialog from "shared/components/Dialog/Dialog";
import AlertDialog from "shared/components/Dialog/AlertDialog";


// Views
import StepContent from './components/QuestionAnswers'
import Summary from './components/AssessmentSummary'
import Help from './components/Help'

//variables
import { TODAY_DATE_TIME } from "shared/variables/general.js";

//Lottie
import Lottie from 'react-lottie';
import animationData from 'assets/lotties/times-up';



// styles
import Styles from "assets/jss/components/views/assessmentStyle";
import "./styles.css";
const useStyles = makeStyles(Styles);

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const Assessment = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch()

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const TestDuration = (props.test.testTime / 60)
    const [minutes, setMinutes] = useState(TestDuration);
    const [seconds, setSeconds] = useState(0);


    const [mode, setMode] = React.useState("out-in");
    const [helpDialog, setHelpDialog] = React.useState(false);
    const [alertDialog, setAlertDialog] = React.useState(false);
    const [isTimesup, setTimesup] = React.useState(false);

    const [revieweMode, setRevieweMode] = React.useState(false);

    useEffect(() => {
        if (props.test.testAttemptedDuration > 0) {
            let totalSeconds = Number(props.test.testAttemptedDuration);
            totalSeconds %= 3600;
            let newMinutes = Math.floor(totalSeconds / 60);

            if (Number(props.test.testTime) <= newMinutes) {
                newMinutes = minutes - (newMinutes + 1);
                // const newSeconds = 60 - (totalSeconds % 60);
                setMinutes(0)
            } else {
                newMinutes = minutes - (newMinutes + 1);
                const newSeconds = 60 - (totalSeconds % 60);
                setMinutes(newMinutes)
                setSeconds(newSeconds)
            }
        }
    }, [])




    useEffect(() => {
        let myInterval = setInterval(() => {
            if (props.test.testAttemptCount > 0) {
                clearInterval(myInterval)
            }
            else {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(myInterval)
                        setTimesup(true);
                        setTimeout(() => {
                            setTimesup(false);
                            handlePostTestAttempt()
                        }, 3000);
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                }
                props.updateTestAttemptedDuration(1)
            }

        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [seconds]);



    const totalSteps = () => {
        return props.questions.qadd.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };


    const handleNext = () => {
        const newActiveStep = activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleReviewe = () => {
        setRevieweMode(true)
        setActiveStep(0);
    };

    /*-----------------------handle Alert Close Dialog--------------------*/
    const handleAlertCloseDialog = () => {
        setAlertDialog(false);
    };

    /*-----------------------handle Alert Open Dialog--------------------*/
    const handleAlertOpenDialog = () => {
        setAlertDialog(true);
    };

    /*-----------------------handle Alert onOK--------------------*/
    const handleAlertOK = async () => {
        handleAlertCloseDialog()
        await handlePostTestAttempt()
    };



    /*-----------------------handle Help Close Dialog--------------------*/
    const handleCloseDialog = () => {
        setHelpDialog(false);
    };


    /*-----------------------handle Help Open Dialog--------------------*/
    const handleOpenDialog = () => {
        setHelpDialog(true);
    };


    const handlePostTestAttempt =async () => {
        const skipCount = props.answers.reduce((Count, currentValue) => Count + (currentValue.isQuestionSkipped === "0" ? 1 : 0), 0)
        const rightCount = props.answers.reduce((Count, currentValue) => Count + parseInt(currentValue.isAnsweredCorrect), 0)
        const wrongCount = props.answers.reduce((Count, currentValue) => Count + (currentValue.isAnsweredCorrect === "0" ? 1 : 0), 0)
        const marksObtained = props.answers.reduce((Count, currentValue) => Count + parseFloat(currentValue.questionMarksObtained), 0)
   
        await dispatch(questActions.updateTestAttemptCount(skipCount, rightCount, wrongCount, marksObtained))
        props.onPostTestAttempt()
    }

    return (
        <div>
            {/*-----PageNavigation----- */}
            <PageNavigation logoText={props.test.courseContentDisplayName} />
            {/*-----Dialog----- */}
            {isTimesup &&
                <Backdrop open={isTimesup} className={classes.backdrop}>
                    <Lottie options={defaultOptions}
                        height={400}
                        width={400}
                        isStopped={!isTimesup} />
                </Backdrop>
            }

            {/*-----Alert Dialog----- */}
            <AlertDialog
                open={alertDialog}
                type="info"
                title={"Are you sure you want to Submit?"}
                contentText={null}
                cancelable={() => { }}
                action={[
                    {
                        text: 'Cancel',
                        onPress: handleAlertCloseDialog,
                        style: 'cancel'
                    },
                    { text: 'OK', onPress: handleAlertOK }
                ]}
            />

            {/*-----Dialog Help----- */}
            {helpDialog
                ? <Dialog open={helpDialog} maxWidth={"xs"} scroll="paper" TransitionComponent={Transition} >
                    <Help onClose={handleCloseDialog} />
                </Dialog>
                : null
            }


            {/*-----page-container----- */}
            <div className='assm-instruction-container'>

                {/*-----Grid----- */}
                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={12} sm={12} md={12} className={classes.cardIconStyle} />
                </GridContainer>

                <GridContainer className={classes.gridContainer1}>
                    <GridItem xs={12} sm={12} md={7} className={classes.centerContainer}>
                        <Card className={classes.cardStyle1}>
                            <Paper elevation={0} style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, height: '100%' }}>

                                {props.test.testAttemptCount === 0 ? (<React.Fragment>
                                    <Header className={classes.cardHeader} title={minutes + ":" + (seconds < 10 ? `0${seconds}` : seconds)} >
                                        {/* <div>{props.test.testAttemptedDuration}</div> */}
                                        {/* <Button color="primary" round outlined className={classes.buttonStyle} onClick={handleOpenDialog}>
                                            Help
                                        </Button> */}
                                    </Header>
                                    <Divider></Divider>
                                </React.Fragment>
                                ) : null}


                                <CardBody className={classes.stepperCardBody}>
                                    <GridContainer>
                                        {props.test.testAttemptCount === 0 || revieweMode
                                            ? <Stepper nonLinear activeStep={activeStep} className={classes.stepperStyle}>
                                                {props.questions.qadd.map((question, index) => (
                                                    <Step key={"btn" + question.questionCode}>
                                                        <StepButton onClick={handleStep(index)} completed={props.answers[index].IsQuestionSkipped == !0} />
                                                    </Step>
                                                ))}
                                            </Stepper>
                                            : null}
                                    </GridContainer>
                                </CardBody>
                            </Paper>
                        </Card>
                    </GridItem>
                </GridContainer>

                <GridContainer className={classes.gridContainer2}>
                    <GridItem xs={12} sm={12} md={7} className={classes.gridItem}>
                        <Card className={`scroll ${classes.cardStyle2}`}>
                            <Paper elevation={0} style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, height: '100%' }}>

                                <CardBody className={classes.questioAnswersCardBody}>
                                    <GridContainer >
                                        <GridItem xs={12} sm={12} md={12}>
                                            {props.test.testAttemptCount > 0 && !revieweMode ? (
                                                <Summary {...props}></Summary>
                                            ) : (
                                                <SwitchTransition mode={mode}>
                                                    <CSSTransition
                                                        key={activeStep}
                                                        addEndListener={(node, done) => {
                                                            node.addEventListener("transitionend", done, false);
                                                        }}
                                                        classNames="asm-fade"
                                                    >
                                                        <StepContent {...props.questions.qadd[activeStep]} reviewe={revieweMode} />
                                                    </CSSTransition>
                                                </SwitchTransition>

                                            )}
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Paper >
                        </Card >
                    </GridItem >
                </GridContainer >

                <GridContainer className={classes.gridContainer4}>
                    <GridItem xs={12} sm={12} md={7} className={classes.centerContainer}>
                        <Card className={classes.cardStyle3}>
                            <Paper elevation={0} style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, height: '100%' }}>

                                <GridContainer >

                                    <GridItem xs={12} sm={12} md={12}>

                                        {props.test.testAttemptCount > 0 ? (
                                            revieweMode
                                                ? <div className={classes.divButtonsStyle}>
                                                    <Button simple={true} color="primary" className={classes.buttonStyle} disabled={activeStep === 0} onClick={handleBack} startIcon={<ArrowBackIosIcon />} >
                                                        Back
                                                    </Button>
                                                    <Button color="primary" round outlined onClick={() => { props.history.replace('/home/resources') }} className={classes.buttonStyle}>
                                                        Close
                                                    </Button>
                                                    <Button simple={true} color="primary" className={classes.buttonStyle} onClick={handleNext} disabled={isLastStep()} endIcon={<ArrowForwardIosIcon />}>
                                                        Next
                                                    </Button>
                                                </div>
                                                : <div className={classes.divButtonsStyle}>
                                                    <Button color="primary" className={classes.buttonStyle} onClick={handleReviewe} >
                                                        Review
                                                    </Button>

                                                    <Button onClick={() => { props.history.replace('/home/resources') }} className={classes.buttonStyle}>
                                                        Close
                                                    </Button>
                                                </div>

                                        ) : (
                                            <div className={classes.divButtonsStyle}>
                                                <Button simple={true} color="primary" className={classes.buttonStyle} disabled={activeStep === 0} onClick={handleBack} startIcon={<ArrowBackIosIcon />} >
                                                    Back
                                                </Button>
                                                <Button color="primary" round outlined className={classes.buttonStyle} onClick={handleAlertOpenDialog}>
                                                    Submit
                                                </Button>
                                                <Button simple={true} color="primary" className={classes.buttonStyle} onClick={handleNext} disabled={isLastStep()} endIcon={<ArrowForwardIosIcon />}>
                                                    Next
                                                </Button>
                                            </div>

                                        )}
                                    </GridItem>
                                </GridContainer>

                            </Paper>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div >

        </div >

    )
}


const mapSateToProps = state => {
    return {
        test: state.content.current_test,
        questions: state.questions.questions,
        answers: state.questions.answers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateTestAttemptCount: () => dispatch(questActions.updateTestAttemptCount()),// For check Current Test is attempted or not 
        onPostTestAttempt: () => dispatch(questActions.postTestAttempt()),
        updateTestAttemptedDuration: (duration) => dispatch(questActions.updateTestAttemptedDuration(duration)),
    }
}


export default connect(mapSateToProps, mapDispatchToProps)(Assessment);
