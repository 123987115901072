import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
//redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

//store
import { store, persistor } from './store/index'

// import './index.css';
import "assets/css/index.css?v=1.8.0";
import "assets/scss/main.scss";
import * as serviceWorker from './serviceWorker';
import Layout from './components/Layouts/Layout';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);


ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={createBrowserHistory()}>
                    <Layout />
                </Router>
            </PersistGate>
        </Provider>
    </ThemeProvider>
    ,
    document.getElementById('root')
);

serviceWorker.unregister();
