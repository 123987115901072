import React from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment';


const TestHeader = (props) => {


    return (
        <a className="link__item link__header">
            <div className="inner-wrapper-col">
                <div className="wrapper wrapper-1">
                    <div className="inner-wrapper">
                        <span className="meta title">Name</span>
                    </div>
                </div>
                <div className="wrapper wrapper-4">
                    <span className="meta title">Status</span>
                </div>
                <div className="wrapper wrapper-5">
                    <span className="meta title">Start Date</span>
                </div>
            </div>

            <div className="inner-wrapper-col">

                <div className="wrapper wrapper-4">
                    <span className="meta title">End Date</span>
                </div>

                <div className="wrapper wrapper-5">
                    <span className="meta title">Total Questions</span>
                </div>

                <div className="wrapper wrapper-4">
                    <span className="meta title">Duration</span>
                </div>

                <div className="wrapper wrapper-5">
                    <span className="meta title">Marks</span>
                </div>

                <div className="wrapper wrapper-6">
                    <span className="meta title">Action</span>
                </div>
            </div>
        </a>
    )
}

export default TestHeader