import React from "react";
// @material-ui/core
import DateRangeIcon from '@material-ui/icons/DateRange';
import Skeleton from '@material-ui/lab/Skeleton';


const SkeletonCard = (props) => {
    const { bgColor } = props

    return (<React.Fragment>
        <section className="component component-upcoming mt-0">
            <div className="card bg-white">
                <div className="card__head">
                    <h6 className="card__title">upcoming classes</h6>
                    <DateRangeIcon className="material-icons ms-auto color-blue" />
                </div>
                <div className="card__body">
                    <div className="card__list">
                        {['001', '010'].map((data, idex) => (
                            <div key={idex + "_#UC"} className="card__item card__item-group">
                                <span className="date"><Skeleton className="meta title" animation="wave"></Skeleton></span>
                                {['001', '010'].map((v, i) =>
                                    <div className="card__item" key={idex +"_#UC_"+i}>
                                        <Skeleton animation="wave" variant="rect" className={`status ${bgColor[(i+idex) % 3]}`}>
                                        </Skeleton>
                                        <div className="wrapper">
                                            <Skeleton className="meta title" animation="wave"></Skeleton>
                                            <Skeleton className="meta name" animation="wave"></Skeleton>
                                            <Skeleton className="meta text" animation="wave"></Skeleton>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
    )
}

export default SkeletonCard;
