import React, { lazy, Suspense } from "react";
import Popover from '@material-ui/core/Popover';
//components
const Tooltip = lazy(() => import('./Tooltip'));



const OnlineSessionCard = (props) => {
    const {
        data,
        onShowTooltip,
        activeId
    } = props

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.currentTarget.style.backgroundColor='#10060626'
    };

    const handleClose = () => {
        anchorEl.style.backgroundColor=''
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <li className="list__group-item ">
            {/* <li className="list__group-item custom-tooltip"> */}
            {/* <div className="list__group-div" onClick={() => onShowTooltip(data.assignmentMappingId)}> */}
            <div className="list__group-div" onClick={handleClick}>
                <span>{data.subjectName} </span>
                <span> </span>
                {/* <span>{data.sTime} - {data.eTime}</span> */}
            </div>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <Suspense
                    fallback={
                        <div className="tooltip__card position--right" style={{ display: 'block' }}>
                            Loading...
                        </div>}>
                    <Tooltip
                        data={data}
                        onClose={handleClose}
                    >
                    </Tooltip>
                </Suspense>

            </Popover>


            {/* {activeId !== "" && activeId === data.assignmentMappingId
                ? <Suspense
                    fallback={
                        <div className="tooltip__card position--right" style={{ display: 'block' }}>
                            Loading...
                        </div>}>
                    <Tooltip
                        data={data}
                        onClose={() => onShowTooltip("")}
                    >
                    </Tooltip>
                </Suspense>
                : null
            } */}
        </li>
    )
}

export default OnlineSessionCard;
