import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    centerContainer: {
        margin: 'auto',
        /* width: 65%; */
        display: 'flex',
        flexDirection: 'column',
    },

    centerContainerTask: {
        height: 'inherit',
        width: '100%',
        padding: 0
    },

    footerDivStyle: {
        flexDirection: "row",
        display: "flex",
        alignItems: 'center',
        color: "#C0C0C0",
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: 0
        },
    },
    divStyle: {
        flexDirection: "row",
        display: "flex",
        alignItems: 'center'
    },


}));

export default useStyles;