import React, { useEffect, useState } from "react";
// @material-ui/core
// import { makeStyles, withStyles } from "@material-ui/core/styles";
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

// core components
import PageNavigation from 'shared/components/Navbars/PageNavigation'
import PDFView from 'shared/components/PDFView/PDFView'

// styles
// import styles from "assets/jss/components/views/PDFViewerStyle";
// const useStyles = makeStyles(styles);



const PDFViewer = (props) => {

    const { navigation, wrapperStyle } = props;

    const dispatch = useDispatch()
    const content = useSelector(state => state.content.current_content)
    const [accessDuration, setAccessDuration] = useState(0)

    useEffect(() => {
        let accessTime = 0;
        const timer = setInterval(() => {
            accessTime++;
            setAccessDuration(accessTime)
        }, 1000);
        return () => {
            clearTimeout(timer)
        };
    }, []);

    useEffect(() => {
        if (accessDuration > 10) {
            dispatch(questActions.updateContentAccessDuration(accessDuration))
        }
    }, [accessDuration])

    return (
        <div>
            {/*-----PageNavigation----- */}
            {navigation && <PageNavigation logoText={content.courseContentDisplayName} />}

            {/*-----page-container----- */}
            <div className={`${wrapperStyle ? 'content-container-task' : 'pdf-container'}`}>
                <PDFView fileurl={content.contentFileUrl} password="" />
            </div>
        </div>
    );
}


PDFViewer.defaultProps = {
    navigation: true,
    wrapperStyle: false,
};

export default PDFViewer;