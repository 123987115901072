import {
  successColor,
  // whiteColor,
  primaryColor,
  // grayColor,
  // hexToRgb,
  blackColor,
} from "assets/jss/material-dashboard-react.js";

const dashboardStyle = theme => ({

  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    // marginLeft: theme.spacing(3),
    // marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    },
    // textAlign: 'center',
  },
  infoText: {
    // color: primaryColor[4],
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    textAlign: 'center'
  },
  taskDiv: {
    padding: '0px 10px 0px 10px',
    borderRightColor: blackColor,//primaryColor[0],
    borderRightStyle: 'groove',
    borderRightWidth: 1,
    flexDirection: "row", display: 'flex', justifyContent: 'center'
  },
  timeDiv: {
    padding: '0px 10px 0px 10px',
    flexDirection: "row", display: 'flex', justifyContent: 'center'
  },

  pageCardBody: {
    minHeight: '100vh'
  },
  gridItemFooter: {
    padding: '0 !important'
  },
  cardStyle1: {
    marginBottom: "0",
    marginTop: '0px',
    // padding: 20,
    borderRadius: 0,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      padding: 5,
    },
    // backgroundColor:successColor[0]
  },
  cardStyle2: {
    height: '100%',
    marginBottom: "0",
    overflowY: 'auto',
    // padding: 20,
    marginTop: 0,
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      padding: 5,
    },
    // backgroundColor:successColor[0]
  },
  cardStyle3: {
    marginBottom: "0",
    padding: 5,
    marginTop: 0,
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      padding: 5,
    },
    [theme.breakpoints.up('sm')]: {
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
    },
    // backgroundColor:successColor[0]
  },
  successColor: {
    backgroundColor: primaryColor[0],
    margin: "0px",
    flex: '0 0 auto'
  },
  cardBodyStyle: {
    padding: 0
  },
  cardIconStyle: {
    width: '90px',
    height: '90px',
    marginTop: '20px',
    marginBottom: '40px',
  },

  imageStyle: {
    width: "auto",
    height: "-webkit-fill-available"
  },

  earnPoints: {
    // padding: '5px 20px 5px 20px',
    width: 'fit-content',
    textAlign: 'center',
    Display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: "15px"
  },
  buttonCenter: {
    display: 'flex',
    justifyContent: "center",
    marginBottom:'210px'
  },
  gridContainer1: {
    margin: "0px",
    marginTop: '-30px',
    flex: '0 0 auto',
    [theme.breakpoints.down('sm')]: {
      // height: '100%',
      margin: "0px -15px",
      marginTop: '-30px',
    },
  },

  gridContainer2: {
    margin: "0px",
    minHeight: '200px',
    flex: '1 1 auto',
    // padding: 8px 24px;
    overflowY: 'hidden',
    [theme.breakpoints.down('sm')]: {
      // height: '100%',
      margin: "0px -15px",
    },
    [theme.breakpoints.up('sm')]: {
      // height: '100%',
      maxHeight: '50vh',
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
    },
  },
  gridContainer3: {
    minHeight: '200px',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  gridContainer4: {
    margin: "0px",
    flex: '0 0 auto',
    [theme.breakpoints.down('sm')]: {
      // height: '100%',
      margin: "0px -15px",
    },

  },
  centerContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  gridItem:{
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',

  }
 
})

export default dashboardStyle;
