import React, { useState, useEffect, lazy, Suspense } from "react";
import moment from 'moment';
import { useLocation } from "react-router-dom";
//@material-ui/core
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';
// components
import TestHeader from "./components/Header";
import TestCard from './components/TestCard'

//Redux
import { useDispatch, useSelector } from 'react-redux'
import * as AssessmentActions from "components/Views/Assessment/store/actions"

const SkeletonCard = lazy(() => import('./components/SkeletonCard'));

const AssessmentTest = (props) => {

    const dispatch = useDispatch();
    const { page__title } = props

    const cdate = moment().utc().add(330, 'm').format("MM/DD/YYYY hh:mm:ss A")

    //state
    const assessments = useSelector(state => state.assessment.assessments)

    const [infoType, setInfoType] = useState(2)//1--Assignment / 2--Test / 0--All
    const [loading, setLoading] = useState(true)

    const [alert, setAlert] = useState({ open: false, severity: "success", message: "" })
    const [page, setPage] = React.useState(1);


    useEffect(() => {
        page__title('Tests')
    }, [])

    useEffect(() => {
        fetchAssessments()
    }, [infoType, page])


    const fetchAssessments = async () => {
        setLoading(true)
        await dispatch(AssessmentActions.fetchAssessments(infoType, page)).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error);
        });
    }


    // handle Alert Close
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, severity: "success", message: "" })
    }

    const handleChange = (event, value) => {
        setPage(value);
    };


    if (loading) {
        return (
            <Suspense fallback={<div > Loading...</div>}>
                <SkeletonCard />
            </Suspense>
        )
    }

    return (
        <div className="page-content__body">
 

            <section className="component-assignments component component-assessment">
                {/* <div className="filters">
                    <div className="label__group ms-md-3">
                        <span className="label border--green">{assessments.activeCount} active</span>
                        <span className="label border--red">{assessments.overDueCount} overdue</span>
                        <span className="label border--red">{assessments.finishedCount} finished</span>
                    </div>
                </div> */}
                <div className="card mt-3" style={{ minHeight: '60vh', backgroundColor: 'transparent', padding: '0px 15px' }}>
                    <div className="card__body">
                        <div className="link__list">
                            <TestHeader />
                            {assessments.assignmentList.sort((a, b) => { return a.orderById - b.orderById }).map(data => (
                                <TestCard
                                    key={data.testId}
                                    data={data}
                                    cdate={cdate}
                                />
                            ))}
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                        <Pagination
                            count={assessments.maxPageCount}
                            page={page}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </section>

            {/* Snackbar */}
            <Snackbar open={alert.open} autoHideDuration={2000} onClose={handleAlertClose}>
                {alert.open
                    ? <Alert elevation={6} onClose={handleAlertClose} severity={alert.severity} >
                        {alert.message}
                    </Alert>
                    : null
                }

            </Snackbar>
        </div>
    )
}

export default AssessmentTest