import * as actionTypes from '../definitions/actionTypes';
// import axios
import axiosConfig from "shared/interceptor/axiosInstance";


// --------------VerifyUser-----------------------------
export const setUserverificationStatus = (verification_status) => {
    return {
        type: actionTypes.SET_USER_verification_STATUS,
        status: verification_status
    }
}

export const verifyUser = (Username) => {
    return async (dispatch, getState) => {
        await axiosConfig().get('ResetPassword/VerifyUser', {
            params: {
                Username: Username,
            }
        }).then(response => {
            console.log(response.data);
            if (response.data.isSuccess)
                dispatch(setUserverificationStatus(response.data))
            else
                throw new Error(response.data.message)
        }).catch(function (error) {
            if (error.response)
                if (error.response.data)
                    throw new Error(error.response.data)
                else
                    throw new Error("something went wrong!")
            throw new Error(error.message)
        });
    }
}


// Validate OTP

export const setOTPverificationStatus = (verification_status) => {
    return {
        type: actionTypes.SET_OTP_verification_STATUS,
        OTP_STATUS: verification_status
    }
}


export const validateUernameOTP = (Username, verificationCode) => {
    return async (dispatch) => {
        await axiosConfig().get('ResetPassword/ValiateOTP', {
            params: {
                Username: Username,
                OTP: verificationCode
            }
        }).then(response => {
            dispatch(setOTPverificationStatus(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            // dispatch(fetchSharedDataFailed())
        });
    }
}

