import React, { useEffect, useState } from "react";
//Redux
import { connect } from 'react-redux'

//import reducer 
import * as questActions from 'store/actions/index'

//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { green } from '@material-ui/core/colors';

import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//Custom Components
import GridItem from "shared/components/Grid/GridItem.js";
import GridContainer from "shared/components/Grid/GridContainer.js"
import CustomMarkdown from 'shared/components/CustMarkdown/CustMarkdown'
import Progress from 'shared/components/Progress/Progress'

// styles
import Styles from "assets/jss/components/views/assessmentStyle";
const useStyles = makeStyles(Styles);


const QuestionAnswers = (props) => {
    const classes = useStyles();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (props.reviewe) {
                clearInterval(myInterval)
            } else {
                if (seconds < 60) {
                    setSeconds(seconds + 1);
                }
                if (seconds === 60) {
                    setMinutes(minutes + 1);
                    setSeconds(0);
                }
                props.updateAnswerDuration(1, props.questionCode)
            }
        }, 1000)

        return () => {
            clearInterval(myInterval);
        };
    });



    const handleChange = async (value) => {
        setLoading(true)
        await props.updateQuestioAnswers(value, props.questionCode)
        setLoading(false)
    };

    const CORRECT_ANSWER = props.answer ? props.answer.split(",").sort() : ""



    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Typography>
                        <CustomMarkdown>
                            {props.questionText}
                        </CustomMarkdown>
                    </Typography>
                </GridItem>
            </GridContainer>

            <List className={classes.root}>
                {/*----- Spinner---- */}
                {loading ? <Progress color="secondary" open={loading} /> : null}


                {props.options !== undefined && props.options.optionItems !== undefined
                    ? props.options.optionItems.map(option =>
                        <ListItem
                            disabled={props.reviewe}
                            className={classes.ansListItemStyle}
                            classes={{ disabled: classes.disabledListItem }}
                            style={{
                                backgroundColor: props.reviewe
                                    ? ((CORRECT_ANSWER.includes(option.id) && option.ischecked) || CORRECT_ANSWER.includes(option.id)
                                        ? "aliceblue"
                                        : option.ischecked ? "antiquewhite" : "")
                                    : ""
                            }}
                            key={"option" + option.id} role={undefined} dense button
                            onClick={() => handleChange(option.id)}

                        >
                            <ListItemIcon>
                                {props.questionTypeId === 1 || props.questionTypeId === 5 || props.questionTypeId === 4
                                    ? <Radio checked={option.ischecked} color="primary" />
                                    : props.questionTypeId === 2 ?
                                        <Checkbox checked={option.ischecked} color="primary" />
                                        : null
                                }
                            </ListItemIcon>
                            <ListItemText style={{ margin: 0 }} primary={<CustomMarkdown>{option.value}</CustomMarkdown>} />

                            {props.reviewe
                                ? CORRECT_ANSWER.includes(option.id)
                                    ? <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="comments">
                                            <CheckCircleIcon style={{ color: green[500] }} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                    : null
                                : null
                            }

                        </ListItem>
                    )
                    : null
                }

            </List>

            {(props.reviewe && props.explanation) && <div className='noselect'>
                <Typography className={classes.explanationHeaderText}>
                    Explanation :
                </Typography>
                <Typography className="center_p">
                    <CustomMarkdown   >
                        {props.explanation}
                    </CustomMarkdown>
                </Typography>
            </div>}
        </div>
    )
}




const mapDispatchToProps = dispatch => {
    return {
        updateQuestioAnswers: (answer, questionCode) => dispatch(questActions.updateQuestioAnswer(answer, questionCode)),
        updateAnswerDuration: (duration, questionCode) => dispatch(questActions.updateAnswerDuration(duration, questionCode)),

    }
}


export default connect(null, mapDispatchToProps)(QuestionAnswers);
