import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from 'react-redux'
import * as messageActions from "components/Views/Messaging/store/actions/index"
import * as questActions from 'store/actions/index'

//@material-ui/core
import { useTheme } from "@material-ui/core/styles";
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Pagination from '@material-ui/lab/Pagination';
import Dialog from '@material-ui/core/Dialog';

//Custom Components
import GridContainer from "shared/components/Grid/GridContainer.js"
import GridItem from "shared/components/Grid/GridItem.js";
// import MonthYearSelector from "components/Views/Messaging/components/MonthYearSelector";
import MessagesCard from "components/Views/Messaging/components/MessageComponents/MessagesCard";
import MessageDeatilsCard from "components/Views/Messaging/components/MessageComponents/MessageDeatilsCard";



//Style
import useStyles from './styles.js'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});


const Inbox = (props) => {
    const {
        loading,
        setLoading,
        page,
        onPageChange
    } = props

    const dispatch = useDispatch()

    const location = useLocation();

    const inboxMessages = useSelector(state => state.messages.inbox_messages)
    const currentMessage = useSelector(state => state.messages.current_inbox_messages)

    const classes = useStyles();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    const [messages, setMessages] = useState([])
    const [dialogState, setDialogState] = useState(false);

    useEffect(() => {
        if (inboxMessages.messageList.length) {
            if (location.state === null) {
                dispatch(messageActions.setCurrentInboxMessages(inboxMessages.messageList[0]))
            } else if (location.state !== null) {
                let NewMessage = inboxMessages.messageList.find(element => element.mappingId === location.state.mappingId);
                if (NewMessage !== undefined)
                    dispatch(messageActions.setCurrentInboxMessages(NewMessage))
                else
                    dispatch(messageActions.setCurrentInboxMessages(inboxMessages.messageList[0]))
            }
        }
        handleSearch("")
    }, [inboxMessages, location])

    useEffect(() => {
        if (currentMessage.mappingId !== undefined)
            if (currentMessage.isRead === false) {
                setLoading(true)
                dispatch(messageActions.updateMessageStatus(currentMessage.mappingId, currentMessage.typeId)).then(async () => {
                    dispatch(messageActions.setInboxMessages({
                        ...inboxMessages,
                        messageList: inboxMessages.messageList.map(data => {
                            if (currentMessage.mappingId === data.mappingId) {
                                data.isRead = true
                            }
                            return data
                        })
                    }))
                    dispatch(questActions.updateNotifications(currentMessage.mappingId)) // update for notifications
                    setLoading(false)
                }).catch(error => {
                    setLoading(false)
                    console.log(error);
                });
            }
    }, [currentMessage])

    // Dialog Close Message Deatils
    const handleCloseMessageDeatils = () => {
        dispatch(messageActions.setCurrentInboxMessages({}))
        setDialogState(false)
    };

    // Dialog Open Message Deatils
    const handleOpenMessageDeatils = async (message) => {
        dispatch(messageActions.setCurrentInboxMessages(message))
        setDialogState(true)
    };

    //handle Search
    const handleSearch = (searchInput) => {
        setMessages(inboxMessages.messageList.filter(data => data.firstName.toUpperCase().includes(searchInput.toUpperCase())
            || data.subject.toUpperCase().includes(searchInput.toUpperCase())
            || data.messageBody.toUpperCase().includes(searchInput.toUpperCase())
        ));
    }


    // update Starred Status
    const updateStarredStatus = async (message) => {
        setLoading(true)
        await dispatch(messageActions.updateStarredStatus(message.mappingId, message.typeId)).then(async () => {
            if (currentMessage.mappingId === message.mappingId)
                dispatch(messageActions.setCurrentInboxMessages({ ...message, isStarred: !currentMessage.isStarred }))
            dispatch(messageActions.setInboxMessages({
                ...inboxMessages,
                messageList: inboxMessages.messageList.map(data => {
                    if (message.mappingId === data.mappingId) {
                        data.isStarred = !data.isStarred
                    }
                    return data
                })
            }))
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error);
        });

    };

    const handleChange = (event, value) => {
        onPageChange(value);
    };


    return (
        <div className={classes.mainContainer}>
            <GridContainer className={classes.gridContainerStyle}>
                <GridItem xs={12} sm={12} md={4} className={classes.gridItemStyle}>
                    <MessagesCard
                        activeId={currentMessage.messageId}
                        data={messages}
                        onClickRow={handleOpenMessageDeatils}
                        onChangeStarStatus={updateStarredStatus}
                        loading={loading}
                        noDataMessage={"No data available"}
                        onSerch={handleSearch}
                    />

                    <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                        <Pagination
                            count={inboxMessages.maxPageCount}
                            page={page}
                            onChange={handleChange}
                        />
                    </div>
                </GridItem>

                {sm ?
                    <Dialog open={dialogState} fullScreen={sm} TransitionComponent={Transition} transitionDuration={400}>
                        <MessageDeatilsCard
                            sm={sm}
                            loading={loading}
                            data={currentMessage}
                            onChangeStarStatus={updateStarredStatus}
                            onClose={handleCloseMessageDeatils}
                        />
                    </Dialog>
                    : <GridItem xs={12} sm={12} md={8} className={classes.gridItemStyle}>
                        {Object.keys(currentMessage).length
                            ? <MessageDeatilsCard
                                sm={sm}
                                loading={loading}
                                data={currentMessage}
                                onChangeStarStatus={updateStarredStatus}
                                onClose={handleCloseMessageDeatils}
                            />
                            : null
                        }
                    </GridItem>
                }
            </GridContainer>

        </div>
    )
}

export default Inbox;