import {
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  grayColor, 
  blackColor
} from "assets/jss/material-dashboard-react.js";

const typographyStyle = {
  defaultFontStyle: {
    ...defaultFont,
    fontSize: "14px"
  },
  defaultHeaderMargins: {
    marginTop: "20px",
    marginBottom: "10px"
  },
  quote: {
    padding: "0px 20px",
    margin: "0 0 20px",
    // padding: "10px 20px",
    // fontSize: "17.5px",
    fontSize: "	0.875rem",
    fontWeight:600,
    borderLeft: "5px solid " + grayColor[10]
  },
  quoteText: {
    // margin: "0 0 10px",
    margin: "0px",
    fontStyle: "italic"
  },
  quoteAuthor: {
    display: "block",
    fontSize: "80%",
    lineHeight: "1.42857143",
    color: grayColor[1]
  },
  mutedText: {
    color: grayColor[1]
  },
  primaryText: {
    color: primaryColor[4]
  },
  infoText: {
    color: infoColor[0]
  },
  successText: {
    color: successColor[0]
  },
  warningText: {
    color: warningColor[0]
  },
  dangerText: {
    color: dangerColor[0]
  },
  inputLabel:{ 
    marginTop: 30, 
    ...defaultFont,
    color: grayColor[2] + " !important",
    // fontWeight: "400",
    fontSize: "0.75rem",
    fontWeight: "500",
    lineHeight: "1.42857",
    letterSpacing: "unset"
  },
  

  
};

export default typographyStyle;
