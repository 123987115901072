import React, { useState, useEffect, lazy, Suspense } from "react";
import moment from 'moment';
import { useLocation } from "react-router-dom";
//@material-ui/core
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';
// components
import AssignmentHeader from "./components/Header";
import AssignmentCard from './components/AssignmentCard'
//Redux
import { useDispatch, useSelector } from 'react-redux'
import * as AssessmentActions from "components/Views/Assessment/store/actions"


const AssignmentFeedback = lazy(() => import('components/Views/AssignmentFeedback'));
const AssignmentUpload = lazy(() => import('components/Views/AssignmentUpload'));
const SkeletonCard = lazy(() => import('./components/SkeletonCard'));

const Assessment = (props) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const { page__title } = props

    const cdate = moment().utc().add(330, 'm').format("YYYY-MM-DD")

    //state
    const assessments = useSelector(state => state.assessment.assessments)

    const [infoType, setInfoType] = useState(1)//1--Assignment / 2--Test / 0--All
    const [loading, setLoading] = useState(true)

    const [feedbackDialog, setFeedbackDialog] = useState(false)
    const [uploadDialog, setuploadDialog] = useState(false)
    const [currentAssignment, setCurrentAssignment] = useState({})

    const [alert, setAlert] = useState({ open: false, severity: "success", message: "" })

    const [page, setPage] = React.useState(1);


    useEffect(() => {
        page__title('Assignments')
    }, [])


    useEffect(() => {
        fetchAssessments()
    }, [infoType, page])


    const fetchAssessments = async () => {
        setLoading(true)
        await dispatch(AssessmentActions.fetchAssessments(infoType, page)).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error);
        });
    }

    // handle click feedback 
    const handleFeedback = (assignment) => {
        setCurrentAssignment(assignment)
        setFeedbackDialog(true)
    }

    // handle click upload 
    const handleUpload = (assignment) => {
        setCurrentAssignment(assignment)
        setuploadDialog(true)
    }

    // close Dialogs
    const closeDialogs = (isSuccess) => {
        if (isSuccess === false) {
            setAlert({ open: true, severity: 'error', message: "something went wrong!" })
        } else {
            setCurrentAssignment({})
            setuploadDialog(false)
            setFeedbackDialog(false)
            if (isSuccess === true) {
                fetchAssessments()
                setAlert({ open: true, severity: 'success', message: "assignment submit successfully" })
            }
        }
    }

    // handle Alert Close
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ open: false, severity: "success", message: "" })
    }

    const handleChange = (event, value) => {
        setPage(value);
    };
    if (loading) {
        return (
            <Suspense fallback={<div > Loading...</div>}>
                <SkeletonCard />
            </Suspense>
        )
    }

    return (
        <div className="page-content__body">
            <Dialog open={feedbackDialog || uploadDialog} maxWidth="sm" fullWidth={true} >
                {feedbackDialog && <Suspense fallback={<div > Loading...</div>}>
                    <AssignmentFeedback onClose={closeDialogs} data={currentAssignment} />
                </Suspense>}
                {uploadDialog && <Suspense fallback={<div > Loading...</div>}>
                    <AssignmentUpload onClose={closeDialogs} data={currentAssignment} />
                </Suspense>}
            </Dialog>

            <section className="component-assignments component  component-assessment">
                {/* <div className="filters">
                    <div className="label__group ms-md-3">
                        <span className="label border--green">{assessments.activeCount} active</span>
                        <span className="label border--red">{assessments.overDueCount} overdue</span>
                        <span className="label border--red">{assessments.finishedCount} finished</span>
                    </div>
                </div> */}
                <div className="card mt-3" style={{ minHeight: '60vh',backgroundColor:'transparent',padding:'0px 15px' }}>
                    <div className="card__body">
                        <div className="link__list">
                            <AssignmentHeader />
                            {assessments.assignmentList.sort((a, b) => { return a.orderById - b.orderById }).map(data => (
                                <React.Fragment>
                                    <AssignmentCard
                                        key={data.assignmentMappingId}
                                        data={data}
                                        cdate={cdate}
                                        onClickUpload={handleUpload}
                                        onClickFeedback={handleFeedback}
                                    />
                                </React.Fragment>

                            ))}
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                        <Pagination
                            count={assessments.maxPageCount}
                            page={page}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </section>

            {/* Snackbar */}
            <Snackbar open={alert.open} autoHideDuration={2000} onClose={handleAlertClose}>
                {alert.open
                    ? <Alert elevation={6} onClose={handleAlertClose} severity={alert.severity} >
                        {alert.message}
                    </Alert>
                    : null
                }

            </Snackbar>
        </div>
    )
}

export default Assessment