import React, { useState, memo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';


// nodejs library to set properties for components
import PropTypes from "prop-types";

// import material-ui/core
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Hidden from "@material-ui/core/Hidden";
import { Input } from '@material-ui/core';

//@material-ui/icons
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

//Custom Components
import Button from 'shared/components/CustomButtons/Button'


//import style
import styles from "assets/jss/components/customcomponents/PDFViewStyle.js";
import './PDFViewStyle.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'assets/css/loader.css';


pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const useStyles = makeStyles(styles);
const PDFViewer = (props) => {
    const {
        fileurl,
        password,
        onPdfLoad,
        ...rest
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [inputNumber, setInputNumber] = useState(1);
    const [pagScale, setPagScale] = useState(1);
    const [loading, setLoading] = useState(true)


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setLoading(false)
        onPdfLoad(true)
    }

    const handleNext = () => {
        setPageNumber((prevActiveStep) => prevActiveStep + 1);
        setInputNumber((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setPageNumber((prevActiveStep) => prevActiveStep - 1);
        setInputNumber((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (event) => {
        setInputNumber(parseInt(event.target.value));
    };

    const handleZoomin = () => {
        if (pagScale < 2)
            setPagScale((prevActiveStep) => prevActiveStep + 0.5);
    };

    const handleZoomout = () => {
        if (pagScale > 1)
            setPagScale((prevActiveStep) => prevActiveStep - 0.5);
    };

    const handleKeyPress = (e) => {
        const value = e.target.value
        const charCode = e.charCode;
        if (charCode == 13) {
            if (value > 0 && value <= numPages) {
                setPageNumber(parseInt(value))
                setInputNumber(parseInt(value));
            } else {
                setPageNumber(1)
                setInputNumber(1);
            }
        }

    }

    if (props.fileurl === '' || props.fileurl === null || props.fileurl === undefined) {
        return <div className="center-container ">
            <div>
                Data not available
            </div>
        </div>
    }

    return (

        <div className="center-container ">

            {!loading && <div className={classes.pdfControl}>
                <Button justIcon color="transparent" size="lg" round onClick={handleBack} disabled={pageNumber === 1}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight fontSize="large" /> : <KeyboardArrowLeft />}
                </Button>
                <p>Page :</p>
                <Input
                    classes={{ input: classes.inputStyle }}
                    value={inputNumber}
                    type="number"
                    size="small"
                    onChange={handleChange}
                    onKeyPress={handleKeyPress} />

                <p> of {numPages}</p>
                <Button justIcon color="transparent" size="lg" round onClick={handleNext} disabled={pageNumber === numPages}>
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft fontSize="large" /> : <KeyboardArrowRight />}
                </Button>
                <Hidden smDown implementation="css">
                    <Button justIcon color="transparent" size="lg" onClick={handleZoomout}><ZoomOutIcon></ZoomOutIcon></Button>
                    <Button justIcon color="transparent" size="lg" onClick={handleZoomin}><ZoomInIcon></ZoomInIcon></Button>
                </Hidden>
            </div>}

            <Document
                file={{ url: props.fileurl }}
                className={"PDFDocument"}
                onPassword={(callback) => callback(password)}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={() => onPdfLoad(false)}
                loading={<div className="loader">Loading...</div>}
            >
                <Page pageNumber={pageNumber} className={"PDFPage"} scale={pagScale} />
                {/* {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                  .map(page => <Page key={page} pageNumber={page} className={"PDFPage"} scale={pagScale} />)} */}
            </Document>
        </div>
    );
}


PDFViewer.defaultProps = {
    onPdfLoad: () => { }
}

PDFViewer.propTypes = {
    fileurl: PropTypes.string,
    password: PropTypes.string,
    onPdfLoad: PropTypes.func
};

function arePropsEqual(prevProps, nextProps) {
    return prevProps.fileurl === nextProps.fileurl;
}


export default memo(PDFViewer, arePropsEqual)