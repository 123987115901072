import React from 'react';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DescriptionIcon from '@material-ui/icons/Description';
import ForumTwoToneIcon from '@material-ui/icons/ForumTwoTone';
import PublishTwoToneIcon from '@material-ui/icons/PublishTwoTone';
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone';
import AssignmentTurnedInTwoToneIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  // root: {
  //   '&:focus': {
  //     backgroundColor: '#6239BD',
  //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
  //       color: theme.palette.common.white,
  //     },
  //   },
  // },
}))(MenuItem);

export default function CardMenu(props) {
  const {
    data,
    cdate,
    dueDate,
    onDownloadQuestionPaper,
    onDownloadSolvedPaper,
    onUploadPaper,
    onDownloadAnswerkey,
    onClickFeedback
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={onDownloadQuestionPaper}>
          <ListItemIcon>
            <DescriptionIcon
              className="btn--blue btn--icon me-1 svg"
              fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Question paper" />
        </StyledMenuItem>

        {data.isSubmitted && data.isScorePublish
          ? <StyledMenuItem onClick={onDownloadSolvedPaper}>
            <ListItemIcon>
              <GetAppTwoToneIcon
                className="btn--blue btn--icon me-1 svg"
                fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Download" />
          </StyledMenuItem>
          : data.isSubmitted && moment(cdate).isAfter(dueDate)
            ? <StyledMenuItem onClick={onDownloadSolvedPaper}>
              <ListItemIcon>
                <GetAppTwoToneIcon
                  className="btn--blue btn--icon me-1 svg"
                  fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Download" />
            </StyledMenuItem>
            : <StyledMenuItem disabled={moment(cdate).isAfter(dueDate) ? true : false} onClick={onUploadPaper}>
              <ListItemIcon>
                <PublishTwoToneIcon
                 className={`btn--icon me-1 svg ${moment(cdate).isAfter(dueDate) ? "btn--disabled" : "btn--blue"}`}
                  fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={data.isSubmitted ? 'reupload' : "Upload"} />
            </StyledMenuItem>
        }

        <StyledMenuItem
          disabled={(data.answerKeyList ? data.answerKeyList.length ? true : false : false) && moment(cdate).isAfter(dueDate) ? false : true}
          onClick={onDownloadAnswerkey}>
          <ListItemIcon>
            <AssignmentTurnedInTwoToneIcon
              className={`btn--icon me-1 svg ${(data.answerKeyList ? data.answerKeyList.length ? true : false : false) && moment(cdate).isAfter(dueDate) ? "btn--blue" : "btn--disabled"}`}
              fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Answer key" />
        </StyledMenuItem>

        <StyledMenuItem disabled={data.isSubmitted && data.isScorePublish ? false : true} onClick={onClickFeedback}>
          <ListItemIcon>
            <ForumTwoToneIcon
              className={`btn--icon me-1 svg ${data.isSubmitted && data.isScorePublish ? "btn--red" : "btn--disabled"}`}
              fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Feedback" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
