import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
//Redux
import { connect } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

// material-ui components
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
// material-ui icons
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// custom style
import styles from "assets/jss/components/views/signinStyle";

// import axios 
import axiosConfig from "../../../shared/interceptor/axiosInstance";

//import Custom Components
import GridItem from "shared/components/Grid/GridItem.js";
import GridContainer from "shared/components/Grid/GridContainer.js"
import Button from "shared/components/CustomButtons/Button"
import CustomizedDialog from "shared/components/Dialog/CustomizedDialog";
import AlertDialog from "shared/components/Dialog/AlertDialog";

import ForgotPassword from "./ForgotPassword/ForgotPassword";

//validations
import { checkNull } from "shared/utils/validations";

// img
import QuestLogo from "assets/img/slate/LoginLogo.png";
import Zenlogo from "assets/img/slate/Zenlogo.png";

//util
import { hrefUrl, eventTracking, SchoolLogo } from 'shared/utils/commonfunctions'


const CustCheckbox = withStyles({
  root: {
    padding: 0,
    paddingRight: 5,
    marginTop: -2,
    color: '#ED1C24',
    '&$checked': {
      color: '#ED1C24',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class SignIn extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',

      errorUserName: "",
      errorPassword: "",

      open: false,
      openProgress: false,
      errorMessage: '',
      remember: false, //true

      forgotDialog: false,
      forgotDialogTitle: "Forgot Password",

      alertMessage: "",
      alertType: "success",

      showPassword: false
    }
    this.InputPassword = React.createRef();
  }


  async componentWillMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const platform = params.get('platform')
    const userName = params.get('userName');
    const password = params.get('password');
    const isAutologin = params.get('isAutologin')

    sessionStorage.setItem('platform', platform);

    if (typeof window.Android !== 'undefined') {
      if (userName) {
        await this.setState({
          userName: userName,
          password: password,
          remember: false
        })
        if (isAutologin) {
          await this.setState({
            openProgress: true
          })
          await this.logIN()
        }
      }
    }

    if (localStorage.getItem('remember')) {
      this.setState({ openProgress: true })
      this.setState({
        userName: localStorage.getItem('userName'),
        password: localStorage.getItem('password'),
        remember: false
      }, () => this.logIN())
      //this.navigateToHome()
    } else if (sessionStorage.getItem('token')) {
      this.navigateToHome()
    }
  }

  componentDidMount() {
    if (Object.keys(this.props.configs).length === 0)
      this.props.onInitAppConfigs()
  }


  // ------Clear All States---------
  clearStates = () => {
    this.setState({
      userName: '',
      password: '',

      errorUserName: "",
      errorPassword: "",

      open: false,
      openProgress: false,
      errorMessage: '',
      remember: false,
      forgotDialog: false,
      forgotDialogTitle: "Forgot Password",
      alertMessage: "",
      alertType: "success"
    });
  }


  // ------Clear error---------
  clearError = () => {
    this.setState({
      errorUserName: "",
      errorPassword: "",
    });
  }

  // ---- click Login --------
  handleLogin = () => {
    this.clearError() // clear Error
    this.setState({ openProgress: true })
    // Validation
    let validationObject = {};
    checkNull("UserName", this.state.userName, "Mobile Number is required", validationObject);
    checkNull("Password", this.state.password, "Password is required", validationObject);

    if (Object.keys(validationObject).length != 0) {
      this.setState({ openProgress: false })
      this.setState({
        errorPassword: validationObject.errorPassword,
        errorUserName: validationObject.errorUserName,
      });
    } else {
      this.logIN()
    }

  }


  async logIN(forcelogout = '') {
    // Code for IP Save IP
    axiosConfig().get('AppCore/GetIP').then(response => {
      sessionStorage.setItem('ip', response.data);

      let TOKEN = localStorage.getItem('token');
      axiosConfig().post('AuthenticateUser', {
        token: TOKEN ? TOKEN : '',
        forcelogout: forcelogout
      }, {
        auth: {
          username: this.state.userName,
          password: this.state.password
        }
      }).then(async response => {

        //Event Tracking
        const eventData = {
          'MobileNumber': this.state.userName,
          'Result': "Success"
        };
        eventTracking("Login", eventData)


        this.props.setAuthError(false)
        if (this.state.remember) {
          localStorage.setItem('remember', this.state.remember ? true : false);
          localStorage.setItem('userName', this.state.userName);
          localStorage.setItem('password', this.state.password);
          if (typeof window.Android !== 'undefined') {
            window.Android.onRemenberMeClicked(this.state.userName, this.state.password, true);
          }
          if (typeof onRemenberMeClicked !== 'undefined') {
            window.onRemenberMeClicked(this.state.userName, this.state.password, true);
          }
        }

        // flutter
        if (typeof window.flutter_inappwebview !== 'undefined') {
          window.flutter_inappwebview.callHandler('onLogin', this.state.userName);
        }

        localStorage.setItem('token', response.data.authToken);
        // sessionStorage.setItem('token', response.data.authToken);
        await this.props.onInitUserInfo()
        this.setState({ openProgress: false })
        this.navigateToHome()
      }).catch((error) => {
        //Event Tracking
        const eventData = {
          'MobileNumber': this.state.userName,
          'Result': "Fail"
        };
        eventTracking("Login", eventData)

        this.setState({ openProgress: false })
        if (error.response) {
          if (error.response.status === 401) {
            this.props.setAuthError(true)
            if (error.response.data.isMultilogedIn) {
              this.setState({ alertMessage: "There is a Zeptolearn session active on another device. Do you want to deactivate the exisitng session and start a new session on this device?", alertType: 'custom_info' });
            } else {
              let ErrorMessage = "Please confirm your Mobile Number and password."
              if (error.response.data.error)
                ErrorMessage = error.response.data.error
              this.setState({
                open: true,
                errorMessage: ErrorMessage
              })
            }
          } else {
            this.props.setAuthError(true)
            this.setState({
              open: true,
              errorMessage: 'something went wrong !'
            })
          }
        }
      });

    }).catch(error => {
      this.setState({ openProgress: false })
      sessionStorage.setItem('ip', " 127.0.0.1");
    })


  }

  // navigate To Dashboard
  navigateToHome() {
    //return this.props.history.push('/main')

    return this.props.history.push('/intermediate')
  }

  // handle Snackbar Click
  handleSnackbarClick = () => {
    this.setState({ open: true });
  };
  handleSnackbarClose = () => {
    this.setState({ open: false });
  };



  //handle ENTER key press
  handleKeyPress = (e) => {
    const name = e.target.name;
    const charCode = e.charCode;
    if (charCode == 13) {
      if (name === 'password')
        this.logIN()
      else if (name === 'userName')
        this.InputPassword.current.focus()
    }
  }

  handleCheckChange = (event) => {
    this.setState({ remember: event.target.checked });
  };

  /*handle Forgot Password Dialog Open*/
  openForgotDialog = () => {
    this.props.setUserverificationStatus({})
    this.setState({ forgotDialog: true });
    //Event Tracking
    eventTracking("ForgetPassword", {})
  };

  /* handle Forgot Password Dialog Close */
  closeForgotDialog = () => {
    this.setState({ forgotDialog: false });
  };

  /*on Close Alert*/
  onCloseAlert = () => {
    this.setState({ alertMessage: "" })
    if (this.state.alertType === 'custom_info')
      this.logIN("1")

  }

  /* on Reset Password*/
  onResetPassword = (alertType, message) => {
    this.setState({ alertMessage: message, alertType: alertType });
  }

  /*Register*/
  onRegister = () => {
    //Event Tracking
    eventTracking("BookDemo", {})

    this.props.history.push('/signup')
  }

  // Click Show Password
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  render() {
    const { classes } = this.props;
    return (
      <React.Fragment >

        {/* ---AlertDialog--- */}
        <AlertDialog
          maxWidth="xs"
          fullWidth={true}
          open={this.state.alertMessage ? true : false}
          type={this.state.alertType}
          contentText={this.state.alertMessage}
          contenttextstyle={{ color: 'black', marginBottom: 0 }}
          title={null}
          titlestyle={{ paddingBottom: 0 }}
          cancelable={() => { }}
          action={
            this.state.alertType === 'success'
              ? [{ text: 'OK', onPress: () => this.onCloseAlert() }]
              : [{ text: this.state.alertType === 'custom_info' ? 'Yes' : 'OK', onPress: () => this.onCloseAlert() },
              { text: this.state.alertType === 'custom_info' ? 'No' : 'cancel', onPress: () => this.setState({ alertMessage: "" }) }
              ]}
        />

        {/* Dialog */}
        <CustomizedDialog
          classes={{ paper: classes.paperDialog }}
          title={this.state.forgotDialogTitle}
          open={this.state.forgotDialog}
          onClose={this.closeForgotDialog}>
          <div style={{ display: 'flex', justifyContent: "center", flexDirection: 'column' }}>
            <ForgotPassword
              onChangeTitle={(title) => this.setState({ forgotDialogTitle: title })}
              onClose={this.closeForgotDialog}
              onResetPassword={this.onResetPassword}
            />
          </div>
        </CustomizedDialog>

        <div className='signin-container background-signin'>
          <CssBaseline />
          <div className={classes.container}>
            <main className={classes.layout}>
              {this.state.openProgress ? < LinearProgress color="primary" style={{ margin: '0px 20px 0px 20px' }} /> : null}
              <Paper className={classes.paper}>
                {/* <Typography variant="subtitle1">Sign in</Typography> */}
                <div className={classes.form}>
                  {/* <img className={classes.imageStyle} src={Zenlogo} alt="..." /> */}
                  <SchoolLogo className={classes.imageStyle} />

                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="userName">Your username</InputLabel>
                    <Input
                      className={classes.number}
                      disabled={this.state.openProgress ? true : false}
                      value={this.state.userName}
                      onChange={(e) => this.setState({ userName: e.target.value })}
                      onKeyPress={this.handleKeyPress}
                      // onInput={(e) => {
                      //   e.target.value = e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : ""
                      // }}
                    />
                    <FormHelperText className={classes.labelRootError} >{this.state.errorUserName}</FormHelperText>
                  </FormControl>

                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                      name="password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      id="password"
                      inputRef={this.InputPassword}
                      disabled={this.state.openProgress ? true : false}
                      autoComplete="current-password"
                      value={this.state.password}
                      onChange={(e) => this.setState({ password: e.target.value })}
                      onKeyPress={this.handleKeyPress}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText className={classes.labelRootError} >{this.state.errorPassword}</FormHelperText>
                  </FormControl>

                  <Button
                    disabled={this.state.openProgress ? true : false}
                    round
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.handleLogin()}>
                    Login
                  </Button>

                  {/* <Button
                    disabled={this.state.openProgress ? true : false}
                    round
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.onRegister()}>
                    Book a Demo Class
                  </Button> */}

                  
                  <GridContainer style={{ alignItems: 'center', padding: '10px 0px' }}>
                    <GridItem xs={6} sm={6} md={6}>
                      <FormControlLabel
                        // className={classes.checkboxStyle}
                        control={
                          <CustCheckbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={this.state.remember}
                            onChange={this.handleCheckChange} />
                        }
                        classes={{ label: classes.checkboxLabelStyle }}
                        label="Remember Me"
                      />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6} className={classes.forgotPasswordContainer}>
                      <FormControlLabel
                        onClick={this.openForgotDialog}
                        className={classes.forgotPassword}
                        classes={{ label: classes.forgotPasswordLable }}
                        control={<div />
                        }
                        label="Forgot Password?"

                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ padding: 5 }}>
                      <Typography note="true" variant="caption" paragraph display="inline" style={{ letterSpacing: 'normal' }}>
                        {'By clicking Login, you agree to our '}
                        <span onClick={() => hrefUrl(this.props.configs.tandcurl)} className={classes.hyperText}>Terms and Conditions</span>
                        {' and that you have read our '}
                        <span className={classes.hyperText} onClick={() => hrefUrl(this.props.configs.tandcurl)}>Privacy Policy.</span>
                      </Typography>
                    </GridItem>
                  </GridContainer>

                  <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                    open={this.state.open}
                    autoHideDuration={3000}
                    onClose={this.handleSnackbarClose}
                    ContentProps={{ 'aria-errormessage': 'message-id', }}
                    message={<span id="message-id" >{this.state.errorMessage}</span>}
                    action={[
                      <IconButton
                        key="close"
                        aria-label="Close"
                        color="secondary"
                        className={classes.close}
                        onClick={this.handleSnackbarClose}
                      >
                        <CloseIcon />
                      </IconButton>,
                    ]}
                  />
                </div>
              </Paper>
            </main>
          </div>
        </div >
      </React.Fragment >
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    loading: state.appconfigs.loading,
    configs: state.appconfigs.configs,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onInitUserInfo: () => dispatch(questActions.initUserInfo()),
    onInitAppConfigs: () => dispatch(questActions.initAppConfigs()),
    setAuthError: (auth) => dispatch(questActions.setAuthError(auth)),
    setUserverificationStatus: () => dispatch(questActions.setUserverificationStatus({}))
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(SignIn)))

