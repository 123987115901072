import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: "center",
    display: 'flex',
    flexDirection: "column",
    alignItems: "center"
  },
  dialogActions: {
    margin: 10,
    flexDirection: "column"
  },
  avatar: {
    // margin: theme.spacing(),
    backgroundColor: '#00a9ff',
    marginLeft: 'auto',
    marginRight: 'auto',

  },
  hyperText: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.01071em',
    color: "#0000FF",
    cursor: 'pointer'
  },
  dialogActions: {
    margin: 10,
    flexDirection: "column"
  },
  resendText: {
    textAlign: 'center',
    // width: -webkit-fill-available;
    justifyContent: 'center'
  },
}));

export default useStyles;