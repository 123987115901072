import React, { useState, useEffect, lazy, Suspense } from "react";
import clsx from 'clsx';
import moment from 'moment';

import { Switch, Route, useRouteMatch,useLocation, Redirect } from "react-router-dom";

// @material-ui/core
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// core components
import Navbar from 'shared/components/Navbars/Navbar';
import Drawer from 'shared/components/Drawer/Drawer';
import MenuIcon from '@material-ui/icons/Menu';

//import reducer 
import { useDispatch, useSelector } from 'react-redux'
import * as questActions from 'store/actions/index'
import axiosConfig from "shared/interceptor/axiosInstance";

//util
import { eventTracking } from 'shared/utils/commonfunctions';

// Custom Components
import Progress from 'shared/components/Progress/Progress'

// Views
import HomeDefaultComponent from 'components/Views/Home/HomeNew'

// Course
import Course from 'components/Views/Tasks/Tasks'

//Reports
import Reports from 'components/Views/Dashboard/Dashboard'

// Messaging
import Mails from 'components/Views/Messaging/views/Mails'

// Meeting schedules
import Schedules from 'components/Views/Schedules'
// import OnlineClasses from 'components/Views/Schedules/views/OnlineClasses'
// import OnlineAssignment from 'components/Views/Schedules/views/OnlineAssignment'

//Assessment
import Assessment from 'components/Views/Assessment'

//Tests
import Tests from 'components/Views/AssessmentTest'

// Doubt Solving
// import DoubtSolving from 'components/Views/DoubtSolving'

// Recorded Sessions
import RecordedSessions from 'components/Views/RecordedSessions'

// Book Demo
// import BookDemo from 'components/Views/DemoBooking/DemoBooking'

//Reports
import AttendanceReport from 'components/Views/Reports/AttendanceReports';
import AssignmentReports from 'components/Views/Reports/AssignmentReports';
import TestReports from 'components/Views/Reports/TestReports';


const MainContainer = (props) => {
  let match = useRouteMatch();
  let location =useLocation()
  const dispatch = useDispatch()

  const infoLoading = useSelector(state => state.user.loading)
  const notification = useSelector(state => state.notification.notification)
  const userInfo = useSelector(state => state.user.userinfo)

  const [page__title, setPageTitle] = useState("")
  const [open, setOpen] = useState(false);

  const [loading_notify, setLoadingNotify] = useState(true)
  const [logoutLoading, setLogoutLoading] = useState(false);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (location.pathname==='/home/dashboard')
      setOpen(true)
    else
      setOpen(false)
  }, [sm])


  const toggleSidebar = (fromMenu = false) => {
    if (sm || fromMenu)
      setOpen(!open);
  };



  // fetchNotifications
  useEffect(() => {
    setLoadingNotify(true)
    dispatch(questActions.fetchNotifications()).then(() => {
      setLoadingNotify(false)
    }).catch(error => {
      setLoadingNotify(false)
      console.log(error);
    });
  }, [dispatch])



  /*----------------------- Logout --------------------*/
  const Logout = () => {
    setLogoutLoading(true)
    axiosConfig().post('/logout', null)
      .then(response => {
        const platform = sessionStorage.getItem('platform');
        dispatch(questActions.logoutUser())
        localStorage.clear();
        sessionStorage.clear();
        setLogoutLoading(false)

        if (platform !== 'null')
          props.history.push({ pathname: '/', search: `?platform=${platform}` })
        else
          props.history.push('/')

      }).catch((error) => {
        if (error.response) {
          console.log(error.response.data.error)
        }
      });
  }

  return (
    <div >
      {/*----- Spinner---- */}
      {logoutLoading ? <Progress color="secondary" open={logoutLoading} /> : null}

      {/* top appbar */}
      <Navbar
        userInfo={userInfo}
        loading={infoLoading}
        loadingNotify={loading_notify}
        notifications={notification} 
        onLogout={Logout}
      />

      {/*left side drawer*/}
      <Drawer
        userInfo={userInfo}
        open={open}
        match={match}
        onClose={(op)=>setOpen(sm?false:op)}
        onLogout={Logout}
      />

      {/* main page */}
      <div id="pageContent" className={clsx('page-content', { ['active']: open })} >
        <div className={clsx('page-content__right')}>
          <div className="wrapper">
            <div className={clsx('page-content__head', { ['active']: open })}>
              <div className="wrapper">
                <button className="btn btn--icon me-3" onClick={toggleSidebar}>
                  <MenuIcon />
                </button>
                <h2 className="page__title">{page__title}</h2>
                <time className="page__timestamp">{moment().format('Do MMM YYYY, dddd')}</time>
              </div>
            </div>

            <Switch >
              <Route exact path={`/home`}>
                <Redirect to={`${match.path}/dashboard`} />
              </Route>

              <Route path={`${match.path}/dashboard`}>
                <HomeDefaultComponent page__title={setPageTitle} />
              </Route>

              <Route exact path={`${match.path}/schedules`}>
                <Schedules page__title={setPageTitle} />
              </Route>

              {/* <Route path={`${match.path}/schedules/online-classes`}>
                  <OnlineClasses />
                </Route>

                <Route path={`${match.path}/schedules/online-assignment`}>
                  <OnlineAssignment />
                </Route> */}

              <Route path={`${match.path}/messages`}>
                <Mails page__title={setPageTitle} />
              </Route>
              {/* 
              <Route exact path={`${match.path}/doubts`}>
                <DoubtSolving page__title={setPageTitle} />
              </Route> */}

              <Route exact path={`${match.path}/assignments`}>
                <Assessment page__title={setPageTitle} />
              </Route>

              <Route exact path={`${match.path}/tests`}>
                <Tests page__title={setPageTitle} />
              </Route>

              <Route path={`${match.path}/recorded-sessions`}>
                <RecordedSessions page__title={setPageTitle} />
              </Route>

              <Route path={`${match.path}/resources`}>
                <Course page__title={setPageTitle} />
              </Route>


              {/* 
              <Route path={`${match.path}/bookdemo`}>
                <BookDemo page__title={setPageTitle} />
              </Route> */}

              {/* Reports */}
              {/* 
              <Route path={`${match.path}/reports`}>
                <Reports page__title={setPageTitle} />
               </Route>
              <Route path={`${match.path}/reports_attendance`}>
                <AttendanceReport page__title={setPageTitle} />
              </Route>
              <Route path={`${match.path}/reports_assignment`}>
                <AssignmentReports page__title={setPageTitle} />
              </Route>
              <Route path={`${match.path}/reports_test`}>
                <TestReports page__title={setPageTitle} />
              </Route> */}

            </Switch>

          </div>
        </div>
      </div>
    </div >

  )
}




export default MainContainer