import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

import userReducer from './userInfo'
import contentListReducer from './contentList'
import courseListtReducer from './userCourseList'
import testQuestionsReducer from './testQuestions'
import userProgressReducer from './userProgress'
import appConfigsReducer from './appConfigs'
import sharedDataReducer from './sharedData'
import messageReducer from 'components/Views/Messaging/store/reducers/messageReducer'
import onlineClassesReducer from 'components/Views/Schedules/store/reducers/reducer-online-classes'
import scheduleReducer from 'components/Views/Schedules/store/reducers/reducer-schedule'
import assessmentReducer from 'components/Views/Assessment/store/reducers/reducer-assessment'
import meetingsReducer from './meetings'
import assignmentsReducer from './assignments'
import unreadCountReducer from './unreadCount'
import notificationReducer from './notification'
import recordedSessionsReducer  from './recordedSessions'
import resetPasswordReducer from "./resetPassword";
import userRegistration from "./userRegistration";
import registerDemoSchedule from "./registerDemoSchedule";

import doubtReducer from 'components/Views/DoubtSolving/store/reducers'

// persist Config
const persistConfig = {
    key: 'root',
    storage: sessionStorage,
};

const appReducer = combineReducers({
    user: userReducer,
    newuser: userRegistration,
    content: contentListReducer,
    course: courseListtReducer,
    questions: testQuestionsReducer,
    userprogress: userProgressReducer,
    appconfigs: appConfigsReducer,
    shareddata: sharedDataReducer,
    messages:messageReducer,
    meetings:meetingsReducer,
    onlineclasses:onlineClassesReducer,
    schedule:scheduleReducer,
    assignments:assignmentsReducer,
    unreadCount:unreadCountReducer,
    notification:notificationReducer,
    assessment:assessmentReducer,
    recordedSessions:recordedSessionsReducer,
    resetpassword: resetPasswordReducer,
    demoSchedule:registerDemoSchedule,
    doubt:doubtReducer
});


const rootReducer = (state, action) => {
    if (action.type === 'LOG_OUT') {
        Object.keys(state).forEach(key => {
            sessionStorage.removeItem(`persist:${key}`);
        });
        state = undefined;
    }

    return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducer)