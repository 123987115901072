import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  one: {
    // paddingLeft: theme.spacing(6),
    paddingBottom: 0
  },
  two: {
    paddingLeft: theme.spacing(6),
    paddingBottom: 0
  },
  content: {
    fontWeight: '500 !important',
    fontSize: '14px !important',
    fontFamily: 'Roboto, sans-serif',
    lineHeight: '1.2',
    marginBottom: 2,
    color: '#46494B',
    display: 'block'
  }
}));

export default useStyles;