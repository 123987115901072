import React from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment';


const TestCard = (props) => {

    let history = useHistory();

    const {
        data,
        cdate
    } = props;

    const bgColor = ['subject_bg--green', 'subject_bg--orange', 'subject_bg--violet', 'subject_bg--attended']

    const dateTimeFormat = "MM/DD/YYYY hh:mm:ss A";
    let dueDate = moment(data.testAssignEndDate).format(dateTimeFormat)
    let startDate = moment(data.testAssignStartDate).format(dateTimeFormat)


    //OLD
    // let status__label = 'active';
    // let status_color = 'bg--green';
    // if (data.isSubmitted) {
    //     status__label = 'finish'
    //     status_color = 'bg--violet';
    // } else if (moment(cdate).isAfter(dueDate)) {
    //     status__label = 'OVERDUE'
    //     status_color = 'bg--orange';
    // }

    let status__label = '';
    let status_color = '';
    if (data.orderById === 1) {
        status__label = 'active'
        status_color = 'bg--green';
    } else if (data.orderById === 2) {
        status__label = 'OVERDUE'
        status_color = 'bg--orange';
    } else if (data.orderById === 3) {
        status__label = 'finish'
        status_color = 'bg--violet';
    }


    const handleClickStartTest = () => {
        let token = localStorage.getItem('token');
        let MLink = window.TEST_URL+'?tk=' + token + '&osaid=' + data.onlineStudentAssignmentId + '&type=web'
        history.push('/online-test', { testLink: MLink, testName: props.paperName })
    }

    const handleClickViewSolution = () => {
        let token = localStorage.getItem('token');
        let MLink = window.TEST_URL+'/view-solution?tk=' + token + '&osaid=' + data.onlineStudentAssignmentId + '&attemptid=' + data.attemptList[0].onlineStudentAssignmentAttemptId + '&type=web'
        history.push('/online-test', { testLink: MLink, testName: props.paperName })
    }


    return (
        <a className="link__item">
            <div className="inner-wrapper-col">
                <div className="wrapper wrapper-1">
                    <div className="inner-wrapper">
                        <span className="meta title">{data.paperName}</span>
                        <span className="meta name">{data.level1Name}: {data.testTypeName}</span>
                    </div>
                </div>
                <div className="wrapper wrapper-4">
                    <div className={`new-status ${status_color}`}>
                        <span className="meta content__label">Status : </span>
                        <span className={`new-status__label`}><em>{status__label}</em>
                        </span></div>
                </div>

                <div className="wrapper wrapper-5">
                    <div className="inner-wrapper">
                        <span className="meta date date__start">
                            <span className="content__label">Start Date : </span>
                            {moment(data.testAssignStartDate).format('DD/MM/YYYY hh:mm a')}
                        </span>
                    </div>
                </div>

            </div>


            <div className="inner-wrapper-col">


                <div className="wrapper wrapper-4">
                    <div className="inner-wrapper">
                        <span className="meta date date__end">
                            <span className="content__label">End Date : </span>
                            {moment(data.testAssignEndDate).format('DD/MM/YYYY hh:mm a')}
                        </span>
                    </div>
                </div>

                <div className="wrapper wrapper-5">
                    <div className="mcqs">
                        <div className="title">
                            <span className="content__label">Total Questions : </span>
                            <span>{data.totalQuestions}</span>
                        </div>
                    </div>
                </div>

                <div className="wrapper wrapper-4">
                    <div className="inner-wrapper">
                        <span className="meta date date__end">
                            <span className="content__label">Duration : </span>
                            {data.testDuration} min
                        </span>
                    </div>

                </div>

                <div className="wrapper wrapper-5">
                    <div className="inner-wrapper">
                        <span className="meta date date__start">
                            <span className="content__label">Marks : </span>
                            {data.marksObtained}/{data.totalMarks}
                        </span>
                    </div>

                </div>

                <div className="wrapper wrapper-6">
                    {data.isViewSolution && data.attemptList.length
                        ? <button type="button" className="btn btn--blue" onClick={handleClickViewSolution} >View Solution</button>
                        : moment(cdate).isBefore(startDate)
                            ? <button type="button" className="btn btn--blue disabled" >upcoming</button>
                            : moment(cdate).isBefore(dueDate)
                                ? <button type="button" className="btn btn--blue " onClick={handleClickStartTest}>start test</button>
                                : null
                    }
                </div>
            </div>
        </a>
    )
}

export default TestCard