
import {
    dangerColor,
    boxShadow
} from "assets/jss/material-dashboard-react.js";

import { makeStyles } from "@material-ui/core/styles";
//.....styles..........

const useStyles = makeStyles((theme) => ({
    cardStyle: {
        //   marginBottom: "30px",
        //   marginTop: "30px",
        borderRadius: 0,
        borderBottomStyle: 'inset',
        //   ...boxShadow,
        position: 'relative'
    },
    listGridContainer: {
        display: 'flex',
        justifyContent: "center"
    },
    senderContainer: {
        display: 'flex',
        alignItems: 'center'
    },

    senderName: {
        lineHeight: 1.2,
        width: '85%',
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '1',
        },
    },
    subjectContainer: {
        paddingLeft: theme.spacing(3),
        marginLeft: 5
    },
    subject: {
        lineHeight: 1.2,
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '1',
        },
    },
    messageBodyContainer: {
        paddingTop:15,
        paddingLeft: theme.spacing(3),
        marginLeft: 5,
        display: 'flex',
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    messageText: {
        width: '95%',
        overflow: 'hidden',
        lineHeight: 1.3,

        fontSize: '0.875rem',
        letterSpacing: ' 0.00714em',

    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: 14,
        marginRight: 5
    },

    cardContent: {
        padding: '8px 25px',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 16px'
        },
    },
    chip: {
        margin: theme.spacing(0.2),
        borderRadius: 2,
        maxWidth: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '-webkit-fill-available',
        },
    },

    buttonStar: {
        padding: 0,
        position: 'absolute',
        right: 25,
        bottom: 8
    },
    card: {
        margin: theme.spacing(2),
      },
      media: {
        height: 190,
      },

      attachmentList:{
        marginLeft: 25,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        },
      }

}));

export default useStyles;