import {
  whiteColor,
  grayColor,
  hexToRgb,
  primaryColor,
} from "assets/jss/material-dashboard-react.js";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  questDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    fontWeight: 'bold'
  },
  infoProgressText:{
    color: primaryColor[4],
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  pageCardBody: {
    minHeight: '100vh',
    margin: '0 15px'
  },
  gridContainerHeader: {
    margin: 20,
  
    // backgroundColor: '#fff',
  },
  gridItemHeader: {
    margin: "auto",
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
      paddingLeft: 0,
      margin: 0,
      border: '0px solid rgba(0, 0, 0, 0.12)',
    },
  },
  gridItemButtonHeader: {
    padding: 0
  },
  buttonStyleHeader: {
    margin: 0,
    width: '100%',
    borderRadius: 0
  },
  gridItemBuyButtonHeader: {
    margin: 'auto',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
    }
  },


  gridContainerStyle:{
    margin: 20,
    height: '75vh',
    overflowY: 'overlay',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '60vh',
    },
  },
  gridItemStyle: {
    padding: 0
  },

  gridItemFooter: {
    padding: '0 !important',
    color: primaryColor[4]
  },



  paperMissions: {
    borderRadius: 20,
    backgroundColor: '#f9f8f9e1',
    // opacity: '90%'
  },
  gridItemMissions: {
    padding: 15
  },
  cardMissions: {
    marginLeft: "auto",
    marginRight: "auto",
    // maxWidth: 248,
    marginBottom: "0px",
    borderRadius: 10,
    boxShadow: '0 5px 4px 0 rgba(0, 0, 0, 0.14)',
  },


  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardAvatar: {
    maxWidth: "80px",
    maxHeight: "80px",
    right: "10%",
    position: 'absolute',
    margin: "-40px auto 0",
    borderRadius: "50%",
    overflow: "hidden",
    padding: "0",
    // boxShadow:
    //   "0 16px 38px -12px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.56), 0 4px 25px 0px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.12), 0 8px 10px -5px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.2)",
    // "&$cardAvatarPlain": {
    //   marginTop: "0"
    // }
  },
  imageStyle: {
    width: 'inherit',
    height: 'inherit'
  },

  cardMedia:{
    objectFit: 'fill'
  }



}));

export default useStyles;
