import {
    dangerColor,
    boxShadow
} from "assets/jss/material-dashboard-react.js";

import { makeStyles } from "@material-ui/core/styles";
//.....styles..........

const useStyles = makeStyles((theme) => ({
    cardStyle: {
        //   marginBottom: "30px",
        //   marginTop: "30px",
        borderRadius: 0,
        borderBottomStyle: 'inset',
        //   ...boxShadow,
        position: 'relative'
    },
    avctiveCard: {
        borderLeftStyle: 'solid',
        borderLeftColor: '#ED1C24'
    },

    unreadCard:{
        background: '#d9f2ff 0% 0% no-repeat padding-box',
    },


    listGridContainer: {
        display: 'flex',
        justifyContent: "center"
    },
    senderContainer: {
        display: 'flex',
        alignItems: 'center'
    },

    senderName: {
        width: '85%',
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '1',
        },
    },
    subjectContainer: {
        paddingLeft: theme.spacing(3),
        marginLeft: 5
    },
    subject: {
        lineHeight: 1.2,
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '1',
        },
    },
    messageBodyContainer: {
        paddingLeft: theme.spacing(3),
        marginLeft: 5,
        display: 'flex',
        justifyContent: "space-between"
    },
    messageText: {
        width: '95%',
        overflow: 'hidden',
        lineHeight: 1.3,
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '1',
        },
        fontSize: '0.875rem',
        fontWeight: '500',
        letterSpacing: ' 0.00714em',
        // paddingRight: 50,
        // [theme.breakpoints.down('sm')]: {
        //     paddingRight: 25,
        // },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: 14,
        marginRight: 5
    },

    cardContent: {
        padding: '8px 25px',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 16px'
        },
    },
    chip: {
        margin: theme.spacing(0.2),
        borderRadius: 2,
        maxWidth: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '-webkit-fill-available',
        },
    },

    buttonStar: {
        padding: 0,
        position: 'absolute',
        right: 25,
        bottom: 8
    },


    // New
    searchbarConatiner: {
        background: '#ed1c2424 0% 0% no-repeat padding-box',
        borderRadius: '6px 0px 0px 0px',
        padding: '20px  20px'
    },
    fliterButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 10
    },
    activeButton: {
        border: '1px solid #00A9FF',
    },
    filterButton: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: ' 0px 2px 6px #92929D12',
        opacity: 1,
        textTransform: 'none'
    }



}));

export default useStyles;