import React from 'react';
import clsx from 'clsx';
import axiosConfig from "shared/interceptor/axiosInstance";

//Redux
import { connect } from 'react-redux'
import * as questActions from 'store/actions/index'

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Route, Switch, withRouter } from "react-router-dom";

// import style
import withStyles from '@material-ui/core/styles/withStyles';
import styles from "assets/jss/components/layouts/layoutStyle.js";
import "assets/css/pageLayouts/page.css";

// Comman Component
import Progress from 'shared/components/Progress/Progress'
import AlertDialog from "shared/components/Dialog/AlertDialog";
import SignIn from '../Views/SignIn/signin'

// import Drawer from '../CustomComponents/Drawer/Drawer';

//Routes Components
import Routes from '../../routes'
const SwitchRoutes = (props) => (
    <Switch location={props.location}>
        {Routes.map((prop, key) => {
            if (prop.submenu.length)
                return prop.submenu.map(submenu => <Route
                    path={submenu.layout + submenu.path}
                    component={submenu.component}
                    key={key + submenu.name} />)
            return <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key + prop.name}
            />
        })}
    </Switch>
);


class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDrawer: false,
            mobileOpen: false,
            prevDepth: this.getPathDepth(this.props.location),
            alertLogout: false,
            logoutLoading: false
        }
    }


    componentWillReceiveProps = () => {
        this.setState({ prevDepth: this.getPathDepth(this.props.location) })
    }

    componentWillMount() {
        document.addEventListener('contextmenu', this.handelRightClick);
        window.addEventListener("onBackPressed", (event) => { this.handelBackClick(event) });

        if (!localStorage.getItem('token')) {
            const params = new URLSearchParams(window.location.search)
            this.props.history.push(`/${params ? "?" + params.toString() : ""}`)
        }
    }

    handelRightClick(event) {
        event.preventDefault();
    }

    handelBackClick(event) {
        if (event.target.location.pathname === "/home/dashboard") {
            this.setState({ alertLogout: true });
        } else if (event.target.location.pathname === "/") {

        }
        else {
            event.target.history.back()
        }
    }


    /*----------------------- Logout --------------------*/
    onLogout = () => {
        this.setState({ alertLogout: false, logoutLoading: true })
        axiosConfig().post('/logout', null)
            .then(response => {
                const platform = sessionStorage.getItem('platform');
                this.setState({ logoutLoading: false })
                this.props.logoutUser()
                localStorage.clear();
                sessionStorage.clear();
                if (platform !== 'null')
                    this.props.history.push({ pathname: '/', search: `?platform=${platform}` })
                else
                    this.props.history.push('/')
            }).catch((error) => {
                this.setState({ logoutLoading: false })
                if (error.response) {
                    console.log(error.response.data.error)
                }
            });
    }

    getPathDepth = (location) => {
        let pathArr = location.pathname.split("/")
        pathArr = pathArr.filter(n => n !== "")
        return pathArr.length
    }

    render() {
        const { classes, location } = this.props;
        // const timeout = { enter: 800, exit: 400 }
        const currentKey = location.pathname.split("/")[1] || "/";
        return (
            <div className={classes.wrapper}>
                {this.state.logoutLoading
                    ? <Progress color="secondary" open={this.state.logoutLoading} />
                    : null
                }

                {/* ---AlertDialog--- */}
                <AlertDialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={this.state.alertLogout}
                    type={'custom_info'}
                    contentText={'Do you want to Logout?'}
                    contenttextstyle={{ color: 'black', marginBottom: 0 }}
                    title={null}
                    titlestyle={{ paddingBottom: 0 }}
                    cancelable={() => { }}
                    action={[
                        { text: 'Yes', onPress: () => this.onLogout() },
                        { text: 'No', onPress: () => this.setState({ alertLogout: false }) }
                    ]}
                />

                <div className={classes.mainPanel} >

                    <div className={clsx(classes.content, { [classes.contentShift]: this.state.mobileOpen, })}>

                        <TransitionGroup component='div' className={classes.container}>
                            <CSSTransition
                                key={currentKey}
                                addEndListener={(node, done) => { node.addEventListener("transitionend", done, false); }}
                                classNames="fade"
                                timeout={400}
                            >
                                <div className={this.getPathDepth(location) - this.state.prevDepth >= 0 ? "left" : "right"}>
                                    {/* Routes */}
                                    <Route exact path="/" component={() => <SignIn />} />

                                    <SwitchRoutes location={location} />
                                </div>
                            </CSSTransition>
                        </TransitionGroup>

                    </div>
                </div>
            </div>

        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logoutUser: () => dispatch(questActions.logoutUser()),
    }
}


export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(Layout)));