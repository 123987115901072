import React, { useState, useEffect } from 'react';
//axios
import axiosConfig from "shared/interceptor/axiosInstance";

// material component
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
// @material-ui/icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


//Custom Components
import { TextInput } from "shared/components/Inputs/Inputs"
import Button from "shared/components/CustomButtons/Button.js";
import AlertDialog from "shared/components/Dialog/AlertDialog";
//validations
import { checkNull, checkPassword } from "shared/utils/validations";

// styles
import useStyles from "assets/jss/components/views/changePasswordStyle";

//util
import { eventTracking } from 'shared/utils/commonfunctions'


const ResetPassword = (props) => {

    const { username, onChangeTitle, handleLoading, loading, handleNext, onResetPassword } = props
    const classes = useStyles();

    const [state, setState] = useState({
        newPassword: "",
        confirmPassword: "",
        errorNewPassword: "",
        errorConfirmPassword: "",
    })

    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("success");

    useEffect(() => {
        onChangeTitle("Reset Password")
    }, [])


    // ------Clear All States---------
    const clearStates = () => {
        setState({
            newPassword: "",
            confirmPassword: "",
            errorNewPassword: "",
            errorConfirmPassword: "",
            newShowPassword: false,
            confirmShowPassword: false,
        });
    }

    // ------Clear error---------
    const clearError = () => {
        setState({
            ...state,
            errorNewPassword: "",
            errorConfirmPassword: ""
        });
    }

    // ---- click chnage --------
    const handlePasswordChange = () => {
        clearError() // clear Error
        handleLoading(true)
        // Validation
        let validationObject = {};
        checkNull("NewPassword", state.newPassword, "New Password is required", validationObject);
        checkNull("ConfirmPassword", state.confirmPassword, "Confirm Password is required", validationObject);
        checkPassword("ConfirmPassword", state.newPassword, state.confirmPassword, "Password not match.", validationObject);

        if (Object.keys(validationObject).length != 0) {
            handleLoading(false)
            setState({
                ...state,
                errorNewPassword: validationObject.errorNewPassword,
                errorConfirmPassword: validationObject.errorConfirmPassword,
            });
        } else {
            postChangePassword(state.newPassword)
        }
    }

    // axios call for post password change
    const postChangePassword = (newPassword) => {
        axiosConfig().get('ResetPassword/ChangePassword', {
            params: {
                Username: username,
                newPassword: newPassword
            }
        }).then(response => {
            clearStates()
            if (response.data.success) {

                //Event Tracking
                const eventData = { 'MobileNumber': username, 'Result': 'Success' };
                eventTracking('ResetPassword', eventData)

                handleNext()
                onResetPassword("success", response.data.success)
            }
        }).catch((error) => {
            
            //Event Tracking
            const eventData = { 'MobileNumber': username, 'Result': "Fail" };
            eventTracking("ResetPassword", eventData)

            handleLoading(false)
            if (error.response.data) {
                setAlertType("danger")
                setAlertMessage(error.response.data.error)
            }
        });
    }



    const handleClickShowPassword = (showPassword) => () => {
        setState({ ...state, [showPassword]: !state[showPassword] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const onCloseAlert = () => {
        switch (alertType) {
            case "danger":
                setAlertMessage("")
                break;
            case "success":
                setAlertMessage("")
                props.handleNext()
                break;
            default:
                setAlertMessage("")
        }
    }


    return (
        <React.Fragment>
            {/* ---AlertDialog--- */}
            <AlertDialog
                maxWidth="xs"
                fullWidth={true}
                open={alertMessage ? true : false}
                type={alertType}
                contentText={alertMessage}
                contenttextstyle={{ color: 'black', marginBottom: 0 }}
                title={null}
                titlestyle={{ paddingBottom: 0 }}
                cancelable={() => { }}
                action={[{ text: 'OK', onPress: () => onCloseAlert() }
                ]}
            />
            <DialogContent>
                <TextInput
                    label="Set New Password"
                    disabled={loading ? true : false}
                    type={state.newShowPassword ? 'text' : 'password'}
                    value={state.newPassword}
                    errorText={state.errorNewPassword}
                    onChange={(e) => setState({ ...state, newPassword: e.target.value })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword('newShowPassword')}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {state.newShowPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextInput
                    label="Confirm Password"
                    disabled={loading ? true : false}
                    type={state.confirmShowPassword ? 'text' : 'password'}
                    value={state.confirmPassword}
                    errorText={state.errorConfirmPassword}
                    onChange={(e) => setState({ ...state, confirmPassword: e.target.value })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword('confirmShowPassword')}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {state.confirmShowPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </DialogContent>


            <DialogActions style={{ margin: 10 }}>
                <Button
                    disabled={loading ? true : false}
                    round
                    fullWidth
                    color="primary"
                    onClick={handlePasswordChange}>
                    Change
                </Button>

            </DialogActions>



        </React.Fragment>
    )
}



export default ResetPassword;


