import React from "react";
import moment from 'moment';
import { useHistory } from "react-router-dom";

//SkeletonCard
import SkeletonCard from './SkeletonCard'


const AssignmentCard = ({ data, cdate, onClick }) => {

  let dueDate = moment(data.dueDate).format("YYYY-MM-DD")
  let status__label = 'active';
  let status_color = 'bg--green';

  if (data.isSubmitted) {
    status__label = 'finish'
    status_color = 'bg--violet';
  } else if (moment(cdate).isAfter(dueDate)) {
    status__label = 'OVERDUE'
    status_color = 'bg--orange';
  }

  return (
    <div className="card__item card__item-group" key={data.classAssignmentId} onClick={onClick}>
       <div className="card__header">
       <span className="date">{moment(data.dueDate).format('MMM DD YYYY')}</span>
       <div className={`status ${status_color}`}>
          <span className="status__label"><em>{status__label}</em></span>
        </div>
        </div>

      <div className="card__item">
        <div className="wrapper">
          <span className="meta title">{data.chapterName}</span>
          <span className="meta name">{data.subjectName}: {data.topicName}</span>
          <span className="meta text">{data.assignmentHeading}</span>
        </div>
      </div>
    </div>
  )
}


const Assignment = (props) => {
  let history = useHistory();

  const {assignments,loading} = props

  const handleClick = () => {
    history.push("/home/assignments");
  }

  if (loading) {
    return (
      <SkeletonCard />
    )
  }

  return (
    <div className="component-assignments component-dashboard-assignments">
      <div className="card shadow bg-white">
        <div className="card__head">
          <h6 className="card__title">assignments</h6>
          {/* <div className="label__group ms-3">
            <span className="label border--green">{metadeta.assignmentDueCount} active</span>
            <span className="label border--red">{metadeta.assignmentOverDueCount} overdue</span>
          </div> */}
        </div>
        <div className="card__body">
          {assignments.length === 0 ?
            <div className="link__item">
              <div className="wrapper">
                <span className="meta name">
                  Assignments not available
                </span>
              </div>
            </div>
            : <div className="card__body">
              <div className="link__list">
                {assignments.assignmentList && assignments.assignmentList.map((data, idex) => (
                  <AssignmentCard
                    key={data.assignmentMappingId}
                    data={data} onClick={handleClick}
                    cdate={props.cdate} />
                ))}
              </div>
            </div>
          }
        </div>
      </div>
    </div>

  );
}

export default Assignment;
