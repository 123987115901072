import React, { useEffect, useState, Suspense, lazy } from "react";

//Custom Components
import CustomizedTabs from "shared/components/CustomTabs";

const TrendReport = lazy(() => import('./Trend'));
const SummaryReport = lazy(() => import('./Summary'));
const ChapterWiseReport = lazy(() => import('./Chapter-wise'));
const ParameterScoreReport = lazy(() => import('./ParameterScore'));



const TestReports = (props) => {
    const { page__title } = props

    const [tabId, setTabId] = useState(0)
    const TABS = [
        { tabName: "Test Summary" },
        { tabName: "Chapter-wise Test Summary" },
        // { tabName: "Test Parameter Score" },
        { tabName: "Test Marks Trend" }
    ]

    useEffect(() => {
        page__title('reports')
    }, [])

    return (
        <div className="page-content__body" style={{ padding: 0, paddingTop: 48 }}>
            <section >
                <CustomizedTabs
                    tabs={TABS}
                    onChangeTab={setTabId}
                />
                <div style={{ padding: 50 }} >
                    <Suspense fallback={<div>Loading...</div>}>
                        {tabId === 0 && <SummaryReport />}
                        {tabId === 1 && <ChapterWiseReport />}
                        {/* {tabId === 2 && <ParameterScoreReport />} */}
                        {tabId === 2 && <TrendReport />}
                    </Suspense>
                </div>
            </section>
        </div>
    )
}

export default TestReports;