import * as actionTypes from '../actionTypes';
import axiosConfig from "shared/interceptor/axiosInstance";

export const setSchedule = (SCHEDULE_LIST) => {
    return {
        type: actionTypes.SET_SCHEDULES,
        schedule: SCHEDULE_LIST
    }
}

export const fetchSchedule = (subjectId, infoType, startDate, endDate) => {
    return async (dispatch, getState) => {
        const infoTypes = ["schedule", "assignment"]
        const selectedInfoTypes = (infoType === 'all' || infoType === 'absent') ? infoTypes.toString() : infoType
        await axiosConfig().post('/AppCore/V2/GetAssignmentSessionTestList', {
            'typeId': "0",
            "dayDuration": "0",
            "subjectId": subjectId, //Conditional if subjectId exist
            "infoType": selectedInfoTypes,
            "startDate": startDate, //pass start date of Calender
            "endDate": endDate, //pass end date of Calender 

        }).then(response => {
            if (infoType === 'absent') {
                const scheduleList = response.data
                scheduleList.schedulesLists = scheduleList.schedulesLists.filter(data => data.isPresent === false)
                dispatch(setSchedule(scheduleList))
            } else {
                dispatch(setSchedule(response.data))
            }
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}