import React, { useEffect, useState } from 'react';

import GridItem from "shared/components/Grid/GridItem.js";
import GridContainer from "shared/components/Grid/GridContainer.js"

import BookingCard from './component/BookingCard'

const DemoBooking = (props) => {
  const { page__title } = props
  useEffect(() => {
    if (page__title)
      page__title('Book Demo')
  }, [])


  return (
    <React.Fragment>
      {page__title
        ? <div className="page-content__body">

          <GridContainer style={{justifyContent:'center'}}>
            <GridItem xs={12} sm={12} md={6}>
              <section className="component bg-white shadow" style={{ width: '100%', padding: '10px 30px' }}>
                <BookingCard renderat={'home'} />
              </section>
            </GridItem>
          </GridContainer>

        </div>
        : <div style={{ width: '100%', padding: '0px 30px' }}>
          <BookingCard />
        </div>
      }
    </React.Fragment>
  )
}

export default DemoBooking;