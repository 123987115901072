import * as actionTypes from 'store/definitions/actionTypes'

const initialState = {
    BoardList: [],
    CourseList: [],
    SubjectList: [],
    ScheduleList: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BOARD_LIST:
            return {
                ...state,
                BoardList: action.payload,
            }
        case actionTypes.SET_COURSE_LIST:
            return {
                ...state,
                CourseList: action.payload,
            }
        case actionTypes.SET_SUBJECT_LIST:
            return {
                ...state,
                SubjectList: action.payload,
            }
        case actionTypes.SET_SCHEDULE_LIST:
            return {
                ...state,
                ScheduleList: action.payload,
            }

        default:
            return state;
    }

}


export default reducer