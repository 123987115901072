import {
  drawerWidth,
  transition,
  container
} from "assets/jss/material-dashboard-react.js";

const appStyle = theme => ({
  root: {
    display: 'flex',
    // height: '100vh',
  },





  
  wrapper: {
    position: "relative",
    top: "0",
    //height: "100vh",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      // width: `calc(100% - ${drawerWidth}px)`
    },
    // overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    // marginTop: "50px",
    // marginBottom: '100px',
    padding: "0px 0px 0px 0px",
    //minHeight: "calc(100vh - 150px)",
   
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth-10,
  },
  container,
  map: {
    marginTop: "70px"
  }
});

export default appStyle;
