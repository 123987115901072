import * as actionTypes from '../actions/actionTypes'

const initialState = {
    inbox_messages: {
        extraBoolValue: false,
        isSuccess: false,
        maxPageCount: 0,
        message: "",
        messageList: [],
        totalRecords: 0,
    },
    current_inbox_messages: {},
    sent_messages: [],
    announcements: [],
    recipient_list: []

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //------ Messages and Announcements ----------
        case actionTypes.SET_MESSAGE_AND_ANNOUNCEMENTS:
            return {
                ...state,
                inbox_messages: action.inbox,
                sent_messages: action.sent,
                announcements: action.announcements,
            }

        // ------- inbox messages -------------
        case actionTypes.SET_INBOX_MESSAGES:
            return {
                ...state,
                inbox_messages: action.data,
            }

        // -------Current inbox messages -------------
        case actionTypes.SET_CURRENRT_INBOX_MESSAGES:
            return {
                ...state,
                current_inbox_messages: action.data,
            }


        // ------- sent messages -------------
        case actionTypes.SET_SENT_MESSAGES:
            return {
                ...state,
                sent_messages: action.data,
            }

        // ------- recipient list -------------
        case actionTypes.SET_RECIPIENT_LIST:
            return {
                ...state,
                recipient_list: action.data,
            }

        // ------- Announcements -------------
        case actionTypes.SET_ANNOUNCEMENTS:
            return {
                ...state,
                announcements: action.data,
            }


        default:
            return state;
    }

}


export default reducer