import moment from 'moment';
import React from 'react';


/*-----------------------Compare Date --------------------*/
export const Token = () => {
    let token = localStorage.getItem('token');
    if (token === null || token === undefined || token === "") {
        // console.log(token);
        token = sessionStorage.getItem('token');
    }
    return token
}


export const SchoolLogo = (props) => {
    let hostName = window.location.host.split('.')[0].toLocaleLowerCase()
    return (<img
        src={`/${hostName}.png`}
        onError={event => {
            event.currentTarget.src = '/zeptoapp.png'
            event.currentTarget.onerror = null
        }}
        {...props} />)
}

/*-----------------------Compare Date --------------------*/
export const compareDate = (a, b) => {
    const dataMyOne = moment(a).format('YYYYMMDD');
    const dataMyTwo = moment(b).format('YYYYMMDD');


    if (dataMyOne === dataMyTwo) {
        return 0;
    }
    return (dataMyOne < dataMyTwo) ? -1 : 1;
}

/*-----------------------get older  Date --------------------*/
export const getolderDate = (months) => {
    var d = moment().subtract(months, 'months').format('YYYY-MM-DD');
    return d
}

/*-----------------------get upcoming  Date --------------------*/
export const getUpcomingDate = (months) => {
    var d = moment().add(months, 'months').format('YYYY-MM-DD');
    return d
}

// /*-----------------------get Time difference  --------------------*/
// export const getTimeDifference = (a, b) => {
//     const dataMyOne = moment(a).format('mm:ss');
//     const dataMyTwo = moment(b).format('mm:ss');
// }

/**
 * Custom function that calculates the percent of a number.
 * @param percentFor float | int num, The percent that you want to get.
 * @param percentOf float | int num, The number that you want to calculate the percent of.
 * @returns {Number}
 */
export const percentageNumber = (percentFor, percentOf) => {
    let percentage = 0;
    const firstNumber = Number(percentFor);
    const secondNumber = Number(percentOf);
    percentage = secondNumber > 0 ? ((firstNumber / secondNumber) * 100).toFixed(2) : 0
    return Number(percentage)
}

export const roundPercentageNumber = (percentFor, percentOf) => {
    let percentage = percentageNumber(percentFor, percentOf)
    return percentage > 0 ? Math.round(percentage) : ""
}


export const hrefUrl = (URL) => {
    if (typeof window.flutter_inappwebview !== 'undefined') {
        window.flutter_inappwebview.callHandler('openNewUrl', URL, '');
    } else {
        var w = window.open(URL, "_blank")
    }
}


/**
 * Custom function for Event Tracking.
 * @param eventName string , The Event Name for Tracking.
 * @param eventData object, The Data for Tracking.
 */
export const eventTracking = (eventName, eventData) => {
    console.log("Tracking-------->"+eventName,JSON.stringify(eventData));
    if (typeof window.flutter_inappwebview !== 'undefined') {
        const EventData = JSON.stringify(eventData);
        window.flutter_inappwebview.callHandler('onEventReceived', eventName, EventData);
    }
}