import React, { useEffect, useState } from "react";

import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

//redux
import { connect, useDispatch,useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
//axios
import axiosConfig from "shared/interceptor/axiosInstance";
// @material-ui/core
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
// @material-ui/icons
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

// core components
import PageNavigation from 'shared/components/Navbars/PageNavigation'
import VideoPlayer from 'shared/components/VideoPlayer/VideoPlayer'
import FlashAnimation from 'components/Views/ContentViewer/VideoViewer/FlashAnimation'

//Style
import useStyles from 'assets/jss/components/views/videoViewerStyle'


const SkeletonComp = () => (<React.Fragment >
    <Skeleton animation="wave" variant="circle" width={50} height={50} />
    <Skeleton animation="wave" variant="rect" width={50} height={40} />
</React.Fragment>)




const VideoViewer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch()

    const content= useSelector(state =>state.content.current_content)
    const ratings= useSelector(state =>state.content.content_ratings)
    const loading= useSelector(state =>state.content.content_ratings_loading)


    const { wrapperStyle, navigation, ...rest } = props;
    const centerContainer = classNames({
        [classes.centerContainer]: true,
        [classes.centerContainerTask]: wrapperStyle
    });

    const [flashAnimation, setFlashAnimation] = useState(false)
    const [contentUrl, setContentUrl] = useState("")
    const [ScreenOrientation, setScreenOrientation] = useState('portrait')

    /*---------------------ChangeScreenOrientation--------------------*/
    const onChangeScreenOrientation = (orientation) => {
        setScreenOrientation(orientation)
    }

    /*---------------------init Content Rating & get vedio url--------------------*/
    useEffect(() => {
        dispatch(questActions.initContentRating(content.courseContentId))
    }, content.courseContentId)


    const handleContentRating = (rating) => {
        if (rating !== ratings.myRating) {
            dispatch(questActions.postContentRating(content.courseContentId, rating))
            if (rating > 0)
                setFlashAnimation(true)
        }
    }


    return (
        <React.Fragment>
            {/*-----PageNavigation----- */}
            {navigation ?
                ScreenOrientation === "portrait" ? <PageNavigation logoText={content.courseContentDisplayName} />
                    : <CssBaseline />
                : null
            }

            {flashAnimation ? <FlashAnimation open={flashAnimation} close={setFlashAnimation} ></FlashAnimation> : null}

            {/*-----page-container----- */}
            <div className={`${props.wrapperStyle
                ? 'content-container-task'
                : ScreenOrientation === "portrait" ? 'video-container' : 'video-container-landscape'} background-video-player`}>

                <div className={centerContainer}>
                    <VideoPlayer
                        platform={props.platform}
                        fileurl={content.contentFileUrl}
                        wrapperStyle={props.wrapperStyle}
                        fullscreen={props.fullscreen}
                        setFullscreen={props.setFullscreen}
                        onChangeOrientation={onChangeScreenOrientation}
                        orientation={ScreenOrientation}
                    />

                    <div className={classes.footerDivStyle}>
                        <div className={classes.divStyle}>
                            <div className={classes.divStyle} style={{ marginRight: 20 }}>
                                {loading
                                    ? <div className={classes.divStyle} style={{ marginTop: 10 }}> <SkeletonComp /></div>
                                    : <React.Fragment>
                                        <IconButton aria-label="ThumbUpIcon" onClick={() => handleContentRating(5)}>
                                            <ThumbUpIcon fontSize="default" style={{ color: ratings.myRating === 5 ? '#3B5998' : '#C0C0C0' }} />
                                        </IconButton>
                                        <Typography variant="subtitle2" >{ratings.like}</Typography>
                                    </React.Fragment>
                                }

                            </div>
                            <div className={classes.divStyle}>
                                {loading
                                    ? <div className={classes.divStyle} style={{ marginTop: 10 }}>  <SkeletonComp /></div>
                                    : <React.Fragment>
                                        <IconButton aria-label="ThumbDownIcon" onClick={() => handleContentRating(0)}>
                                            <ThumbDownIcon fontSize="default" style={{ color: ratings.myRating === 0 ? '#3B5998' : '#C0C0C0' }} />
                                        </IconButton>
                                        <Typography variant="subtitle2" >{ratings.disLike}</Typography>
                                    </React.Fragment>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

VideoViewer.defaultProps = {
    navigation: true,
    platform: 'mobile',
    wrapperStyle: false,
    fullscreen: false,
    setFullscreen: () => { }
};
VideoViewer.propTypes = {
    platform: PropTypes.oneOf(["web", "mobile"]),
    wrapperStyle: PropTypes.bool,
    navigation: PropTypes.bool,
    fullscreen: PropTypes.bool,
    setFullscreen: PropTypes.func
};


export default VideoViewer;