import React, { isValidElement, useState, useEffect } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions'
//validations
import { checkNull, checkMobileNumber } from 'shared/utils/validations'
// import axios
// import axiosConfig from "util/axios";

// import material component
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';

//import Custom Components
import Button from "shared/components/CustomButtons/Button.js";
import { TextInput } from "shared/components/Inputs/Inputs";
import AlertDialog from "shared/components/Dialog/AlertDialog";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// Views
import VerifyUsernameOTP from './VerifyUsernameOTP';

//util
import { eventTracking } from 'shared/utils/commonfunctions'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      paddingBottom: 10
    }
  },
  paperWidthXs: {
    maxWidth: 300
  }
}));

const VerifyUsername = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const verification_status = useSelector(state => state.resetpassword.userVerificationStatus)
  const theme = useTheme();


  useEffect(() => {
    props.onChangeTitle("Forgot Password")
  }, [])

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [OTPDialog, setOTPDialog] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [isMobileValidate, setMobileValidation] = useState(false)

  const [state, setState] = useState({
    username: "",
    errorusername: "",
  })

  const onSendOTP = async () => {
    dispatch(questActions.setOTPverificationStatus({}))
    let validationObject = {};
    checkNull("username", state.username, "Mobile Number is required", validationObject);

    if (Object.keys(validationObject).length != 0) {
      setState({
        ...state,
        errorusername: "",
        ...validationObject
      });
    } else {
      setState({ ...state, errorusername: "" });
      props.handleLoading(true);
      dispatch(questActions.verifyUser(state.username)).then(() => {
        props.handleLoading(false);
        trackingFunction("VerifyUser", 'Success')
      }).catch(error => {
        trackingFunction("VerifyUser", 'Fail')
        props.handleLoading(false);
        setAlertType("danger")
        setAlertMessage(error.message)
      });
    }
  }


  useEffect(() => {
    if (verification_status.isSuccess) {
      setOTPDialog(true)
    } else {
      setAlertType("info")
      setAlertMessage(verification_status.message)
    }
  }, [verification_status])



  const verifyMobile = (isVerify, message) => {
    if (isVerify) {
      trackingFunction("ValiateOTP", 'Success')

      props.setUsername(state.username)
      setSnackbarMessage(message)
      setTimeout(() => {
        props.handleNext()
      }, 1000)
    }
    else {
      trackingFunction("ValiateOTP", 'Fail')

      setAlertType("danger")
      setAlertMessage(message)
    }
  }

  const onCloseAlert = () => {
    switch (alertType) {
      case "danger":
        setAlertMessage("")
        break;
      case "success":
        setAlertMessage("")
        props.handleNext()
        break;
      default:
        setAlertMessage("")
    }
  }

  //Event Tracking
  const trackingFunction = (eventName, status) => {
    const eventData = {
      'MobileNumber': state.username,
      'Result': status,
    };
    eventTracking(eventName, eventData)
  }


  // handle Resend OTP
  const handleResendOTP = () => {
    //Event Tracking
    eventTracking("ResendOTP", { 'MobileNumber': state.username })
    onSendOTP()
  }


  return (
    <React.Fragment>
      {/*-----Dialog----- */}
      <Dialog
        open={OTPDialog}
        maxWidth={'xs'}
        fullWidth
        fullScreen={fullScreen}
        scroll='body'
        classes={{ paperWidthXs: classes.paperWidthXs }}
      >
        <VerifyUsernameOTP
          title={verification_status.message}
          username={state.username}
          onClose={setOTPDialog}
          onValidate={verifyMobile}
          onResendOTP={handleResendOTP}
        />
      </Dialog>

      {/* ---AlertDialog--- */}
      <AlertDialog
        maxWidth="xs"
        fullWidth={true}
        open={alertMessage ? true : false}
        type={alertType}
        contentText={alertMessage}
        contenttextstyle={{ color: 'black', marginBottom: 0 }}
        title={null}
        titlestyle={{ paddingBottom: 0 }}
        cancelable={() => { }}
        action={[{ text: 'OK', onPress: () => onCloseAlert() }
        ]}
      />

      {/* ----Snackbar---- */}
      <Snackbar open={snackbarMessage ? true : false} autoHideDuration={6000} onClose={() => setSnackbarMessage("")} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarMessage("")} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>


      <DialogContent style={{ overflowY: 'unset' }}>
        <TextInput
          disabled={props.loading}
          autoFocus={true}
          type="number"
          label="Enter Your Mobile Number"
          value={state.username}
          errorText={state.errorusername}
          className={classes.number}
          onChange={(e) => setState({ ...state, username: e.target.value })}
          onInput={(e) => {
            e.target.value = e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : ""
          }}
        />
      </DialogContent>

      <DialogActions style={{ margin: 10 }}>
        {/* <div style={{ marginTop: 20, visibility: state.username.length > 0 ? 'visible' : 'hidden' }}> */}
        <Button
          disabled={props.loading}
          round
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSendOTP}>
          Continue
        </Button>
        {/* </div> */}
      </DialogActions>
    </React.Fragment>
  )
}

export default VerifyUsername;