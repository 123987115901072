import React, { useState, useEffect } from "react";
import moment from 'moment'

//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as messageActions from "components/Views/Messaging/store/actions/index"

//@material-ui/icons
import InboxIcon from '@material-ui/icons/Inbox';

// Views
import Inbox from './Inbox'


const MailContainer = (props) => {

    const { page__title } = props

    const dispatch = useDispatch();

    const [selectedTab, setSelectedTab] = useState({ name: "Inbox", icon: InboxIcon })
    const [loading, setLoading] = useState(false)
    const [page, setPage] = React.useState(1);

    useEffect(() => {
        page__title('Messages')
    }, [])

    useEffect(() => {
        fetchMessages()
    }, [page, selectedTab])

    const fetchMessages = async () => {
        setLoading(true)       
        await dispatch(messageActions.fetchMessages(page)).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error);
        });
    }


    return (
        <div className="page-content__body">
            <section className="component shadow">
                <Inbox
                    loading={loading}
                    setLoading={setLoading}
                    page={page}
                    onPageChange={setPage}
                />
            </section>
        </div>
    )
}

export default MailContainer