import * as actionTypes from '../actionTypes';
import axiosConfig from "shared/interceptor/axiosInstance";

export const setAssessments = (ASSESSERNTS_LIST) => {
    return {
        type: actionTypes.SET_ASSESSERNTS,
        assessments: ASSESSERNTS_LIST
    }
}

export const fetchAssessments = (typeId, pageNumber) => {
    return async (dispatch, getState) => {

        if (typeId === 2) {
            await axiosConfig().post('/OnlineTest/GetTestList', {
                "type": 0, // type==0 -- ALL Test, type==1 -- OnGoing Test, type==2 -- UpComing Test,type==3 -- Missed Test
                "testTypeId": "0",  //--1 = Objective & 2 = Subjective / Pass "0" for all
                "subjectId": 0,
                "pageNumber": pageNumber,
                "pageSize": 10
            }).then(response => {
                let data = {
                    ...response.data,
                    assignmentList: response.data.testList,
                }
                delete data.testList
                dispatch(setAssessments(data))
            }).catch(function (error) {
                if (error.response.data)
                    throw new Error(error.response.data.error)
                throw new Error(error.message);
            });
        } else {
            await axiosConfig().post('/AppCore/V3/GetAssignmentList', {
                "typeId": typeId + "", //1--Assignment / 2--Test / 0--All
                "pageNumber": pageNumber,
                "pageSize": 10,
                "search": "",
                "subjectId": 0
            }).then(response => {
                dispatch(setAssessments(response.data))
            }).catch(function (error) {
                if (error.response.data)
                    throw new Error(error.response.data.error)
                throw new Error(error.message);
            });
        }
    }
}