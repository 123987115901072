import { makeStyles } from "@material-ui/core/styles";

import {
  successColor,
  hyperText
} from "assets/jss/material-dashboard-react.js";


const Style = makeStyles((theme) => ({
  hyperText,
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    height: '90vh',
    [theme.breakpoints.up(500 + theme.spacing(3 * 2))]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center'
    },
  },
  paper: {
    // marginTop: theme.spacing() * 8,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing()}px ${theme.spacing()}px ${theme.spacing()}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing() * 3,
  },
  close: {
    padding: theme.spacing() / 2,
  },
  stepperStyle: {
    width: '100%'
  },
  actionsContainer: {
    paddingTop: 20,
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    position: 'relative'
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },

  getOTPBtnContainer: {
    display: 'flex',
    alignItems: 'end',
    height: '100%',
    position: 'relative',
  },
  button: {
    marginBottom: 2,
    padding: '6px 16px'
  },
  verifyOTPButton: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  buttonProgress: {
    position: 'absolute',
    top: '75%',
    left: '80%',
    marginTop: -12,
    marginLeft: -12,
    [theme.breakpoints.down('sm')]: {
      top: '65%',
      left: '80%',
    }
  },

  buttonProceed: {
    position: 'absolute',
    top: '75%',
    // left: '80%',
    marginTop: -12,
    marginLeft: -12,
    [theme.breakpoints.down('sm')]: {
      top: '75%',
      // left: '80%',
    }
  },

  actionsGridItem: {
    marginTop: 10,
    justifyItems: 'flex-end',
    alignItems: 'flex-end',
    display: 'flex',
    marginBottom: 10,
  },
  autosubheader:{
    fontSize: 12,
    lineHeight: 1
  }
}));

export default Style;