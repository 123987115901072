
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';

// core components
import Intermediate from '../components/Layouts/Intermediate';
import Home from '../components/Views/MainContainer/MainContainer'
//import Home from '../components/Views/Home/HomeNew'

import Tasks from '../components/Views/Tasks/Tasks'
import Signup from '../components/Views/SignUp/Signup'
import ThankYou from 'components/Views/DemoBooking/ThankYou/ThankYou'

import Instruction from 'components/Views/ContentViewer/AssessmentViewer/InstructionAssessment'
import AssessmentViewer from 'components/Views/ContentViewer/AssessmentViewer/Assessment'
import PDFViewer from 'components/Views/ContentViewer/PDFViewer/PDFViewer'
import HTMLViewer from 'components/Views/ContentViewer/HTMLViewer/HTMLViewer'
import HTMLContentViewer from 'components/Views/ContentViewer/HTMLViewer/HTMLContentViewer'
import VideoViewer from 'components/Views/ContentViewer/VideoViewer/VideoViewer'
import ActivityViewer from 'components/Views/ContentViewer/ActivityViewer/ActivityViewer'
import AssignmentViewer from 'components/Views/ContentViewer/AssignmentViewer'
import RecordingViewer from 'components/Views/ContentViewer/RecordingViewer/RecordingViewer'
import TestViewer from 'components/Views/ContentViewer/TestViewer/TestViewer'


const inventoryRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: DashboardIcon,
    component: Home,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },


  // ----------------------//

  {
    path: "/intermediate",
    name: "Intermediate",
    icon: DashboardIcon,
    component: Intermediate,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/task",
    name: "Tasks",
    icon: DashboardIcon,
    component: Tasks,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },

  {
    path: "/assessmentviewer",
    name: "Assessment",
    icon: DashboardIcon,
    component: AssessmentViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },

  {
    path: "/instruction",
    name: "Instruction",
    icon: DashboardIcon,
    component: Instruction,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/pdf",
    name: "PDFViewer",
    icon: DashboardIcon,
    component: PDFViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/viewer",
    name: "HTMLViewer",
    icon: DashboardIcon,
    component: HTMLViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/contentviewer",
    name: "HTMLContentViewer",
    icon: DashboardIcon,
    component: HTMLContentViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/player",
    name: "VideoViewer",
    icon: DashboardIcon,
    component: VideoViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/activity",
    name: "ActivityViewer",
    icon: DashboardIcon,
    component: ActivityViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/assignmentviewer",
    name: "ActivityViewer",
    icon: DashboardIcon,
    component: AssignmentViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/recordingviewer",
    name: "RecordingViewer",
    icon: DashboardIcon,
    component: RecordingViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/online-test",
    name: "TestViewer",
    icon: DashboardIcon,
    component: TestViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/signup",
    name: "Signup",
    icon: DashboardIcon,
    component: Signup,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },

  {
    path: "/thankyou",
    name: "thankyou",
    icon: DashboardIcon,
    component: ThankYou,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },



];

export default inventoryRoutes;
