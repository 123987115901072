import React from 'react';
import { withRouter } from 'react-router-dom';

// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";


//import reducer 
import { useDispatch, useSelector } from 'react-redux'
import * as questActions from 'store/actions/index'
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
// @material-ui/core
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockIcon from '@material-ui/icons/Lock';



// styles
import useStyles from "assets/jss/components/views/contentListItemStyle";

const ContentCard = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentContent = useSelector(state => state.content.current_content)
    const loading = useSelector(state => state.content.loading)

    const nestedClasses = classNames({
        [classes[props.level]]: props.level,
    });

    const onClickContentCard = async () => {
        // if (!props.content.isContentLocked)
        if (Object.keys(currentContent).length !== 0) {
            if (currentContent.accessDuration) {
                await dispatch(questActions.postContentAccess()).then(async () => {
                    dispatch(questActions.setCurrentContent(props.content))
                    if (props.link !== '/task')
                        props.history.push(props.link)
                })

            } else {
                await dispatch(questActions.clearCurrentContent())
                await dispatch(questActions.setCurrentContent(props.content))
                if (props.link !== '/task')
                    props.history.push(props.link)
            }

        } else {
            await dispatch(questActions.clearCurrentContent())
            await dispatch(questActions.setCurrentContent(props.content))
            if (props.link !== '/task')
                props.history.push(props.link)
        }
    }

    return (
        <ListItem
            button
            onClick={onClickContentCard}
            className={nestedClasses}
            selected={currentContent.contentCode === props.content.contentCode}
        >
            {loading ?
                <div>Loading......</div>
                : <React.Fragment>
                    <ListItemIcon >
                        <Icon className={props.icon} fontSize="small" style={{ color: props.content.progress.accessCount > 0 ? green[400] : "" }}></Icon>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography className={classes.content}> {props.content.courseContentDisplayName} </Typography>}
                    />
                    {/* {props.content.isContentLocked
                        ? <LockIcon fontSize="small" style={{ color: 'rgba(0,0,0,0.5)' }} />
                        : null
                    } */}
                </React.Fragment>
            }
        </ListItem>
    )
}

ContentCard.defaultProps = {
    level: 'one',

};
ContentCard.propTypes = {
    level: PropTypes.oneOf(["one", "two"]),
};

export default withRouter(ContentCard);



