import * as actionTypes from '../definitions/actionTypes';
// import axios
import axiosConfig from "shared/interceptor/axiosInstance";


// Request For OTP
export const requestForOTP = (MobileNumber) => {
    return async (dispatch) => {
        await axiosConfig().get('/AppCore/SelfRegistration/GetOTP', {
            params: {
                MobileNo: MobileNumber
            }
        }).then((response) => {
            if (!response.data.isSuccess)
                throw new Error(response.data.message)
        }).catch(function (error) {
            if (error.response)
                if (error.response.data)
                    throw new Error(error.response.data)
                else
                    throw new Error("something went wrong!")
            throw new Error(error.message)

        });
    }
}

// Verify OTP
export const VerifyOTP = (verificationCode, MobileNumber) => {
    return async (dispatch) => {
        await axiosConfig().get('/AppCore/SelfRegistration/VerifyOTP', {
            params: {
                OTP: verificationCode,
                MobileNo: MobileNumber
            }
        }).then((response) => {
            if (!response.data.isSuccess)
                throw new Error(response.data.message)
        }).catch(function (error) {
            console.log(error.response);
            if (error.response.data)
                throw new Error(error.response.data)
            else
                throw new Error("something went wrong!")
        });
    }
}


// Pincode Info
export const setCitySateCountry = (userInfo) => {
    return {
        type: actionTypes.SET_CITY_STATE_COUNTRY_DATA,
        payload: userInfo
    }
}
export const GetPincodeInfo = (pincode) => {
    return async (dispatch) => {
        await axiosConfig().get('/Common/GetPincodeInfo/' + pincode).then(response => {

            if (response.data[0].status === 'Success') {
                if (response.data[0].postOffice)
                    dispatch(setCitySateCountry(response.data[0].postOffice[0]))
                else
                    throw new Error('Enter valid pincode')
            } else {
                throw new Error('Enter valid pincode')
            }
        }).catch(function (error) {
            if (error.response)
                if (error.response.data)
                    throw new Error(error.response.data)
                else
                    throw new Error("something went wrong!")
            throw new Error(error.message)
        });
    }
}


// Register User
export const setInitialUser = () => {
    return {
        type: actionTypes.INIT_REGISTER_USER
    }
}

export const setRegistration = (userInfo) => {
    return {
        type: actionTypes.SET_SELF_REGISTRATION_DATA,
        payload: userInfo
    }
}

export const setRegistrationUserID = (id) => {
    return {
        type: actionTypes.SET_REGISTRATION_USERID,
        payload: id
    }
}

export const registerNewUser = () => {
    return async (dispatch, getState) => {
        const user = getState().newuser.registerUser
        await axiosConfig().post('/AppCore/SelfRegistration/RegisterUser', { ...user }).then(response => {
            console.log(response.data);
            if (response.data.isSuccess)
                dispatch(setRegistrationUserID(response.data.message))
            else
                throw new Error(response.data.message)
        }).catch(function (error) {
            console.log(error.response);
            if (error.response)
                if (error.response.data)
                    throw new Error(error.response.data)
                else
                    throw new Error("something went wrong!")
            throw new Error(error.message)
            // dispatch(fetchSharedDataFailed())
        });
    }
}


export const loginNewUser = () => {
    return async (dispatch, getState) => {
        const user = getState().newuser.registerUser

        // Password for Login
        let tpass = user.contactNo.slice(user.contactNo.length - 4)
        if (user.firstName.length >= 4) {
            tpass = user.firstName.slice(0, 4).toString().toLowerCase() + tpass
        } else {
            tpass = user.firstName.padEnd(4, "0").toString().toLowerCase() + tpass
        }

        await axiosConfig().post('AuthenticateUser', {
            token: '',
            forcelogout: ''
        }, {
            auth: {
                username: user.contactNo,
                password: tpass
            }
        }).then(async response => {
            localStorage.setItem('remember', true);
            localStorage.setItem('userName', user.contactNo);
            localStorage.setItem('password', tpass);
            localStorage.setItem('token', response.data.authToken);
        }).catch((error) => {
            if (error.response)
                if (error.response.data)
                    throw new Error(error.response.data.error)
                else
                    throw new Error("something went wrong!")
            throw new Error(error.message)
        });
    }
}







