import React, { useState, Suspense, lazy } from "react";
import moment from 'moment'
//@material-ui/core
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip'
import { Divider } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
//@material-ui/icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import MessagesIcon from './MessagesIcon'

// custom style
import useStyles from "./styles/MessageDeatilsCardStyle";
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: 5,
        background: '#FFC600 0% 0% no-repeat padding-box',
        boxShadow: '0px 4px 10px #00000040',
        display: 'flex',
        alignItems: 'center'
    },
    closeButton: {
        // position: 'absolute',
        // right: theme.spacing(1),
        // top: theme.spacing(1),
        // color: theme.palette.grey[500],
    },
});

const SkeletonCard = (props) => {
    const classes = useStyles();
    return (<DialogContent className={classes.card}>
        <CardHeader
            avatar={<Skeleton animation="wave" variant="circle" width={40} height={40} />}
            title={<Skeleton animation="wave" height={20} width="80%" style={{ marginBottom: 6 }} />}
            subheader={<Skeleton animation="wave" height={20} width="40%" />}
        />
        <Skeleton animation="wave" variant="rect" className={classes.media} style={{ marginBottom: 6 }} />
        <div>
            <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={20} width="80%" />
        </div>
    </DialogContent>
    )
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <ArrowBackIcon />
                </IconButton>
            ) : null}
            <Typography variant="h6">{children}</Typography>

        </MuiDialogTitle>
    );
});


const MessageDeatilsCard = (props) => {
    const {
        data,
        onChangeStarStatus,
        loading,
        onClose,
        sm
    } = props

    const classes = useStyles();

    if (loading) {
        return (
            <SkeletonCard />
        )
    }

    const fileDowload = (file) => {
        if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('downloadFile', file.fileUrl);
        } else {
            fetch(file.fileUrl)
                .then(res => res.blob()) // Gets the response and returns it as a blob
                .then(blob => {
                    let objectURL = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = objectURL;
                    document.body.appendChild(link);
                    link.setAttribute('download', file.fileName);
                    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                    // Remove link from body
                    document.body.removeChild(link);
                });
        }
    }




    return (
        <React.Fragment>
            {sm && <DialogTitle id="customized-dialog-title" onClose={onClose}>
                {data.firstName}
            </DialogTitle>
            }

            <DialogContent className={classes.cardContent}>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <div>
                        <Typography variant="h6"   >
                            {data.subject}
                        </Typography>
                        {sm && <Typography variant="caption"  >
                            {`${moment(data.sendOn).format('DD-MM-YYYY')}`}
                        </Typography>
                        }
                    </div>
                    {data.typeId !== 6 &&
                        <IconButton aria-label="star" onClick={() => onChangeStarStatus(data)}>
                            {data.isStarred
                                ? <StarIcon fontSize="small" style={{ color: '#ED1C24' }} />
                                : <StarBorderIcon fontSize="small" />
                            }
                        </IconButton>}
                </div>

                <div style={{ display: 'flex', flexDirection: "column", marginBottom: 10 }}>
                    {!sm && <div style={{ display: 'flex', flexDirection: "row", alignItems: 'flex-start', padding: '10px 0px' }}>
                        <Avatar className={classes.small}>
                            <MessagesIcon type={data.typeId}></MessagesIcon>
                        </Avatar>
                        <div>
                            <Typography variant="caption" className={classes.senderName} >
                                {data.firstName}
                            </Typography>
                            <Typography variant="caption"  >
                                {`${moment(data.sendOn).format('DD-MM-YYYY')}`}
                            </Typography>
                        </div>
                    </div>
                    }

                    <Divider />

                    <div className={classes.messageBodyContainer}>
                        <Typography variant="subtitle2" className={classes.messageText} >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data.messageBody
                                }}
                            ></div>
                        </Typography>
                    </div>


                    {data.attachmentList && data.attachmentList.map((file) => {
                        return (
                            <div key={file.index} className={classes.attachmentList}>
                                <Chip
                                    size="small"
                                    clickable
                                    onClick={() => fileDowload(file)}
                                    deleteIcon={<GetAppIcon></GetAppIcon>}
                                    label={file.fileName}
                                    className={classes.chip}
                                    onDelete={() => fileDowload(file)}

                                />
                            </div>
                        );
                    })}
                </div>

            </DialogContent>
        </React.Fragment>

    )
}

export default MessageDeatilsCard;