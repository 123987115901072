import React, { useEffect, useState } from "react";
//Lottie
import Lottie from 'react-lottie';
import animationData from 'assets/lotties/like';
// @material-ui/core
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));



const FlashAnimation = (props) => {
    const classes = useStyles();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        // rendererSettings: {
        //     preserveAspectRatio: "xMidYMid slice"
        // }
    };

    useEffect(() => {
        let myInterval = null;
        if (props.open)
            myInterval = setInterval(() => {
                props.close()
            }, 1000)

        return () => {
            clearInterval(myInterval);
        };
    }, [props.open]);

    return (
        <div>
            <Backdrop open={props.open} className={classes.backdrop}>
                <Lottie options={defaultOptions}
                    height={'100%'}
                    width={'100%'} />
            </Backdrop>
        </div>
    )
}

export default FlashAnimation;