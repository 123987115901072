import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import styles from "assets/jss/components/customcomponents/autocompleteStyle";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
    const classes = useStyles();
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        errorText,
        white,
        inputRootCustomClasses,
        success,
        options,
        value,
        placeholder,
        ...rest
    } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });

    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white,
        [classes.whiteUnderline]: white,
    });

    const labelError = classNames({
        [classes.labelRootError]: true,
        [classes.labelWhiteError]: white,
    })

    var formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControlSelect
        );
    } else {
        formControlClasses = classes.formControlSelect;
    }

    return (
        <FormControl margin="dense" {...formControlProps} className={formControlClasses}>
            <Autocomplete
                classes={{
                    input: inputClasses,
                    inputRoot: classes.autocompleteInput
                }}
                value={value}
                options={options}
                renderInput={(params) => <form autoComplete="off"> <TextField
                    {...params}
                    label={labelText}
                    placeholder={placeholder}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelRoot + " " + labelClasses,
                        },
                        shrink: true
                    }}
                    helperText={errorText}
                    FormHelperTextProps={{ className: labelError }}


                /></form>}
                {...rest}
            />
        </FormControl>
    );
}

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    success: PropTypes.bool,
    white: PropTypes.bool,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string
};
