import React, { useEffect, useState } from "react";
import moment from 'moment'

//import reducer 
import { useDispatch, useSelector } from 'react-redux'
import * as actions from "components/Views/Schedules/store/actions/index"

import SkeletonCard from './SkeletonCard'

// styles
import useStyles from "assets/jss/components/views/homeStyle";





const PreviousSessionCards = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const pastOnlineClasses = useSelector(state => state.onlineclasses.pastOnlineClasses)
  
  const [loading, setLoading] = useState(false)

  /*----------------------------------------*/
  useEffect(() => {
    fetchPreviousSession()
  }, [])


  const fetchPreviousSession = async () => {
    setLoading(true)
    dispatch(actions.fetchPastOnlineClasses()).then(() => {
      setLoading(false)
    }).catch(error => {
      setLoading(false)
      console.log(error);
    });
  }

  if (loading) {
    return (
      <SkeletonCard />
    )
  }

  return (
    <div className="component-prevsession">
      <div className="card shadow bg-white">
        <div className="card__head">
          <h6 className="card__title">previous sessions</h6>
        </div>

        {pastOnlineClasses.length === 0
          ? <span className="meta">
            There are no previous session available 
          </span>
          :
          <div className="card__body">
            <div className="link__list">
              {pastOnlineClasses.slice(0, 3).map((data, idex) => (
                <a className="link__item" key={data.scheduleId + "PRIV"}>
                  <span className="meta title"> {data.scheduleName}</span>
                  <span className="meta name">{data.courseDisplayName}</span>
                  <span className="meta date"> {` ${moment(data.startTime).format('DD-MM-YYYY')}`}</span>
                </a>
              ))}

            </div>
            {/* <button className="btn btn--blue mt-3 text-capitalize">archive</button> */}
          </div>
        }
      </div>
    </div>
  );
}

export default PreviousSessionCards;
