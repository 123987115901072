import React, { useEffect } from 'react';
// nodejs library to set properties for components
import PropTypes from "prop-types";
//@material-ui 
import Dialog from '@material-ui/core/Dialog';
import { Alert, AlertTitle } from '@material-ui/lab';
// Custom Components 
import Button from "shared/components/CustomButtons/Button.js";
//Styles
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
    root: {
        //flexDirection: 'column'
    },
    standardInfo:{
        backgroundColor:' #cae6f6 !important'
    }
});

const AlertDialog = (props) => {

    const { className, action, children, open, title, type, contentText, cancelable, ...rest } = props;
    const [animation, setAnimationData] = React.useState("");

    const classes = useStyles();

    let alertType = type
    if (type === 'danger')
        alertType = 'error'
    if (type === 'custom_info')
        alertType = 'info'
    return (
        <Dialog
            open={open}
            onClose={cancelable}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            {...rest}
        >
            <Alert
                className={type === 'custom_info' && classes.root}
                classes={{standardInfo:classes.standardInfo}}
                severity={alertType}
                // icon={type === 'custom_info' ? null : true}
                action={
                    action.map((currentAction, index) =>
                        <Button size='sm' onClick={currentAction.onPress} color={index == 0 ? 'primary' : 'secondary'} key={"DIALOG121" + index}>
                            {currentAction.text}
                        </Button>
                    )
                }
            >

                <AlertTitle style={{textTransform: 'capitalize'}}>{title ? title : alertType}</AlertTitle>
                {contentText}
            </Alert >
        </Dialog>
    );
}

AlertDialog.defaultProps = {
    cancelable: false
}
AlertDialog.propTypes = {
    type: PropTypes.oneOf([
        "info",
        "success",
        "warning",
        "danger",
        "custom_info"
    ]),
    open: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.node,
    contentText: PropTypes.node,
    action: PropTypes.any,
    cancelable: PropTypes.func
};

export default AlertDialog;