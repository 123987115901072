import React, { useEffect } from 'react';
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const useStyles = makeStyles((theme) => ({
    root: {
        textTransform: 'none',
        fontWeight: 'bold',
        color: '#ffff'
    },
    tabsroot: {
        backgroundColor: '#ED1C24',
        position: 'fixed',
        zIndex: 2,
        width: '100%'
    }

}));

const CustomizedTabs = (props) => {
    const classes = useStyles();

    const { tabs, onChangeTab } = props;

    const [value, setValue] = React.useState(0);
    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
        onChangeTab(newValue);
    };

    return (

        <Tabs
            value={value}
            classes={{ root: classes.tabsroot }}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="secondary"
        >
            {tabs.map((prop, index) => (
                <Tab
                    classes={{ root: classes.root }}
                    key={`tab-${index}`}
                    id={`scrollable-force-tab-${index}`}
                    label={prop.tabName}
                />
            ))}

        </Tabs>

    );
}

CustomizedTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            tabName: PropTypes.string.isRequired
        })),
    onChangeTab: PropTypes.func
};

export default CustomizedTabs;
