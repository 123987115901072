import React, { useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import clsx from 'clsx';


import Collapse from '@material-ui/core/Collapse';
//'@material-ui/icons/
import PieChart from '@material-ui/icons/PieChart';
import Message from '@material-ui/icons/Message';
import ClassIcon from '@material-ui/icons/Class';
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from '@material-ui/icons/DateRangeOutlined'
import AssignmentIcon from '@material-ui/icons/Assignment';
import FeedbackIcon from '@material-ui/icons/Feedback';
import PeopleIcon from '@material-ui/icons/People';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import CloseIcon from '@material-ui/icons/Close';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

//util
import { eventTracking } from 'shared/utils/commonfunctions'

const Header = (props) => {
    const { match, onClose, open, onLogout, userInfo } = props
    let location = useLocation();
    const platform = sessionStorage.getItem('platform');

    const active = (path) => {
        if (location.pathname === match.url + path)
            return true
        return false
    }

    const onCloseDrawer = (op) => {
        onClose(op)
    }

    const onBuyCourses = () => {

    }


    const [reports_submenu, setReportSubmenu] = useState(false);
    const onClickReports = () => {
        setReportSubmenu(!reports_submenu);
    };

    return (
        <nav id="sideBar" className={clsx('sidebar sidebar__left', { ['active']: open })}>

            <button className="btn btn--icon btn-close" onClick={onClose}>
                <CloseIcon className="material-icons-outlined" />
            </button>

            <ul className="sidebar__list">

                <li className={`sidebar__item ${active('/dashboard') ? 'active' : ''} `} onClick={() => onCloseDrawer(true)}>
                    <NavLink to={`${match.url}/dashboard`}>
                        <DashboardIcon style={{ fontSize: 18 }} />
                        {open && 'dashboard'}
                    </NavLink>
                </li>

                <li className={`sidebar__item ${active('/resources') ? 'active' : ''} `} onClick={() => onCloseDrawer(false)}>
                    <NavLink to={`${match.url}/resources`}>
                        <LocalLibraryIcon style={{ fontSize: 18 }} />
                        {open && 'Course Content'}

                    </NavLink>
                </li>
                {/* {userInfo.isShowRecordedSession && <li className={`sidebar__item ${active('/recorded-sessions') ? 'active' : ''} `} onClick={() => onCloseDrawer(false)}>
                    <NavLink to={`${match.url}/recorded-sessions`}>
                        <PeopleIcon style={{ fontSize: 18 }} />
                        {open && 'Completed Sessions'}

                    </NavLink>
                </li>} */}

                {/* <li className={`sidebar__item ${active('/doubts') ? 'active' : ''} `} onClick={()=>onCloseDrawer(false)}>
                    <NavLink to={`${match.url}/doubts`}>
                        <FeedbackIcon style={{ fontSize: 18 }} />Doubt Solving
                    </NavLink>
                </li> */}

                <li className={`sidebar__item ${active('/tests') ? 'active' : ''} `} onClick={() => onCloseDrawer(false)}>
                    <NavLink to={`${match.url}/tests`}>
                        <ClassIcon style={{ fontSize: 18 }} />
                        {open && 'Tests'}
                    </NavLink>
                </li>

                <li className={`sidebar__item ${active('/assignments') ? 'active' : ''} `} onClick={() => onCloseDrawer(false)}>
                    <NavLink to={`${match.url}/assignments`}>
                        <AssignmentIcon style={{ fontSize: 18 }} />
                        {open && 'Assignments'}
                    </NavLink>
                </li>


                {/* <li className={`sidebar__item ${active('/bookdemo') ? 'active' : ''} `} onClick={() => {
                    //Event Tracking
                    eventTracking('BookDemo ', {})
                    ()=>onCloseDrawer(false)()
                }}>
                    <NavLink to={`${match.url}/bookdemo`}>
                        <PeopleIcon style={{ fontSize: 18 }} />
                        Book Demo
                    </NavLink>
                </li> */}

                {/* <li className={`sidebar__item`} onClick={()=>onCloseDrawer(false)} style={{ display: platform === 'iOS' ? 'none' : 'block' }}>
                    <a onClick={onBuyCourses} >
                        <ClassIcon style={{ fontSize: 18 }} />Buy Courses
                    </a>
                </li> */}

                {/* reports  */}
                {/* <li className={`sidebar__item`} >
                    <a onClick={onClickReports}>
                        <PieChart style={{ fontSize: 18 }} />reports
                        {reports_submenu ? <ExpandLess className='right_icon' /> : <ExpandMore  className='right_icon'/>}
                    </a>
                </li> */}
                <Collapse in={reports_submenu} timeout="auto" unmountOnExit className='sidebar__collapse'>
                    <li className={`sidebar__collapse__item ${active('/reports_attendance') ? 'active' : ''} `} onClick={() => onCloseDrawer(false)}>
                        <NavLink to={`${match.url}/reports_attendance`}>
                            attendance
                        </NavLink>
                    </li>
                    <li className={`sidebar__collapse__item ${active('/reports_assignment') ? 'active' : ''} `} onClick={() => onCloseDrawer(false)}>
                        <NavLink to={`${match.url}/reports_assignment`}>
                            assignment
                        </NavLink>
                    </li>
                    <li className={`sidebar__collapse__item ${active('/reports_test') ? 'active' : ''} `} onClick={() => onCloseDrawer(false)}>
                        <NavLink to={`${match.url}/reports_test`}>
                            test
                        </NavLink>
                    </li>
                </Collapse>
                {/*end  reports  */}


                {/*<li className={`sidebar__item ${active('/attendance') ? 'active' : ''} `}>
                    <a href="">
                        <AssignmentInd style={{ fontSize: 18 }} />attendance
                    </a>
                </li>

                <li className={`sidebar__item ${active('/reports') ? 'active' : ''} `} >
                    <NavLink to={`${match.url}/reports`}>
                        <PieChart style={{ fontSize: 18 }} />reports
                    </NavLink>
                </li>

                <li className={`sidebar__item ${active('/discussion') ? 'active' : ''} `}>
                    <a href="">
                        <PeopleIcon style={{ fontSize: 18 }} />
                        discussion board
                    </a>
                </li>
                */}
                <li className={`sidebar__item ${active('/messages') ? 'active' : ''} `} onClick={() => onCloseDrawer(false)}>
                    <NavLink to={`${match.url}/messages`}>
                        <Message style={{ fontSize: 18 }} />
                        {open && 'Messages'}
                    </NavLink>
                </li>

                <li className={`sidebar__item ${active('/schedules') ? 'active' : ''} `} onClick={() => onCloseDrawer(false)}>
                    <NavLink to={`${match.url}/schedules`}>
                        <DateRange style={{ fontSize: 18 }} />
                        {open && 'schedule'}

                    </NavLink>
                </li>

                <li className={`sidebar__item ${active('/discussion') ? 'active' : ''} `}>
                    <a onClick={() => onLogout()}>
                        <PowerSettingsNewIcon style={{ fontSize: 18 }} />
                        {open && 'Logout'}

                    </a>
                </li>
            </ul>
        </nav>
    )

}


export default Header;