import * as actionTypes from '../actionTypes'

const initialState = {
    assessments: {
        "assignmentList": [],
        "totalRecords": 0,
        "maxPageCount": 0,
        "activeCount": 0,
        "overDueCount": 0,
        "finishedCount": 0,
        "isSuccess": false,
        "extraBoolValue": false
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ASSESSERNTS:
            return {
                ...state,
                assessments: action.assessments
            }
        default:
            return state;
    }

}


export default reducer