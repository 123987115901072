import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "shared/interceptor/axiosInstance";


//-------------Message Announcements-----------------
export const setMessageAnnouncements = (messages) => {
    return {
        type: actionTypes.SET_MESSAGE_AND_ANNOUNCEMENTS,
        inbox: messages.filter(msg => !msg.IsSentMessage && !msg.IsAnnouncement),
        sent: messages.filter(msg => msg.IsSentMessage && !msg.IsAnnouncement),
        announcements: messages.filter(msg => msg.IsAnnouncement)
    }
};


export const fetchMessageAnnouncements = (Month, Year, IsSent = false) => {
    return async (dispatch, getState) => {
        await axiosConfig().get('/AppCore/GetMessageList', {
            params: {
                IsSent: IsSent,
                MNum: Month,
                YNum: Year,
                TypeId: 1
            },
        }).then(response => {
            dispatch(setMessageAnnouncements(response.data))
        }).catch(error => {
            console.log(error);
            throw new Error(error)
        });
    }
}


//-------------set Messages-----------------
export const setCurrentInboxMessages = (messages) => {
    return {
        type: actionTypes.SET_CURRENRT_INBOX_MESSAGES,
        data: messages
    }
};

export const setInboxMessages = (messages) => {
    return {
        type: actionTypes.SET_INBOX_MESSAGES,
        data: messages
    }
};

export const setSentMessages = (messages) => {
    return {
        type: actionTypes.SET_SENT_MESSAGES,
        data: messages
    }
};



export const fetchMessages = (page) => {
    return async (dispatch, getState) => {
        await axiosConfig().post('AppCore/V2/GetMessageList', {
            "typeId": "1,2,3", //1--Messages, 2--Announcement you can pass comma seperated typeid like "1,2" for both
            "isSent": false,
            "pageNumber": page,
            "pageSize": 15,
            "search": ""
           
        }).then(response => {
            dispatch(setInboxMessages(response.data))
          }).catch(error => {
            console.log(error);
            throw new Error(error)
        });
    }
}


//-------------set Announcement-----------------
export const setAnnouncements = (announcements) => {
    return {
        type: actionTypes.SET_ANNOUNCEMENTS,
        data: announcements
    }
};

export const fetchAnnouncements = (Month, Year) => {
    return async (dispatch, getState) => {
        await axiosConfig().get('/AppCore/GetMessageList', {
            params: {
                IsSent: false,
                MNum: Month,
                YNum: Year,
                TypeId: 2
            },
        }).then(response => {
            dispatch(setAnnouncements(response.data))
        }).catch(error => {
            console.log(error);
            throw new Error(error)
        });
    }
}




// -----------------------Recipient List-----------------
export const setRecipientList = (recipient_list) => {
    return {
        type: actionTypes.SET_RECIPIENT_LIST,
        data: recipient_list,
    }
};

export const fetchRecipientList = () => {
    return async (dispatch, getState) => {
        await axiosConfig().get('/AppCore/GetRecipientList').then(response => {
            dispatch(setRecipientList(response.data))
        }).catch(error => {
            console.log(error);
            throw new Error(error)
        });
    }
}

//------------------------------- Update Message Status-----------------------
export const updateMessageStatus = (mappingId,typeId) => {
    return async (dispatch, getState) => {
        await axiosConfig().get('/AppCore/UpdateMessageStatus', {
            params: {
                MappingId: mappingId,
                TypeId:typeId
            }
        }).then(response => {
            // console.log(response);
        }).catch(error => {
            console.log(error);
            throw new Error(error)
        });
    }
}


//------------------------------- Update Message Starred Status-----------------------
export const updateStarredStatus = (mappingId,typeId) => {
    return async (dispatch, getState) => {
        await axiosConfig().get('/AppCore/UpdateMessageStarredStatus', {
            params: {
                MappingId: mappingId,
                TypeId:typeId
            }
        }).then(response => {
            // console.log(response);
        }).catch(error => {
            console.log(error);
            throw new Error(error)
        });
    }
}