import React from "react";
// @material-ui/core
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonCard = (props) => {
    return (  
      <div className="component-assignments">
        <div className="card shadow bg-white">
          <div className="card__head">
            <h6 className="card__title">assignments</h6>
          </div>
          <div className="card__body">
            <div className="link__list">
  
  
              {['001', '010', '011', '111'].map((data, idex) => (
                <a className="link__item" key={data+"#_"+idex}>
                  <div className="status bg--green">
  
                  <span className="meta date"><Skeleton height={'100%'} width={'100%'} style={{transform: 'scale(1)'}} animation="wave"/></span>   
                  </div>
                  <div className="wrapper">
                    <Skeleton className="meta title" animation="wave"/>
                    <Skeleton className="meta name" animation="wave"/>
                    <Skeleton className="meta text" animation="wave"/>
                  </div>
                </a>
              ))}
  
            </div>
          </div>
        </div>
      </div>
      )
  }

  export default SkeletonCard;