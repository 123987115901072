import React, { useState } from "react";

//import reducer 
import { useSelector } from 'react-redux'
// core components
import PageNavigation from 'shared/components/Navbars/PageNavigation'

const RecordingViewer = (props) => {

    const { wrapperStyle, navigation } = props;
    const [loading, setLoading] = useState(true)
    const [display, setDisplay] = useState('none')

    const content = useSelector(state => state.recordedSessions.current_session)

    const hideSpinner = () => {
        setLoading(false);
        setDisplay('block')
    };



    return (
        <div >
            {/*-----PageNavigation----- */}
            {navigation && <PageNavigation logoText={content.structureDisplayName} />}

            <div className={`${wrapperStyle ? '' : 'toppadding'}`}>
                {loading ? (<div style={{
                    height: '100vh',
                    display: `${display === "none" ? "flex" : "none"}`,
                    alignItems: 'center'
                }}>
                    <div className="loader">Loading...</div>
                </div>
                ) : null}
                {content &&
                    <iframe
                        style={{
                            position: 'relative',
                            left: 0,
                            // bottom: 0,
                            right: 0,
                            width: '100%',
                            minHeight: '100vh',
                            border: "none",
                            display: { display }
                        }}
                        onLoad={hideSpinner}
                        src={content.recordingURL}
                    />
                }
            </div>
        </div>

    );
}


RecordingViewer.defaultProps = {
    navigation: true,
    wrapperStyle: false,
};

export default RecordingViewer;