import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "assets/jss/components/customcomponents/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function Info(props) {
  const classes = useStyles();
  const { children,style,className, } = props;

  const infoClasses = classNames({
    [classes.defaultFontStyle]: true,
    [classes.primaryText]: true,
    [className]: className
  })
  return (
    <div className={infoClasses} style={style}>
      {children}
    </div>
  );
}

Info.propTypes = {
  children: PropTypes.node,
  style:PropTypes.object
};
