import React from "react";
import { Divider, Typography } from '@material-ui/core';

import { Link } from "react-router-dom";
import SkeletonCard from "./SkeletonCard";

const Counts = (props) => {
    const { assignments, tests ,loading } = props
    const assignmentpercentage=assignments.finishedCount===0?0:(assignments.finishedCount/assignments.totalRecords)*100
    const testspercentage=tests.finishedCount===0?0:(tests.finishedCount/tests.totalRecords)*100
   
    if (loading) {
        return (
          <SkeletonCard />
        )
      }
   
    return (
        <div className="component component-grid component-counts">
            <div className="row">

                <div className="col-md-6">
                    <div className="card shadow bg-white">
                        <Typography variant="subtitle1" className="title">{'Assignments'}</Typography>
                        <div >
                            <div className="rating"><span>{assignments.finishedCount}</span>/{assignments.totalRecords}</div>
                            <div className="completion">{assignmentpercentage?assignmentpercentage.toFixed(2):0}%</div>
                        </div>
                        <Divider />
                        <div >
                            <Link to={`/home/assignments`} className='action'>View Details</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow bg-white">
                        <Typography variant="subtitle1" className="title">{'Tests'}</Typography>
                        <div >
                            <div className="rating"><span>{tests.finishedCount}</span>/{tests.totalRecords}</div>
                            <div className="completion">{testspercentage?testspercentage.toFixed(2):0}%</div>
                        </div>
                        <Divider />
                        <div >
                            <Link to={`/home/tests`} className='action'>View Details</Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}
export default Counts;