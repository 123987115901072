import React, { useEffect, useState, lazy, Suspense } from "react";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
//@material-ui/core
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Skeleton from '@material-ui/lab/Skeleton';
//@material-ui/icons
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

//components
import OnlineSessionCard from './components/OnlineSessionCard/OnlineSessionCard'
import OnlineTestCard from './components/OnlineTestCard/OnlineTestCard'

//Redux
import { useDispatch, useSelector } from 'react-redux'
import * as scheduleActions from "components/Views/Schedules/store/actions"



const ViewRecording = lazy(() => import('components/Views/ViewRecording'));

const Schedules = (props) => {

    const { page__title } = props
    const dispatch = useDispatch();
    const moment = extendMoment(Moment);

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    //state
    const schedule = useSelector(state => state.schedule.scheduleList)
    const userInfo = useSelector(state => state.user.userinfo)

    const weekdayshort = moment.weekdaysShort();


    const today = moment().utc().add(330, 'm').format("YYYY-MM-DD")
    const [dateObject, setDateObject] = useState(moment())
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(moment().startOf("month"))
    const [endDayOfMonth, setEndDayOfMonth] = useState(moment().endOf("month"))
    const [daysInMonth, setDaysInMonth] = useState(moment().daysInMonth())
    const [weeksInMonth, setWeeksInMonth] = useState([])

    const [currentDay, setCurrentDay] = useState(moment().format("D"))
    const [currentWeekNumber, setCurrentWeekNumber] = useState(0)
    const [firstDayOfWeek, setFirstDayOfWeek] = useState(moment().format("D"))
    const [lastDayOfWeek, setLastDayOfWeek] = useState(moment().format("D"))
    const [currentMonth, setCurrentMonth] = useState(moment().format("MMMM"))
    const [currentYear, setCurrentYear] = useState(moment().format("Y"))
    const [modifiedMonth, setModifiedMonth] = useState(moment())

    const [startBlanks, setStartBlanks] = useState([])
    const [endBlanks, setEndBlanks] = useState([])
    const [daysComponet, setDaysComponet] = useState([])
    const [calendarRowsCells, setCalendarRowsCells] = useState([])

    const [calendarMode, setCalendarModde] = useState("month")
    const [infoType, setInfoType] = useState("all")//"schedule","assignment",all
    const [loading, setLoading] = useState(true)
    const [activeTooltip, setActiveTooltip] = useState("")

    const [viewRecordingDialog, setRecordingDialog] = useState(false)
    const [recordingURL, setRecordingURL] = useState(false)

    useEffect(() => {
        page__title('Schedule')
    }, [])



    useEffect(() => {
        setCurrentYear(moment(dateObject).format("Y"))
        setDaysInMonth(moment(dateObject).daysInMonth())
        setFirstDayOfMonth(moment(dateObject).startOf("month"))
        setEndDayOfMonth(moment(dateObject).endOf("month"))

        let WeeksInMonth = getWeeksInMonth()
        setWeeksInMonth(WeeksInMonth)

        let cday = moment(dateObject).format("YYYY-MM-DD")
        if (moment(today).isSame(cday)) {
            setCurrentWeekNumber(WeeksInMonth.indexOf(moment(today).week()))
        } else {
            if (moment(modifiedMonth).isAfter(cday))
                setCurrentWeekNumber(WeeksInMonth.length - 1)
            else
                setCurrentWeekNumber(0)
        }
    }, [currentMonth])

    const getWeeksInMonth = () => {
        let firstDay = moment(dateObject).startOf("month")
        let endDay = moment(dateObject).endOf("month")

        const monthRange = moment.range(firstDay, endDay);

        // console.log(monthRange);
        let weeks = []
        for (let mday of monthRange.by('days')) {
            if (weeks.indexOf(mday.week()) === -1) {
                weeks.push(mday.week());
            }
        }
        return weeks
    }


    useEffect(() => {

        if (weeksInMonth.length) {
            const weeknumber = weeksInMonth[currentWeekNumber];
            let firstWeekDay = moment(firstDayOfMonth).week(weeknumber).day(0);
            if (firstWeekDay.isBefore(firstDayOfMonth)) {
                firstWeekDay = firstDayOfMonth;
            }
            let lastWeekDay = moment(endDayOfMonth).week(weeknumber).day(6);
            if (lastWeekDay.isAfter(endDayOfMonth)) {
                lastWeekDay = endDayOfMonth;
            }
            setFirstDayOfWeek(moment(firstWeekDay).format("D"))
            setLastDayOfWeek(moment(lastWeekDay).format("D"))

        }
    }, [currentWeekNumber, firstDayOfMonth, endDayOfMonth, weeksInMonth])


    useEffect(() => {
        const startDate = dateObject.startOf("month").format("YYYY-MM-DD")
        const endDate = dateObject.endOf("month").format("YYYY-MM-DD")
        fetchSchedule(0, infoType, startDate, endDate)
    }, [currentMonth, infoType])


    const fetchSchedule = async (subjectId, infoType, startDate, endDate) => {
        setLoading(true)
        await dispatch(scheduleActions.fetchSchedule(subjectId, infoType, startDate, endDate)).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error);
        });
    }


    // Start Blanks
    useEffect(() => {
        let blanks = [];
        for (let i = 0; i < firstDayOfMonth.format("d"); i++) {
            blanks.push(<div className="calendar__info"></div>);
        }
        setStartBlanks(blanks)
    }, [firstDayOfMonth.format("d")])

    // End Blanks
    useEffect(() => {
        let blanks = [];
        for (let i = (7 - endDayOfMonth.format("d")); i >= 2; --i) {
            blanks.push(<div className="calendar__info"></div>);
        }
        setEndBlanks(blanks)
    }, [endDayOfMonth.format("d")])


    const showTooltip = (id) => {
        setActiveTooltip(id)
    }

    // handle click upload 
    const handleViewRecording = (url) => {
        setRecordingURL(url)
        setRecordingDialog(true)
    }

    // close Dialogs
    const closeDialogs = () => {
        setRecordingURL("")
        setRecordingDialog(false)
    }

    // all Days Componet
    useEffect(() => {
        let days_In_Month = [];
        for (let d = 1; d <= daysInMonth; d++) {

            let cdate = moment(`${currentYear}-${currentMonth}-${d}`).format("YYYY-MM-DD")

            let current_day = cdate == today ? "active" : "";
            let onlineSession = schedule.schedulesLists.filter(data => d == moment(data.startTime).format("D"))
            let assignments = schedule.assignmentLists.filter(data => d == moment(data.startDate).format("D"))


            let days_status = ""
            if (moment(cdate).isAfter(today)) {
                days_status = 'dot dot__upcoming'
            }


            days_In_Month.push(
                <div className="calendar__info" key={"day_" + d}>
                    <span className={`calendar__date ${current_day}`}>{dateObject.format('MMM')} {d}</span>
                    <div className="list__group">
                        {(onlineSession.length && !loading)
                            ? <ul className="upcoming">
                                <li className="list__group-title ">online session</li>
                                {onlineSession.map(data => (
                                    <OnlineSessionCard
                                        key={data.scheduleId}
                                        data={data}
                                        status={days_status}
                                        onShowTooltip={showTooltip}
                                        activeId={activeTooltip}
                                        onViewRecording={userInfo.isShowRecordedSession?handleViewRecording:null}
                                    />
                                ))}
                            </ul>
                            : null
                        }
                        {(assignments.length && !loading)
                            ? <ul className="notattented">
                                <li className="list__group-title">Assignment</li>
                                {assignments.map(data => (
                                    <OnlineTestCard
                                        key={data.assignmentMappingId}
                                        data={data}
                                        onShowTooltip={showTooltip}
                                        activeId={activeTooltip}
                                    />
                                ))}
                            </ul>
                            : null
                        }

                        {assignments.length === 0 && onlineSession.length === 0 && !loading ?
                            <ul className="blank"></ul>
                            : null
                        }

                        {loading
                            ? <ul className="blank">
                                <Skeleton animation="wave" height={'50%'} />
                                <Skeleton animation="wave" height={'50%'} />
                            </ul>
                            : null
                        }

                    </div>
                </div>
            );
        }
        setDaysComponet(days_In_Month)
    }, [daysInMonth, schedule, activeTooltip, loading])




    // Calendar Rows Cells
    useEffect(() => {
        let totalSlots = sm ? [...startBlanks, ...daysComponet] : [...startBlanks, ...daysComponet, ...endBlanks];

        let weekRows = [];
        totalSlots.forEach((row, i) => {
            weekRows.push(
                <div className="calendar__week">
                    <span className="">{weekdayshort[i % 7]}</span>
                </div>
            ); // if index not equal 7 that means not go to next week
        });


        let rows = [];
        let cells = [];
        totalSlots.forEach((row, i) => {
            const newRow = <div key={'datecol#'+i} className="calendar__datecol">
                {sm || calendarMode === 'week' ? weekRows[i] : (i <= 6) ? weekRows[i] : null}
                {row}
            </div>

            if (i % 7 !== 0) {
                cells.push(newRow); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows
                cells = []; // empty container
                cells.push(newRow); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                rows.push(cells);
            }
        });

        if (calendarMode === 'month')
            setCalendarRowsCells(rows)
        if (calendarMode === 'week') {
            setCalendarRowsCells([rows[currentWeekNumber + 1]])
        }

    }, [startBlanks, daysComponet, endBlanks, calendarMode, currentWeekNumber, sm])



    const onPrev = () => {
        setModifiedMonth(dateObject.format("YYYY-MM-DD"))
        if (calendarMode === 'week') {
            if (currentWeekNumber > 0)
                setCurrentWeekNumber(currentWeekNumber - 1)
            else {
                let changedDate = dateObject.subtract(1, "month")
                setDateObject(moment(changedDate));
                setCurrentMonth(moment(changedDate).format("MMMM"))
            }

        } else {
            let changedDate = dateObject.subtract(1, "month")
            setDateObject(moment(changedDate));
            setCurrentMonth(moment(changedDate).format("MMMM"))
        }

    };

    const onNext = () => {
        setModifiedMonth(dateObject.format("YYYY-MM-DD"))
        if (calendarMode === 'week') {
            if (currentWeekNumber < weeksInMonth.length - 1)
                setCurrentWeekNumber(currentWeekNumber + 1)
            else {
                let changedDate = dateObject.add(1, "month")
                setDateObject(moment(changedDate));
                setCurrentMonth(moment(changedDate).format("MMMM"))
            }

        } else {
            let changedDate = dateObject.add(1, "month")
            setDateObject(moment(changedDate));
            setCurrentMonth(moment(changedDate).format("MMMM"))
        }
    };

    // Week name Componet
    // const Weekdayshortname = () => {
    //     let rows = [];
    //     if (calendarMode !== 'week' && sm) {
    //         const totalSlots = [...startBlanks, ...daysComponet];
    //         totalSlots.forEach((row, i) => {
    //             rows.push(<span className="calendar__datecol">{weekdayshort[i % 7]}</span>); // if index not equal 7 that means not go to next week
    //         });
    //     } else {
    //         weekdayshort.forEach(day => { rows.push(<span key={day} className="calendar__col">{day}</span>) })
    //     }


    //     return (
    //         <React.Fragment>
    //             {rows}
    //         </React.Fragment>
    //     )
    // };

    // Calendar Days Componet
    const CalendarDays = ({ data }) => {
        return (data.map((d, i) => {
            return <div key={"CalendarDays_" + i} className="calendar__row calendar__day">{d}</div>;
        }))
    }




    return (
        <div className="page-content__body">

            <Dialog open={viewRecordingDialog} maxWidth="lg" fullWidth={true} >
                {viewRecordingDialog && <Suspense fallback={<div > Loading...</div>}>
                    <ViewRecording onClose={closeDialogs} data={recordingURL} />
                </Suspense>}
            </Dialog>

            <section className="component bg-white shadow">
                <div className="card">
                    <div className="card__head">
                        <h6 className="card__title">calendar</h6>
                        <ul className="nav nav-tabs custom-tabs ms-md-6">
                            <li className="nav-item">
                                <a className={`nav-link ${infoType === 'all' ? 'active' : ''}`}
                                    onClick={() => setInfoType("all")}>
                                    all
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${infoType === "schedule" ? 'active' : ''}`}
                                    onClick={() => setInfoType("schedule")}>
                                    sessions
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${infoType === `assignment` ? 'active' : ''}`}
                                    onClick={() => setInfoType(`assignment`)}>
                                    assignments
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${infoType === 'absent' ? 'active' : ''}`}
                                    onClick={() => setInfoType('absent')}>
                                    absent
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="#">holiday</a>
                            </li> */}

                        </ul>
                        <ul className="legend ms-auto row" style={{ minWidth: '30%' }}>

                            <li className="attended col-6 col-sm-3 col-md-6 col-lg-3">attended</li>
                            <li className="notattented col-6 col-sm-3 col-md-6 col-lg-3">not attended</li>
                            <li className="partially col-6 col-sm-3 col-md-6 col-lg-3">partially attended</li>
                            <li className="upcoming col-6 col-sm-3 col-md-6 col-lg-3">up coming</li>

                            {/* <li className="holiday">holiday</li> */}
                        </ul>
                    </div>
                    <div className="card__body">
                        <div className="filters">
                            <div className="slide-dates">
                                <span className="btn btn-prev" onClick={() => onPrev()}>
                                    <ChevronLeftIcon />
                                </span>
                                {calendarMode === 'week'
                                    ? <span className="date">{currentMonth},{firstDayOfWeek}-{lastDayOfWeek} {currentYear}</span>
                                    : <span className="date">{currentMonth}, {currentYear}</span>
                                }

                                <span className="btn btn-next" onClick={() => onNext()}>
                                    <ChevronRightIcon />
                                </span>
                            </div>
                            <ul className="nav nav-tabs custom-tabs my-3 ms-auto">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${calendarMode === 'week' ? 'active' : ''}`}
                                        onClick={() => setCalendarModde('week')}
                                    >
                                        week
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${calendarMode === 'month' ? 'active' : ''}`}
                                        onClick={() => setCalendarModde('month')}
                                    >
                                        month
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="calendar">
                            {/* <div className="calendar__row calendar__week">
                                <Weekdayshortname />
                            </div> */}
                            <div className="calendar__row-wrap">
                                <CalendarDays data={calendarRowsCells}></CalendarDays>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>



    )
}

export default Schedules