import React from 'react';

import { withRouter } from 'react-router-dom';

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from "shared/components/Dialog/Dialog";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PowerSettingsNewTwoToneIcon from '@material-ui/icons/PowerSettingsNewTwoTone';
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';

// Views
import ChangePassword from 'components/Views/ChangePassword/ChangePassword';
import Notification from 'components/Views/Notification/Notification';

//util
import { eventTracking , SchoolLogo } from 'shared/utils/commonfunctions'

const AdminNavbarLinks = (props) => {
  const {
    toggleNotify,
    userInfo,
    loadingNotify,
    notifications,
    onLogout
  } = props
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogState, setDialogState] = React.useState(false);
  const [open, setOpen] = React.useState(false);


  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onLogoutPress = () => {
    eventTracking('Logout ', {})
    setAnchorEl(null);
    onLogout()
  }

  const onChangePasswordPress = () => {
    //Event Tracking
    eventTracking('ChangePassword ', {})

    setAnchorEl(null);
    handleOpenDialog()
  }

  /*-----------------------handle Close Dialog--------------------*/
  const handleCloseDialog = () => {
    setDialogState(false);
  };

  /*-----------------------handle Close Dialog--------------------*/
  const handleOpenDialog = () => {
    setDialogState(true);
  };

  /*-----------------------handle Close Dialog--------------------*/
  const onStart = () => {
    setDialogState(false);
    setOpen(true);
  }

  /*-----------------------handle Close Snackbar--------------------*/
  const handleClose = (event, reason) => {
    setOpen(false);
  }

  const openPopover = Boolean(anchorEl);

  // Notification
  const [notification, setNotification] = React.useState(null);

  const handleOpenNotification = (event) => {
    setNotification(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setNotification(null);
  };

  const notification_status = Boolean(notification);

  return (
    <header className="header">
      <div className="header-wrap">
        <div className="site-logo">
          {/* <a href=""> */}
          {/* <img src={require('../../../assets/img/slate/TopBarLogo.png')} alt="logo" className="logo" /> */}
          {/* <img src={require('../../../assets/img/slate/Zenlogo.png')} alt="logo" className="logo" /> */}
          <SchoolLogo className="logo" />
          {/* </a> */}
        </div>
        <div className="site-settings">
          <div className="user">
            <Avatar className='user__avatar'>
              {userInfo.firstName.charAt(0)}
            </Avatar>
            <div className="wrapper">
              <div className='user__div'>
                <span className="user__name">{userInfo.firstName}</span>
                <IconButton onClick={handlePopoverOpen} >
                  {openPopover
                    ? <ExpandLessIcon fontSize='small' />
                    : <ExpandMoreIcon fontSize='small' />
                  }
                </IconButton>
              </div>

              {/* <span className="user__class d-block">class: 08</span> */}
            </div>
          </div>
          <div className="notification" onClick={handleOpenNotification}>
            <span className="icon-svg icon-bell"></span>
            {notifications.length > 0
              ? <span className="notification__count">{notifications.length}</span>
              : null
            }
          </div>
        </div>
      </div>


      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >

        <MenuItem onClick={onChangePasswordPress}>
          <ListItemIcon>
            <LockOpenTwoToneIcon style={{ fontSize: 18 }} />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary="Change Password" />
        </MenuItem>
        <MenuItem onClick={onLogoutPress}>
          <ListItemIcon>
            <PowerSettingsNewTwoToneIcon style={{ fontSize: 18 }} />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary="Logout" />
        </MenuItem>
      </Popover>

      {/* Noti */}

      <Popover
        open={notification_status}
        anchorEl={notification}
        onClose={handleCloseNotification}
        elevation={0}
        PaperProps={{
          style: {
            backgroundColor: 'transparent'
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Notification
          loadingNotify={loadingNotify}
          notification={notifications} />
      </Popover>

      {/*-----Dialog----- */}
      <Dialog open={dialogState} maxWidth={"xs"} fullScreen={fullScreen} scroll="paper" >
        <ChangePassword onClose={handleCloseDialog} onStart={onStart} />
      </Dialog>

      {/* ------Snackbar------ */}
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" elevation={6} variant="filled">
          Your password has been changed successfully. Please Logout and Login with new password.
        </Alert>
      </Snackbar>
    </header>

  );
}


export default withRouter(AdminNavbarLinks);