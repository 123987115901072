import React, { useEffect } from 'react';
//Redux
import { connect, useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

// @material-ui/core
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TODAY_DATE_TIME } from "shared/variables/general.js";


const useStyles = makeStyles((theme) => ({
    root: {
        textTransform: 'none',
        fontWeight: 'bold',
        color: '#ffff'
    },
    tabsroot: {
        backgroundColor: '#405965',
        // position: 'fixed',
        zIndex: 2,
        width: '100%'
    }

}));

const SubjectsTab = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch()

    const content = useSelector(state => state.content.current_content)

    useEffect(() => {
        // dispatch(questActions.clearCurrentContent())
        if (props.contentList.Add.Subject[0] !== undefined)
            dispatch(questActions.setCurrentSubject(props.contentList.Add.Subject[0]))
    }, [props.contentList.Add.Subject,dispatch])

    const handleChange = (event, newValue) => {
       props.onSubjectChange(false)
        const currentSubject = props.contentList.Add.Subject.filter(subject => subject.structureId === newValue).reduce((acc, cv) => { return acc = cv }, {})
        if (Object.keys(content).length !== 0) {
            if (content.accessDuration > 10) {
                dispatch(questActions.postContentAccess())
            } else {
                dispatch(questActions.clearCurrentContent())
            }
        }
        dispatch(questActions.setCurrentSubject(currentSubject))
    };
    return (

        <Tabs
            value={props.subject.structureId}
            classes={{ root: classes.tabsroot }}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="secondary"
        >
            {props.contentList.Add.Subject.map((subject, index) => (
                <Tab
                    classes={{ root: classes.root }}
                    value={subject.structureId}
                    key={`tab-${subject.structureId}`}
                    id={`scrollable-force-tab-${index}`}
                    label={subject.structureDisplayName}
                />
            ))}

        </Tabs>

    );
}

const mapStateToProps = state => {
    return {
        contentLoading: state.content.loading,
        subject: state.content.current_subject,
        userInfo: state.user.userinfo,
        currentCourse: state.course.current_course,
    }
}



export default connect(mapStateToProps, null)(SubjectsTab);