import React from 'react'

// nodejs library to set properties for components
import PropTypes from "prop-types"

// material-ui components

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import useStyles from "assets/jss/components/customcomponents/searchbarStyle";

const Searchbar = (props) => {
    const classes = useStyles();
    const {
        open,
        handleClose,
        ...rest
    } = props;
    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <form noValidate>
            <InputBase
                placeholder="Search…"
                onChange={e => props.inputChange(e.target.value)}
                autoComplete="new-password"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
            />
            </form>
        </div>
    )
}

Searchbar.propTypes = {
    inputChange: PropTypes.func
};

export default Searchbar;