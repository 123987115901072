import * as actionTypes from 'store/definitions/actionTypes'

const initialState = {
    schedule: {
        ScheduleDataResult:[]
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MEETINGS_SCHEDULE:
            return {
                ...state,
                schedule: action.schedule
            }
        default:
            return state;
    }

}


export default reducer