import * as actionTypes from '../definitions/actionTypes';
// import axios
import axiosConfig from "shared/interceptor/axiosInstance";

export const fetchAppConfigsStart = () => {
    return {
        type: actionTypes.FETCH_APPCONFIGS_START
    }
}

export const setAppConfigs = (configs) => {
    return {
        type: actionTypes.SET_APPCONFIGS,
        appConfigs: configs
    }
}

export const fetchAppConfigsFailed = () => {
    return {
        type: actionTypes.FETCH_APPCONFIGS_FAILED
    }
}

export const initAppConfigs = () => {
    return dispatch => {
        dispatch(fetchAppConfigsStart())
        axiosConfig().get('/AppCore/GetConfiguration', {
            params: {
                AppType: 'Web',
            },
            headers: {}
        }).then(response => {
            dispatch(setAppConfigs(response.data.config))
        })
            .catch(function (error) {
                dispatch(setAppConfigs(error))
                dispatch(fetchAppConfigsFailed())
            });
    }
}
// Code for IP Save IP
export const initIP = () => {
    return  async (dispatch, getState) => {
       await axiosConfig().get('AppCore/GetIP').then(response => {
            sessionStorage.setItem('ip', response.data);
        })
            .catch(function (error) {
                sessionStorage.setItem('ip', " 127.0.0.1");
            });
    }
}

