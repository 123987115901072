import React, { useState, useEffect } from 'react';

//Redux
import { useSelector } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline';

//Custom Components
import Loading from 'shared/components/Progress/Progress'


const Intermediate = (props) => {
    const userInfoError = useSelector(state => state.user.error)
    const userAuthError = useSelector(state => state.user.auth_error)
    const courseloading = useSelector(state => state.course.loading)
    const courselist = useSelector(state => state.course.course_list)

    useEffect(() => {
        if (courseloading === false)
            courselist.length ? props.history.push('/home') : props.history.push('/home')
    }, [courseloading])

    useEffect(() => {
        // session is invalid
        if (userInfoError || userAuthError) {
            console.log("session is invalid");
            localStorage.clear();
            sessionStorage.clear();
            props.history.push('/');
        }
    }, [userInfoError, userAuthError])

    return (
        <div className='signin-container background-signin'>
            <CssBaseline />
            <Loading open={true} ></Loading>
        </div>
    )
}

export default Intermediate;

