import React, { useEffect, useState } from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
// core components
import PageNavigation from 'shared/components/Navbars/PageNavigation'

const DocView = (props) => {

    const { navigation, wrapperStyle } = props;

    const dispatch = useDispatch()
    const content = useSelector(state => state.content.current_content)
    const [accessDuration, setAccessDuration] = useState(0)

    useEffect(() => {
        let accessTime = 0;
        const timer = setInterval(() => {
            accessTime++
            setAccessDuration(accessTime)
        }, 1000);
        return () => {
            clearTimeout(timer)
        };
    }, []);

    useEffect(() => {
        if (accessDuration > 10) {
            dispatch(questActions.updateContentAccessDuration(accessDuration))
        }
    }, [accessDuration])


    return (
        <div>
            {/*-----PageNavigation----- */}
            {navigation && <PageNavigation logoText={content.courseContentDisplayName} />}

            {/*-----page-container----- */}
            <div className={`${wrapperStyle ? 'content-container-task' : 'page-container'}`}>
                <div >
                    <div
                        style={{
                            //-position: 'absolute',
                            //-left: 0,
                            // bottom: 0,
                            //-right: 0,
                            width: '100%',
                            minHeight: 'calc(100vh - 160px)',
                            padding:50,
                            border: "none"
                        }}
                        dangerouslySetInnerHTML={{
                            __html: content.htmlcontent
                        }} />
                </div>
            </div>
        </div>
    );

}

DocView.defaultProps = {
    navigation: true,
    wrapperStyle: false,
};

export default DocView

