import * as actionTypes from '../definitions/actionTypes'

const initialState = {
    metadeta: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // ------- metadeta -------------
        case actionTypes.SET_METADATA:
            return {
                ...state,
                metadeta: action.data
            }    
        default:
            return state;
    }

}


export default reducer