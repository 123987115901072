import React, { useState, useEffect } from "react";
//Redux
import { connect, useDispatch, useSelector } from 'react-redux'

import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// import @material-ui/icons
import ExpandLess from '@material-ui/icons/RemoveCircleOutline';
import ExpandMore from '@material-ui/icons/AddCircleOutline';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';


// import LinearProgressWithLabel from 'shared/components/Progress/LinearProgressWithLabel'
import Searchbar from 'shared/components/Searchbar/Searchbar'

// Views
import ContentList from './ContentListItem'

// styles
import useStyles from "assets/jss/components/views/operationStyle";
import '../style.css'

//import reducer 
import * as questActions from 'store/actions'



const ListItems = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.chaptersList)
    }, [props.chaptersList])

    const handleClick = (current_value) => {
        const newData = data.map(value => {
            value.collapse = value.structureId === current_value.structureId ? !value.collapse : false
            return value
        })
        setData(newData)
    }

    return (
        < React.Fragment >
            {data.map(current_chapter =>
                <React.Fragment key={current_chapter.structureId}>
                    <Divider variant="fullWidth" classes={{ root: classes.divider1 }} />
                    <ListItem button onClick={() => handleClick(current_chapter)} className={classes.nested_chapter}>
                        {/* <ListItemIcon >
                                <Icon className={'icon-uni019E'} fontSize="small"></Icon>
                            </ListItemIcon> */}
                        <ListItemText
                            disableTypography={true}
                            primary={<Typography className={classes.chapter}> {current_chapter.structureDisplayName} </Typography>}
                        // secondary={<LinearProgressWithLabel value={current_chapter.progress.progress} />} 
                        />
                        {current_chapter.collapse
                            ? <ExpandLess fontSize="small" style={{ color: "#46494B" }} />
                            : <ExpandMore fontSize="small" style={{ color: "#46494B" }} />}
                    </ListItem>
                    <Collapse in={current_chapter.collapse} timeout={500} unmountOnExit >
                        {props.topicsList.filter(current_content => current_content.structureId === current_chapter.structureId).map((submenu, index) =>
                            <ContentList currentSessions={props.currentSessions} content={{ ...submenu }} key={submenu.courseContentId + "#_#" + index} level='one' onClick={props.onClickItem} />
                        )}

                        {/* Topics */}
                        {props.topicsList.filter(current_topic => current_topic.parentStructureId === current_chapter.structureId).map((topics, index) =>
                            <React.Fragment key={topics.structureId + "#" + topics.parentStructureId + "_" + index}>
                                <Divider variant="fullWidth" classes={{ root: classes.divider }} />
                                <ListItem className={classes.nested}>
                                    {/* <ListItemIcon >
                                            <Icon className={'icon-uni019E'} fontSize="small"></Icon>
                                        </ListItemIcon> */}
                                    <ListItemText
                                        disableTypography={true}
                                        primary={<Typography className={classes.topic}> {topics.structureDisplayName} </Typography>}
                                    />
                                </ListItem>
                                {props.topicsList.filter(current_content => current_content.structureId === topics.structureId).map((submenu, index) =>
                                    <ContentList currentSessions={props.currentSessions} content={{ ...submenu }} key={submenu.courseContentId + "#" + index} level='two' onClick={props.onClickItem} />
                                )}
                            </React.Fragment>
                        )}

                    </Collapse>
                </React.Fragment>
            )}

        </React.Fragment >

    )
}


const ChapterCards = (props) => {
    const classes = useStyles();
    const [searchInput, setSearchInput] = useState("")

    const chaptersList = props.contentList.Add.Chapter.filter(current_value => current_value.parentStructureId === props.subject.structureId && current_value.structureDisplayName.toUpperCase().includes(searchInput)).map((data, i) => {
        if (i === 0)
            data.collapse = true
        return data
    })

    const topicsList = useSelector(state => state.recordedSessions.recorded_sessions)

    const handleInputChange = (input) => {
        setSearchInput(input.toUpperCase())
    }

    return (
        <List >
            <Searchbar inputChange={handleInputChange} />

            <ListItems
                subject={props.subject}
                chaptersList={chaptersList}
                topicsList={topicsList}
                contentList={props.contentList}
                searchInput={searchInput}
                onClickItem={props.onClick}
                currentSessions={props.currentSessions}
            />
        </List>

    )
}


const mapStateToProps = state => {
    return {
        subject: state.content.current_subject,
        operation: state.content.current_operation
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentOperation: (operation) => dispatch(questActions.setCurrentOperation(operation))
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(ChapterCards);




