import * as actionTypes from 'store/definitions/actionTypes'

const initialState = {
    userinfo: {
        contactNo: "",
        extraBoolValue: false,
        firstName: "",
        institutionId: 1,
        isActive: true,
        isDeleted: false,
        isSuccess: false,
        lastName: "",
        modifiedOn: "",
        userId: "",
        userType: ""
    },
    error: false,
    userQuest: {},
    loading: false,
    post_password_error: false,
    post_password_loading: false,
    auth_error: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USERINFO_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SET_USERINFO:
            return {
                ...state,
                userinfo: action.userinfo,
                error: false,
                loading: false
            }
        case actionTypes.FETCH_USERINFO_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }
        // ------- Post Change Passsword -------------
        case actionTypes.POST_CHANGE_PASSWORD_START:
            return {
                ...state,
                post_password_error: false,
                post_password_loading: true
            }

        case actionTypes.POST_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                post_password_error: false,
                post_password_loading: false
            }

        case actionTypes.POST_CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                post_password_error: true,
                post_password_loading: false
            }
        case actionTypes.AUTH_FAILED:
            return {
                ...state,
                auth_error: action.auth,
            }
        default:
            return state;
    }


}


export default reducer