
// App Configs
export const FETCH_APPCONFIGS_START = 'FETCH_APPCONFIGS_START'
export const SET_APPCONFIGS = 'SET_APPCONFIGS'
export const FETCH_APPCONFIGS_FAILED = 'FETCH_APPCONFIGS_FAILED'

// Post change password
export const POST_CHANGE_PASSWORD_START = "POST_CHANGE_PASSWORD_START"
export const POST_CHANGE_PASSWORD_SUCCESS = "POST_CHANGE_PASSWORD_SUCCESS"
export const POST_CHANGE_PASSWORD_FAILED = "POST_CHANGE_PASSWORD_FAILED"

//user
export const FETCH_USERINFO_START = 'FETCH_USERINFO_START'
export const SET_USERINFO = 'SET_USERINFO'
export const FETCH_USERINFO_FAILED = 'FETCH_USERINFO_FAILED'

export const FETCH_USER_PROGRESS_START = 'FETCH_USER_PROGRESS_START'
export const SET_USER_PROGRESS = 'SET_USER_PROGRESS'
export const FETCH_USER_PROGRESS_FAILED = 'FETCH_USER_PROGRESS_FAILED'

export const AUTH_FAILED = 'AUTH_FAILED'

//content
export const FETCH_CONTENTLIST_START = "FETCH_CONTENTLIST_START"
export const SET_CONTENTLIST = 'SET_CONTENTLIST'
export const FETCH_CONTENTLIST_FAILED = 'FETCH_CONTENTLIST_FAILED'

// Fetch Content Rating
export const FETCH_CONTENT_RATING_START = "FETCH_CONTENT_RATING_START"
export const SET_CONTENT_RATING = "SET_CONTENT_RATING"
export const FETCH_CONTENT_RATING_FAILED = "FETCH_CONTENT_RATING_FAILED"

// post Content Rating
export const POST_CONTENT_RATING_START = "POST_CONTENT_RATING_START"
export const POST_CONTENT_RATING_SUCCESS = "POST_CONTENT_RATING_SUCCESS"
export const POST_CONTENT_RATING_FAILED = "POST_CONTENT_RATING_FAILED"

// post Content Access
export const POST_CONTENT_ACCESS_START = "POST_CONTENT_ACCESS_START"
export const POST_CONTENT_ACCESS_SUCCESS = "POST_CONTENT_ACCESS_SUCCESS"
export const POST_CONTENT_ACCESS_FAILED = "POST_CONTENT_ACCESS_FAILED"

//Post Test Attempt
export const POST_TEST_ATTEMPT_START = "POST_TEST_ATTEMPT_START"
export const POST_TEST_ATTEMPT_SUCCESS = "POST_TEST_ATTEMPT_SUCCESS"
export const POST_TEST_ATTEMPT_FAILED = "POST_TEST_ATTEMPT_FAILED"

//User Course
export const FETCH_USERCOURSE_START = 'FETCH_USERCOURSE_START'
export const SET_USERCOURSE = 'SET_USERCOURSE'
export const FETCH_USERCOURSE_FAILED = 'FETCH_USERCOURSE_FAILED'


//User Master Course List
export const FETCH_MASTERCOURSE_START = 'FETCH_MASTERCOURSE_START'
export const SET_MASTERCOURSE = 'SET_MASTERCOURSE'
export const FETCH_MASTERCOURSE_FAILED = 'FETCH_MASTERCOURSE_FAILED'


// Post Assign Course
export const POST_ASSIGN_COURSE_START = "POST_ASSIGN_COURSE_START"
export const POST_ASSIGN_COURSE_SUCCESS = "POST_ASSIGN_COURSE_SUCCESS"
export const POST_ASSIGN_COURSE_FAILED = "POST_ASSIGN_COURSE_FAILED"

// user action
export const SET_CURRENT_COURSE = "SET_CURRENT_COURSE"
export const SET_CURRENT_SUBJECT = "SET_CURRENT_SUBJECT"
export const SET_CURRENT_OPERATION = "SET_CURRENT_OPERATION"
// user current content
export const SET_CURRENT_CONTENT = "SET_CURRENT_CONTENT"
export const CLEAR_CURRENT_CONTENT = "CLEAR_CURRENT_CONTENT"
export const UPDATE_ACCESS_DURATION = "UPDATE_ACCESS_DURATION"
export const UPDATE_CURRENT_CONTENT = "UPDATE_CURRENT_CONTENT" //for marksObtained,cardSatus

// user action Test
export const SET_CURRENT_TESTS = "SET_CURRENT_TESTS"
export const UPDATE_TEST_ATTEMPT_COUNT = "UPDATE_TEST_ATTEMPT_COUNT"
export const UPDATE_TEST_ATTEMPT_DURATION = "UPDATE_TEST_ATTEMPT_DURATION"

//questions
export const FETCH_TESTQUESTIONS_START = 'FETCH_TESTQUESTIONS_START'
export const SET_TESTQUESTIONS = "SET_TESTQUESTIONS"
export const FETCH_TESTQUESTIONS_FAILED = 'FETCH_TESTQUESTIONS_FAILED'
//answer
export const SET_TEST_ANSWERS = "SET_TEST_ANSWERS"

// update question answer
export const UPDATE_QUESTION_ANSWER = 'UPDATE_QUESTION_ANSWER'
export const UPDATE_ANSWER_DURATION = 'UPDATE_ANSWER_DURATION'

//Fetch Shared Data 
export const FETCH_SHARED_DATA_START = "FETCH_SHARED_DATA_START"
export const SET_SHARED_DATA = "SET_SHARED_DATA"
export const FETCH_SHARED_DATA_FAILED = "FETCH_SHARED_DATA_FAILED"

//Post Shared Data
export const POST_SHARED_DATA_START = "POST_SHARED_DATA_START"
export const POST_SHARED_DATA_SUCCESS = "POST_SHARED_DATA_SUCCESS"
export const POST_SHARED_DATA_FAILED = "POST_SHARED_DATA_FAILED"

// Meetings
export const SET_MEETINGS_SCHEDULE = 'SET_MEETINGS_SCHEDULE'

//Assignments 
export const SET_ACTIVE_ASSIGNMENTS = "SET_ACTIVE_ASSIGNMENTS"
export const SET_CURRENT_ASSIGNMENT = "SET_CURRENT_ASSIGNMENT"
//Unread Count
export const SET_METADATA = "SET_METADATA"

// Notification
export const SET_NOTIFICATION = "SET_NOTIFICATION"

//Session Recording Structure
export const FETCH_SR_STRUCTURE_START = 'FETCH_SR_STRUCTURE_START'
export const SET_SR_STRUCTURE = 'SET_SR_STRUCTURE'
export const FETCH_SR_STRUCTURE_FAILED = 'FETCH_SR_STRUCTURE_FAILED'

// Recorded sessions
export const FETCH_RECORDED_SESSIONS_START = 'FETCH_RECORDED_SESSIONS_START'
export const SET_RECORDED_SESSIONS = 'SET_RECORDED_SESSIONS'
export const FETCH_RECORDED_SESSIONS_FAILED = 'FETCH_RECORDED_SESSIONS_FAILED'
export const SET_CURRENT_SESSION = "SET_CURRENT_SESSION"
export const CLEAR_CURRENT_SESSION = "CLEAR_CURRENT_SESSION"

// Reset Password
export const SET_USER_verification_STATUS = "SET_USER_verification_STATUS"
export const SET_OTP_verification_STATUS = "SET_OTP_verification_STATUS"


// Self Registration
export const SET_SELF_REGISTRATION_DATA = 'SET_SELF_REGISTRATION_DATA'
export const SET_CITY_STATE_COUNTRY_DATA = 'SET_CITY_STATE_COUNTRY_DATA'
export const INIT_REGISTER_USER = 'INIT_REGISTER_USER'
export const SET_REGISTRATION_USERID = 'SET_REGISTRATION_USERID'

// Schedule Demo 
export const SET_BOARD_LIST = 'SET_BOARD_LIST'
export const SET_COURSE_LIST = 'SET_COURSE_LIST'
export const SET_SUBJECT_LIST = 'SET_SUBJECT_LIST'
export const SET_SCHEDULE_LIST = 'SET_SCHEDULE_LIST'