import React from 'react';

// @material-ui/core
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
//Custom Components
import ContentListItem from 'components/Views/Tasks/ContentTree/ContentListItem'


// styles
import useStyles from "assets/jss/components/views/taskStyle";


const ContentList = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    // 11	AV Uploaded
    // 12	AV External Link
    // 13	Youtube Link
    // 14	PDF Uploaded
    // 15	PDF Link
    // 16	HTML Created
    // 17	HTML Link
    // 18	Objective Test
    // 19	Subjective Test
    // 20	Activity

    let Content = null;

    switch (props.content.contentTypeCode) {
        case ('11'):
        case ('12'):
        case ('13'):
            Content = <ContentListItem content={{ ...props.content }} level={props.level} icon={'icon-Jcircumflex'} link={sm ? '/player' : '/task'} />
            break;
        case ('14'):
        case ('15'):
            Content = <ContentListItem content={{ ...props.content }} level={props.level} icon={'icon-uni0183'} link={sm ? '/pdf' : '/task'} /> /*link={'/pdf'} */
            break;
        case ('16'):
            Content = <ContentListItem content={{ ...props.content }} level={props.level} icon={'icon-uni018B'} link={sm ? '/contentviewer' : '/task'} />  /*link={'/contentviewer'} */
            break;
        case ('17'):
            Content = <ContentListItem content={{ ...props.content }} level={props.level} icon={'icon-uni018B'} link={sm ? '/viewer' : '/task'} />/*link={'/viewer'} */
            break;
        case ('18'):
        case ('19'):
            Content = <ContentListItem content={{ ...props.content }} level={props.level} icon={'icon-Wcircumflex'} link={sm ? '/instruction' : '/task'} /> /*link={'/instruction'} */
            break;
        case ('20'):
            Content = <ContentListItem content={{ ...props.content }} level={props.level} icon={'icon-uni22FC'} link={sm ? '/activity' : '/task'} />/*link={'/activity'}  */
            break;
        case ('30'):
            Content = <ContentListItem content={{ ...props.content }} level={props.level} icon={'icon-uni0183'} link={sm ? '/assignmentviewer' : '/task'} />/*link={'/activity'}  */
            break;
        default:
            Content = null;
    }

    return (
        <React.Fragment>
            {Content}
        </React.Fragment>

    )
}




export default ContentList;