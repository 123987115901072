import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';


const SkeletonCard = (props) => {

    return (
        <section className="component component-notify">
            <div className="card bg-dark">
                <div className="card__body">
                    <div className="card__list">

                        {['001', '010'].map((data, idex) => (
                            <div key={"Notif#"+idex} className="card__item">
                                <div className="status bg--green">
                                    <Skeleton animation="wave" variant="rect" className={`status`} />
                                </div>
                                <div className="wrapper">
                                    <div className="d-flex justify-content-between">
                                        <Skeleton className="meta name" animation="wave"></Skeleton>
                                        <Skeleton className="meta time" animation="wave"></Skeleton>

                                    </div>
                                    <Skeleton className="meta title" animation="wave"></Skeleton>
                                    <Skeleton className="meta text" animation="wave"></Skeleton>

                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </section>

    )
}

export default SkeletonCard;
