import * as actionTypes from 'store/definitions/actionTypes'

const initialState = {
    active_assignments: [],
    current_assignment: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // ------- active assignments -------------
        case actionTypes.SET_ACTIVE_ASSIGNMENTS:
            return {
                ...state,
                active_assignments: action.assignments
            }
        // ------- current assignments -------------
        case actionTypes.SET_CURRENT_ASSIGNMENT:
            return {
                ...state,
                current_assignment: action.assignments
            }
        default:
            return state;
    }

}


export default reducer