import * as actionTypes from '../definitions/actionTypes';
// import axios
import axiosConfig from "shared/interceptor/axiosInstance";


export const fetchRecordedSessionsgStart = () => {
    return {
        type: actionTypes.FETCH_RECORDED_SESSIONS_START
    }
};

export const fetchRecordedSessionsSuccess = (contentstructure) => {
    return {
        type: actionTypes.SET_RECORDED_SESSIONS,
        data: contentstructure
    }
};

export const fetchRecordedSessionsFailed = () => {
    return {
        type: actionTypes.FETCH_RECORDED_SESSIONS_FAILED
    }
};


export const fetchRecordedSessions = () => {
    return (dispatch, getState) => {
        const courseCode = getState().course.current_course.courseCode
        dispatch(fetchRecordedSessionsgStart());
        axiosConfig().get('AppCore/GetSessionRecordingStructure', {
            params: {
                CourseCode: courseCode
            }
        }).then(response => {
            dispatch(fetchRecordedSessionsSuccess(response.data))

        }).catch(function (error) {
            dispatch(fetchRecordedSessionsFailed())
            console.log(error);
        });
    }
}


//-----set current session-----------------
export const setCurrentSession = (current_session) => {
    return {
        type: actionTypes.SET_CURRENT_SESSION,
        session: current_session
    }
}
