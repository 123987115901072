import React, { useRef, useEffect, useState } from "react";
//redux
import { connect } from 'react-redux'

// chart
import { Pie, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "shared/components/Grid/GridItem.js";
import GridContainer from "shared/components/Grid/GridContainer.js";
import Card from "shared/components/Card/Card.js";
import CardHeader from "shared/components/Card/CardHeader.js";
import CardBody from "shared/components/Card/CardBody.js";
//Utility
import { roundPercentageNumber } from 'shared/utils/commonfunctions';
import {
  barchartOptions,
  groupedBarchartOptions,
  piechartOptions,
  getQuestBarchart,
  groupedChartData,
} from './DashboardUtility'
//style
import styles from "assets/jss/components/views/dashboardStyle";

const useStyles = makeStyles(styles);

const Dashboard = (props) => {
  const classes = useStyles();
  const pieRef = useRef(null);
  const qustBarRef = useRef(null);
  const missionBarRef = useRef(null);
  const operationBarRef = useRef(null);

  const [qustPieData, setQuestPieData] = useState({});
  const [qustBarData, setQuestBarData] = useState({});
  const [missionBarData, setMissionBarData] = useState({})
  const [operationBarData, setOperationBarData] = useState({})

  const { page__title } = props

  useEffect(() => {
    page__title('Reports')
  }, [])


  useEffect(() => {
    populateCoursePie()
    populateCourseBar()
    subjectsProgress()
  }, [])

  // ----------Populate Course Pie--------
  const populateCoursePie = () => {
    const pieChartdata = {
      datasets: [{
        data: [Math.round(props.currentCourseProgress.progress), Math.round(100 - props.currentCourseProgress.progress)],
        backgroundColor: ['#B0D851', '#EC9B4A']
      }],
      labels: ['Completed', 'Remaining'],
    };
    setQuestPieData(pieChartdata)
  }

  // ----------Populate Course Bar--------
  const populateCourseBar = async () => {
    const questBarchartData = await getQuestBarchart(props.currentCourseProgress)
    await setQuestBarData(questBarchartData)
  }

  // ----------Subjects Progress --------
  const subjectsProgress = async () => {
    const SubjectProgress = await groupedChartData(props.contentList.Add.Subject, 'structureDisplayName', 'structureId')
    setMissionBarData(SubjectProgress)

    //ChapterProgress
    if (Object.keys(SubjectProgress).length) {
      if (SubjectProgress.datasets[0]) {
        if (SubjectProgress.datasets[0].ids[0])
          chapterProgress(SubjectProgress.datasets[0].ids[0].id)
      }
    }
  }

  const clickHandler = (evt) => {
    const activePoint = evt[0];
    if (activePoint) {
      const data = activePoint._chart.data;
      const datasetIndex = activePoint._datasetIndex;
      const id = data.datasets[datasetIndex].ids[activePoint._index].id;
      chapterProgress(id)
    }
  }
  // ----------Chapter Progress--------
  const chapterProgress = async (StructureId) => {


    let chartData = await groupedChartData(
      props.contentList.Add.Chapter.filter(current_value => current_value.parentStructureId === StructureId),
      'structureDisplayName',
      'structureCode'
    )
    setOperationBarData(chartData)
  }

  return (


        <div className="page-content__body">
          <section className="component bg-white shadow">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card chart>
                  <CardHeader >
                    <h4 className={classes.cardTitle}>Course wise</h4>
                  </CardHeader>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CardBody color="warning">
                        <Pie
                          data={{ ...qustPieData }}
                          options={piechartOptions}
                          ref={pieRef}
                        />
                      </CardBody>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CardBody color="warning">
                        <Bar
                          data={{ ...qustBarData }}
                          options={barchartOptions}
                          ref={qustBarRef}
                        />
                      </CardBody>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <Card chart>
                  <CardHeader >
                    <h4 className={classes.cardTitle}>Level 1</h4>
                  </CardHeader>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CardBody color="warning">
                        <Bar
                          data={{ ...missionBarData }}
                          options={groupedBarchartOptions}
                          ref={missionBarRef}
                          getElementAtEvent={e => clickHandler(e)}
                        />
                      </CardBody>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>


              <GridItem xs={12} sm={12} md={12}>
                <Card chart>
                  <CardHeader >
                    <h4 className={classes.cardTitle}>Level 2</h4>
                  </CardHeader>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CardBody color="warning">
                        <Bar
                          data={{ ...operationBarData }}
                          options={groupedBarchartOptions}
                          ref={operationBarRef}
                        />
                      </CardBody>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>

            </GridContainer>

          </section>
        </div>

  );
}

const mapSateToProps = state => {
  return {
    currentCourseProgress: state.content.current_course_progress,
    contentList: state.content.contentlist,
  }
}

export default connect(mapSateToProps, null)(Dashboard);
