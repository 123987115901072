import React, { useState, useEffect } from "react";
//Redux
import { connect } from 'react-redux'

import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

// import @material-ui/icons
import ExpandLess from '@material-ui/icons/RemoveCircleOutline';
import ExpandMore from '@material-ui/icons/AddCircleOutline';
import Collapse from '@material-ui/core/Collapse';



// import LinearProgressWithLabel from 'shared/components/Progress/LinearProgressWithLabel'
import Searchbar from 'shared/components/Searchbar/Searchbar'

// Views
import ContentList from 'components/Views/Tasks/ContentTree/ContentList'

// styles
import useStyles from "assets/jss/components/views/operationStyle";
import '../style.css'

//import reducer 
import * as questActions from 'store/actions'



const ListItems = (props) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);

    useEffect(() => {
        setData(props.chaptersList)
        setData1(props.topicsList)
    }, [props.chaptersList,props.topicsList])

    const handleClick = (current_value) => {
        const newData = data.map(value => {
            value.collapse = value.structureId === current_value.structureId ? !value.collapse : false
            return value
        })
        setData(newData)
    }

    const handleTopicClick = (current_topic) => {
        
        const newTopicsData = data1.map(topic => {
            topic.collapse = topic.structureId === current_topic.structureId ? !topic.collapse : false;
            return topic;
        });
        
        setData1(newTopicsData);
    }

    // console.log(props.topicsList , "topicsList");
    // console.log(props.chaptersList , "chaptersList");
    

    return (
        <React.Fragment>
            {data.map(current_chapter =>
                <div key={current_chapter.structureId}>
                    <Divider variant="fullWidth" classes={{ root: classes.divider1 }} />
                    <ListItem button onClick={() => handleClick(current_chapter)} className={current_chapter.collapse ? classes.nested_chapter_in : classes.nested_chapter_out}>
                        <ListItemText
                            disableTypography={true}
                            primary={<Typography className={classes.chapter}>{current_chapter.structureDisplayName}</Typography>}
                        />
                        {current_chapter.collapse
                            ? <ExpandLess fontSize="small" style={{ color: "#46494B" }} />
                            : <ExpandMore fontSize="small" style={{ color: "#46494B" }} />}
                    </ListItem>
    
                    <Collapse in={current_chapter.collapse} timeout={500} unmountOnExit>
                        {/* Content directly under the chapter */}
                        {props.contentList.Add.contentstructure.filter(current_content => current_content.structureId === current_chapter.structureId).map((submenu, index) =>
                            <ContentList content={{ ...submenu }} key={submenu.courseContentId} level='one' />
                        )}
    
                        {/* Topics under the chapter */}
                        {props.topicsList.filter(current_topic => current_topic.parentStructureId === current_chapter.structureId).map((topic, index) =>
                            <React.Fragment key={topic.structureId}>
                                <ListItem button onClick={() => handleTopicClick(topic)} className={topic.collapse ? classes.nestedL1 : classes.nested}>
                                     <ListItemIcon >
                                            <Icon className={'icon-uni019E'} fontSize="small"></Icon>
                                     </ListItemIcon>
                                    <ListItemText
                                        disableTypography={true}
                                        primary={<Typography className={classes.topic}>{topic.structureDisplayName}</Typography>}
                                    />
                                    {topic.collapse
                                        ? <ExpandLess fontSize="small" style={{ color: "#46494B" }} />
                                        : <ExpandMore fontSize="small" style={{ color: "#46494B" }} />}
                                </ListItem>
                                <Collapse in={topic.collapse} timeout={500} unmountOnExit>
                                    {/* Content directly under the topic */}
                                    {props.contentList.Add.contentstructure.filter(current_content => current_content.structureId === topic.structureId).map((submenu, index) =>
                                        <ContentList content={{ ...submenu }} key={submenu.courseContentId} level='two' />
                                    )}
                                    {/* Subtopics under the topic */}
                                    {props.subTopicsList.filter(current_subtopic => current_subtopic.parentStructureId === topic.structureId).map((subtopic, index) =>
                                        <React.Fragment key={subtopic.structureId}>
                                            <ListItem className={classes.nestedL1}>
                                                <ListItemText
                                                    disableTypography={true}
                                                    primary={<Typography className={classes.topic}>{subtopic.structureDisplayName}</Typography>}
                                                />
                                            </ListItem>
                                            {/* Content directly under the subtopic */}
                                            {props.contentList.Add.contentstructure.filter(current_content => current_content.structureId === subtopic.structureId).map((submenu, index) =>
                                                <ContentList content={{ ...submenu }} key={submenu.courseContentId} level='three' />
                                            )}
                                        </React.Fragment>
                                    )}
                                </Collapse>
                            </React.Fragment>
                        )}
                    </Collapse>
                </div>
            )}
        </React.Fragment>
    );
    
}


const ChapterCards = (props) => {
    const classes = useStyles();
    const [searchInput, setSearchInput] = useState("")

    const chaptersList = props.contentList.Add.Chapter.filter(current_value => current_value.parentStructureId === props.subject.structureId && current_value.structureDisplayName.toUpperCase().includes(searchInput)).map((data, i) => {
        if (i === 0)
            data.collapse = true
        return data
    })

    const topicsList = props.contentList.Add.Topic.filter(topic =>
        chaptersList.some(chapter => chapter.structureId === topic.parentStructureId) &&
        topic.structureDisplayName.toUpperCase().includes(searchInput)
    ).map(topic => {
        // topic.collapse = false;
        return topic;
    });

    // const topicsList = props.contentList.Add.Topic
    const subTopicsList = props.contentList.Add.SubTopic


    const handleInputChange = (input) => {
        setSearchInput(input.toUpperCase())
    }


    return (
        <List >
            <Searchbar inputChange={handleInputChange} />

            <ListItems
                subject={props.subject}
                chaptersList={chaptersList}
                topicsList={topicsList}
                subTopicsList={subTopicsList}
                contentList={props.contentList}
                searchInput={searchInput}
            />
        </List>

    )
}


const mapStateToProps = state => {
    return {
        subject: state.content.current_subject,
        operation: state.content.current_operation
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentOperation: (operation) => dispatch(questActions.setCurrentOperation(operation))
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(ChapterCards);




