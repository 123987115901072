import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

// import material component
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

//Redux
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'store/actions/index'

// custom style
import useStyles from "./Styles";



export default function ThankYou() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval((progress) => {
      setProgress((prevProgress) => prevProgress + 10);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (progress === 100) {
      handleLogin()
    }
  }, [progress])

  const handleLogin = async () => {
    await dispatch(actions.initIP()).then(() => {
      login()
    }).catch(error => {
      login()
    })

  }

  const login = async () => {
    await dispatch(actions.loginNewUser()).then(async () => {
      await dispatch(actions.setAuthError(false))
      await dispatch(actions.initUserInfo())
      history.push('/intermediate')
    }).catch(error => {
      dispatch(actions.setAuthError(true))
      console.log(error.message);
    });
  }


  return (


    <div className="page-content__body">
      <section className="component component-welcome bg-white shadow" style={{ textAlign: 'center' }}>
        <h3 className="welcome__msg">welcome <span>Thank You</span>!</h3>
        <p className="welcome__text">Your Zeptolearn login credentials and other important information has been mailed to you. </p>
        <p className="welcome__text">You will be automatically redirect in 10 seconds, if not <a onClick={() => handleLogin()} className={classes.link}>click here</a></p>

        <Box position="relative" display="inline-flex">
          <CircularProgress variant="determinate" value={progress} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
              progress / 10,
            )}s`}</Typography>
          </Box>
        </Box>
      </section>


    </div>

  );
}