// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import {
    roseColor,
    whiteColor,
    primaryColor,
    boxShadow,
} from "assets/jss/material-dashboard-react.js";

const useStyles = makeStyles((theme) => ({

    gridContainer: {
        margin: 0,
    },
    massageDiv: {
        height: ' 100%',
        margin: 'auto',
        justifyContent: 'center',
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center'
    },
    appBar: {
        backgroundColor: primaryColor[0],
        color: whiteColor,
    },

    cardContainer: {
        justifyContent: "center"
    },
    activeCard: {
        marginBottom: "30px",
        marginTop: "30px",
        borderRadius: 10,
        ...boxShadow
    },
    deactivateCard: {
        marginBottom: "30px",
        marginTop: "30px",
        opacity: 0.8,
        borderRadius: 10,
        ...boxShadow
    },
    contentText1: {
        textAlign: 'center',
        // borderRightStyle: 'groove',
        padding: '0px 15px 0px 0px'
    },
    contentText2: {
        textAlign: 'center',
        borderLeftStyle: 'groove',
        padding: '0px 15px 0px 15px'
    },
    // contentText3: {
    //     textAlign: 'center',
    //     padding: '0px 15px 0px 15px'
    // },
    webTopicCard: {
        height: '100%',
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        margin: 0
    },
    cardMedia: {
        objectFit: 'none',
        backgroundColor: ' #D4DFF9'
    },

    // temp
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: roseColor[0],
    },

    //one line height
    topicTitle: {
        fontWeight: 'bold',
        color: '#000000',
        padding: '0 30px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
    },


    //one line height
    operationCardTitle: {
        fontWeight: 'bold',
        color: '#000000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        /* line-height: 16px; */
        /* max-height: 32px; */
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
    },

    //two line height
    contentCardTitle: {
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        height: '3em',
        /* line-height: 16px; */
        /* max-height: 32px; */
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
    },

    //GridContainer for mobile view
    mobileGridContainer: {
        margin: 0,
    },

    //ContentCard.js
    contentCardStyle: {
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 10,
        cursor: 'pointer',
        boxShadow: '0 2px 2px -5px rgba(0, 0, 0, 0.42), 0 1px 10px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
    },

    vedioContainer: {
        height:'calc(100vh - 145px)',
        overflow: "overlay",
        marginRight: '-15px'
    },

    fullscreenContainer: {
        height: 'calc(100vh - 100px)'
    },

    operationCardContainer: {
        height: 'calc(100vh - 180px)',
        overflow: "overlay",
        marginRight: '-30px',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            paddingRight: 5,
        },
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
      },


}))

export default useStyles;