import * as actionTypes from 'store/definitions/actionTypes'

const initialState = {
    recorded_sessions: [],
    current_session: {},
    error: false,
    loading: true,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_RECORDED_SESSIONS_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SET_RECORDED_SESSIONS:
            return {
                ...state,
                recorded_sessions: action.data,
                loading: false
            }
        case actionTypes.FETCH_RECORDED_SESSIONS_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }
        //-----set current session-----------------
        case actionTypes.SET_CURRENT_SESSION:
            return {
                ...state,
                current_session: { ...action.session }
            }

        default:
            return state;
    }

}


export default reducer