import React, { useEffect, useState } from "react";
import moment from 'moment'
// @material-ui/core
import DateRangeIcon from '@material-ui/icons/DateRange';

//import reducer 
import { useDispatch, useSelector } from 'react-redux'
import * as actions from "components/Views/Schedules/store/actions/index"

//Skeleton
import SkeletonCard from './SkeletonCard';

// styles
import useStyles from "assets/jss/components/views/homeStyle";

const bgColor = [
    'bg--green',
    'bg--pink',
    'bg--violet'
]



const UpcomingClassesCards = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const upcomingOnlineClasses = useSelector(state => state.onlineclasses.upcomingOnlineClasses)

    const [loading, setLoading] = useState(true)
    const [upcomingClasses, setUpcomingClasses] = useState([])


    useEffect(() => {
        fetchPreviousSession()
    }, [])


    const fetchPreviousSession = async () => {
        setLoading(true)
        dispatch(actions.fetchUpcomingOnlineClasses()).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error);
        });
    }

    // format data
    function groupBy(data, property) {
        return data.reduce((acc, obj) => {
            const key = moment(obj[property]).format('DD MMM YYYY');
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    useEffect(() => {
        const rawData = groupBy(upcomingOnlineClasses, 'startTime')
        setUpcomingClasses(rawData)
    }, [upcomingOnlineClasses])


    if (loading) {
        return (
            <SkeletonCard bgColor={bgColor} />
        )
    }

    return (
        <section className="component component-upcoming mt-0">
            <div className="card bg-white">
                <div className="card__head">
                    <h6 className="card__title">upcoming classes</h6>
                    <DateRangeIcon className="material-icons ms-auto color-red" />
                </div>

                {upcomingOnlineClasses.length === 0
                    ? <span className="meta">
                        There are no upcoming classes available
                    </span>
                    : <div className="card__body">
                        <div className="card__list">
                            {Object.keys(upcomingClasses).map((data, idex) => (
                                <div key={idex + "_#UC_" + data.scheduleId} className="card__item card__item-group">
                                    <span className="date">{data}</span>
                                    {upcomingClasses[data].map((ucdata, idx) => (
                                        <div key={idx + "_" + idex + "#" + data.scheduleId} className="card__item">
                                            <div className={`status ${bgColor[(idex + idx) % 3]}`}>
                                            </div>
                                            <div className="wrapper">
                                                <span className="meta title">{ucdata.scheduleName}</span>
                                                <span className="meta name">{ucdata.subjectName}</span>
                                                <span className="meta text">{ucdata.batchName}: {ucdata.sTime} - {ucdata.duration}min</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
        </section>
    );
}

export default UpcomingClassesCards;
