
import { roundPercentageNumber } from 'shared/utils/commonfunctions';


const afterTickToLabelConversion = (scaleInstance) => {
    // set the first and last tick to null so it does not display
    // note, ticks[0] is the last tick and ticks[length - 1] is the first
    scaleInstance.ticks[0] = null;
    scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;

    // need to do the same thing for this similiar array which is used internally
    scaleInstance.ticksAsNumbers[0] = null;
    scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
}


const groupedBarchartOptions = {
    responsive: true,
    legend: {
        position: 'bottom',
    },
    animation: {
        animateScale: true,
        animateRotate: true
    },
    scales: {
        xAxes: [{
            ticks: {
                callback: function (tick) {
                    var characterLimit = 20;
                    if (tick.length >= characterLimit) {
                        return tick.slice(0, tick.length).substring(0, characterLimit - 1).trim() + '...';;
                    }
                    return tick;
                }
            }
        }],
        yAxes: [{
            display: true,
            afterTickToLabelConversion: afterTickToLabelConversion,
            scaleLabel: {
                display: true,
                labelString: 'Completed(%)'
            },
            ticks: {
                beginAtZero: true,
                steps: 10,
                stepSize:20,
                max: 110
            }
        }]
    },
    plugins: {
        labels: {
            render: 'value',
            fontStyle: 'bold',
            showZero: false,
        }
    }
}


const barchartOptions = {
    responsive: true,
    legend: {
        display: false,
        position: 'bottom',
    },
    animation: {
        animateScale: true,
        animateRotate: true
    },
    scales: {
        yAxes: [{
            display: true,
            afterTickToLabelConversion: afterTickToLabelConversion,
            scaleLabel: {
                display: true,
                labelString: 'Completed(%)'
            },
            ticks: {
                beginAtZero: true,
                steps: 10,
                // stepValue: 5,
                stepSize: 20,
                max: 110
            }
        }]
    },
    plugins: {
        labels: {
            render: 'value',
            fontStyle: 'bold',
            showZero: false,
        }
    }
}

const piechartOptions = {
    responsive: true,
    legend: {
        position: 'bottom',
    },
    animation: {
        animateScale: true,
        animateRotate: true
    },
    plugins: {
        labels: {
            render: 'percentage',
            precision: 2,
            fontStyle: 'bold',
        }
    }
}

const getQuestBarchart=async (questProgress)=>{

    console.log(questProgress);
   
    const progressVideo = roundPercentageNumber(questProgress.videoCount,questProgress.totalVideoCount)
    const progressTest = roundPercentageNumber(questProgress.testCount, questProgress.totalTestCount)
    const progressHTML = roundPercentageNumber(questProgress.htmlCount, questProgress.totalHtmlCount)
    const progressPDF = roundPercentageNumber(questProgress.pdfCount, questProgress.totalPdfCount)
  
    return {
      datasets: [{
        data: [progressVideo, progressTest, progressHTML, progressPDF],
        backgroundColor: ['#89CDDF', '#AF65DF', '#19D4A7', '#E98B4D']
      }],
      labels: ['Video', 'Test', 'Html', 'Pdf'],
    };

     
}

/**
 * Custom function that prepare grouped chart data.
 * @param dataArray:Array.
 * @param displayName:String.
 * @param idName:String.
 * @returns {Number}
 */
const groupedChartData = async (dataArray, displayName, idName) => {
    let chartData = {
        labels: [],
        datasets: [
            {
                ids: [], label: "Video", backgroundColor: "#89CDDF", data: []
            },
            {
                ids: [], label: "Test", backgroundColor: "#AF65DF", data: []
            },
            {
                ids: [], label: "Html", backgroundColor: "#19D4A7", data: []
            },
            {
                ids: [], label: "Pdf",backgroundColor: "#E98B4D", data: []
            }
        ]
    };
    await dataArray.forEach(currentData => {

        chartData.labels.push(currentData[displayName])
        chartData.datasets.forEach(currentValue => {
            currentValue.ids.push({ 'label': currentData[displayName], 'id': currentData[idName] })
            if (currentValue.label === "Video")
                currentValue.data.push(roundPercentageNumber(currentData.progress.videoCount, currentData.progress.totalVideoCount))
            if (currentValue.label === "Test")
                currentValue.data.push(roundPercentageNumber(currentData.progress.testCount, currentData.progress.totalTestCount))
            if (currentValue.label === "Html")
                currentValue.data.push(roundPercentageNumber(currentData.progress.htmlCount, currentData.progress.totalHtmlCount))
            if (currentValue.label === "Pdf")
            currentValue.data.push(roundPercentageNumber(currentData.progress.pdfCount, currentData.progress.totalPdfCount))
        }
        )
    })
    return chartData;
}





export {
    barchartOptions,
    groupedBarchartOptions,
    piechartOptions,
    getQuestBarchart,
    groupedChartData,
}