import * as actionTypes from '../actionTypes'

const initialState = {
    scheduleList: {
        schedulesLists:[],
        assignmentLists:[]
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SCHEDULES:
            return {
                ...state,
                scheduleList: action.schedule
            }
        default:
            return state;
    }

}


export default reducer