import * as actionTypes from '../actionTypes'

const initialState = {
    schedule_list: [],
    metadata: {},
    teacher_list: [],
    teachers_availability: [],
    subject_list: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DOUBT_LIST:
            return {
                ...state,
                schedule_list: action.payload
            }
        case actionTypes.SET_ELIGIBLITY_FLAG:
            return {
                ...state,
                metadata: action.payload
            }
        case actionTypes.SET_TEACHERS_LIST:
            return {
                ...state,
                teacher_list: action.payload
            }
        case actionTypes.SET_TEACHERS_AVAILABILITY:
            return {
                ...state,
                teachers_availability: action.payload
            }
        case actionTypes.SET_DOUBT_SUBJECT_LIST:
            return {
                ...state,
                subject_list: action.payload
            }
        default:
            return state;
    }

}


export default reducer