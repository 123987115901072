import React, { useEffect, useState, lazy, Suspense } from "react";
import moment from 'moment'

//@material-ui/core
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// swiper
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

//reducer 
import { useDispatch, useSelector } from 'react-redux'
import * as actions from "components/Views/Schedules/store/actions/index"

//SkeletonCard
import SkeletonCard from './SkeletonCard';

// styles
import useStyles from "assets/jss/components/views/homeStyle";
// Swiper styles
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module

//util
import { hrefUrl, eventTracking } from 'shared/utils/commonfunctions';

// img
import GenericIcon from "assets/images/subject_icon/Subject_Icon_Generic.png";
import MathIcon from "assets/images/subject_icon/Subject_Icon_Math.png";
import ScienceIcon from "assets/images/subject_icon/Subject_Icon_Sci.png";

const ViewRecording = lazy(() => import('components/Views/ViewRecording'));


const LiveSessionCards = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const onlineClasses = useSelector(state => state.onlineclasses.todyasOnlineClasses)
  const [loading, setLoading] = useState(true)
  const [currentDataTime, setCurrentDataTime] = useState(moment().utc().add(330, 'm'))

  const [viewRecordingDialog, setRecordingDialog] = useState(false)
  const [recordingURL, setRecordingURL] = useState(false)


  /*----------------------------------------*/
  useEffect(() => {
    fetchLiveSession()
    let interval = setInterval(() => setCurrentDataTime(moment().utc().add(330, 'm')), 1000);
    return (() => {
      clearInterval(interval);
    })
  }, [])



  const fetchLiveSession = async () => {
    setLoading(true)
    dispatch(actions.fetchTodaysOnlineClasses()).then(() => {
      setLoading(false)
    }).catch(error => {
      setLoading(false)
      console.log(error);
    });
  }

  if (loading) {
    return (
      <SkeletonCard />
    )
  }

  // handle click upload 
  const handleViewRecording = (url) => {
    setRecordingURL(url)
    setRecordingDialog(true)
  }

  // close Dialogs
  const closeDialogs = () => {
    setRecordingURL("")
    setRecordingDialog(false)
  }

  const JoinButton = (props) => {
    const diff = moment(moment(props.startTime).format('YYYY-MM-DDTHH:mm:ss')).diff(moment(props.currentDataTime).format('YYYY-MM-DDTHH:mm:ss'), 'minutes')
    if (diff > 5) {
      return (<button className="btn btn--blue disabled">Upcoming</button>)
    }

    if (diff > 0 && (diff <= 5) || diff > -(props.duration)) {
      return (< a onClick={() => {
        dispatch(actions.joinSession(props.id))
        //Event Tracking
        eventTracking('JoinLiveSession', {})
        hrefUrl(props.mLink)
      }} >
        <button className="btn btn--blue">join now</button>
      </a>)
    }

    if (props.userInfo.isShowRecordedSession !== false && props.recordingURL !== "" && props.recordingURL !== undefined && props.recordingURL !== null) {
      return (<button className="btn btn--blue" onClick={() => handleViewRecording(props.recordingURL)}>View recording</button>)
    }
    return (<div />)
  }

  const SubjectIcon = ({ SubjectName }) => {
    switch (SubjectName.toUpperCase()) {
      case 'MATHS':
      case "MATHEMATICS":
        return <img src={MathIcon} alt="" />
      case 'SCIENCE':
        return <img src={ScienceIcon} alt="" />
      default:
        return <img src={GenericIcon} alt="" />
    }
  }

  return (
    <div className="component-livesession bg-white shadow">

      {/* View Recording Dialog*/}
      <Dialog open={viewRecordingDialog} maxWidth="lg" fullWidth={true} >
        {viewRecordingDialog && <Suspense fallback={<div > Loading...</div>}>
          <ViewRecording onClose={closeDialogs} data={recordingURL} />
        </Suspense>}
      </Dialog>


      <h5 className="component__title">today's live session</h5>
      {onlineClasses.length === 0
        ? <span className="meta">
          no live session today
        </span>
        : <>
          {onlineClasses.map((data, idex) => (
              <div className="card"  key={data.scheduleId + "LIVE" + idex}>
                <div className="card__body">
                  <span className="label border--red" id="subjectName">{data.courseDisplayName}</span>
                  <div className="wrapper">
                    <div className="content">
                      <span className="time">{data.subjectName} : {data.sTime} to {data.eTime}</span>
                      <span className="title">{data.scheduleName}</span>
                      {/* <a href={data.mLink} target="_blank" >
                        <button className="btn btn--blue">join now</button>
                      </a> */}
                      <JoinButton
                        id={data.scheduleId}
                        currentDataTime={currentDataTime}
                        startTime={data.startTime}
                        mLink={data.mLink}
                        recordingURL={data.recordingURL}
                        duration={data.duration}
                        userInfo={props.userInfo}
                      />
                    </div>
                    {/* <div className="media media__img img-1by1">
                     <div className="img-box">
                        <SubjectIcon SubjectName={data.subjectName} />
                      </div>
                    </div>  */}
                  </div>
                </div>
              </div>
          ))
          }
        </>}

    </div>
  );
}

export default LiveSessionCards;
