import * as actionTypes from '../definitions/actionTypes';
// import axios
import axiosConfig from "shared/interceptor/axiosInstance";
//import for initialize Content List &  User Progress
import { fetchContentListStart,initContentList } from "store/actions/contentList"




//--Master Course List -------
export const fetchMasterCourseStart = () => {
    return {
        type: actionTypes.FETCH_MASTERCOURSE_START
    }
}


export const setMasterCourse = (MasterCourse) => {
    return {
        type: actionTypes.SET_MASTERCOURSE,
        mastercourse: MasterCourse
    }
}

export const fetchrMasterCourseFailed = () => {
    return {
        type: actionTypes.FETCH_MASTERCOURSE_FAILED
    }
}

export const initMasterCourse = (userId) => {
    return dispatch => {
        dispatch(fetchMasterCourseStart())
        axiosConfig().get('/AppCore/GetCourseList').then(response => {
            dispatch(setMasterCourse(response.data))
            if (response.data.course.length)
                dispatch(initUserCourse(userId))
        }).catch(function (error) {
            dispatch(fetchrMasterCourseFailed())
        });
    }
}


//--- Assigned Course List -------
export const fetchUserCourseStart = () => {
    return {
        type: actionTypes.FETCH_USERCOURSE_START
    }
}


export const setUserCourse = (UserCourse) => {
    return {
        type: actionTypes.SET_USERCOURSE,
        usercourse: UserCourse
    }
}

export const fetchrUserCourseFailed = () => {
    return {
        type: actionTypes.FETCH_USERCOURSE_FAILED
    }
}

export const initUserCourse = (userId, courseCode = null) => {
    return dispatch => {
        dispatch(fetchUserCourseStart())
        axiosConfig().get('/AppCore/GetUserCourseDetails', {
            params: {
                userId: userId,
            }
        }).then(response => {
            dispatch(setUserCourse(response.data,))
            if (response.data.courseList.length) {
                const COURSE_CODE = courseCode
                    ? courseCode
                    : response.data.courseList[0].courseCode
                dispatch(initContentList(COURSE_CODE, userId))
                const CurrentCourse = response.data.courseList
                    .filter(op => op.courseCode === COURSE_CODE)
                    .reduce((acc, cv) => { return acc = cv }, {})
                dispatch(setCurrentCourse(CurrentCourse))
            }

        }).catch(function (error) {
            dispatch(fetchrUserCourseFailed())
        });
    }
}

//set Current Course
export const setCurrentCourse = (CurrentCourse) => {
    return {
        type: actionTypes.SET_CURRENT_COURSE,
        current_course: CurrentCourse
    }
}


//----- Post Assign Course ------------------
export const postAssignCourseStart = () => {
    return {
        type: actionTypes.POST_ASSIGN_COURSE_START
    }
};

export const postAssignCourseSuccess = () => {
    return {
        type: actionTypes.POST_ASSIGN_COURSE_SUCCESS
    }
};

export const postAssignCourseFailed = () => {
    return {
        type: actionTypes.POST_ASSIGN_COURSE_FAILED
    }
};


export const postAssignCourse = (userId, courseCode) => {
    return dispatch => {
        dispatch(postAssignCourseStart());
        dispatch(fetchUserCourseStart());
        dispatch(fetchContentListStart());
        axiosConfig().post('course/assignCourse', null, {
            params: {
                userId: userId,
                courseCode: courseCode
            }
        })
            .then(response => {
                dispatch(postAssignCourseSuccess())
                dispatch(initUserCourse(userId, courseCode))
            }).catch((error) => {
                console.log(error);
                dispatch(postAssignCourseFailed())
            });
    }
}