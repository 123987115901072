
import { makeStyles } from "@material-ui/core/styles";



const Style = makeStyles((theme) => ({
  link: {
    color: '-webkit-link',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: 'red',
      textDecoration: 'underline',
    }
  }
}));

export default Style;