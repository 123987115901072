import React from 'react';


// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";


//import reducer 
import {  useSelector } from 'react-redux'

import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
// @material-ui/core
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// styles
import useStyles from "assets/jss/components/views/contentListItemStyle";

const ContentCard = (props) => {

    const { content, onClick, currentSessions } = props;

    const classes = useStyles();
    const loading = useSelector(state => state.recordedSessions.loading)

    const nestedClasses = classNames({
        [classes[props.level]]: props.level,
    });

    const onClickContentCard = async () => {
        onClick(content)
    }

    if (content.recordingURL === "") {
        return (
            <React.Fragment></React.Fragment>
        )
    }

    return (
        <ListItem 
        button 
        onClick={onClickContentCard} 
        className={nestedClasses}
        selected={currentSessions!==null?currentSessions.structureId===content.structureId:false}
        >
            {loading ?
                <div>Loading......</div>
                : <React.Fragment>
                    <ListItemIcon >
                        <Icon className={'icon-Jcircumflex'} fontSize="small" style={{ color: green[400] }}></Icon>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography className={classes.content}>View Session</Typography>}
                    />
                </React.Fragment>
            }
        </ListItem>
    )
}

ContentCard.defaultProps = {
    level: 'one',

};
ContentCard.propTypes = {
    level: PropTypes.oneOf(["one", "two"]),
};

export default ContentCard;



