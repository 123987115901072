import React from 'react';

// import material component
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

//import Custom Components
import PersonalInfo from './PersonalInfo'
import LoginDetails from '../DemoBooking/DemoBooking'

import { SwitchTransition, CSSTransition } from "react-transition-group";

// custom style
import useStyles from "assets/jss/components/views/signupStyle";

const modes = ["out-in", "in-out"];

export default function VerticalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ['Personal Info', 'Book Demo'];
  const [mode, setMode] = React.useState("out-in");
  const [state, setState] = React.useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setState(state => !state)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setState(state => !state)
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <PersonalInfo onNext={handleNext}></PersonalInfo>
      case 1:
        return <LoginDetails></LoginDetails>
      default:
        return 'Unknown step';
    }
  }


  return (

    <div className='signin-container background-signin'>

      <main className={classes.layout}>
        <Paper className={classes.paper}>

        
            <div  style={{ textAlign: 'center',paddingTop:24 }}>
              <Typography note="true" variant="subtitle1" paragraph display="inline" style={{ letterSpacing: 'normal',fontWeight:'bold' }}>
                {'Below information should be filled by Parents only'}
              </Typography>
           </div>

          <Stepper activeStep={activeStep} className={classes.stepperStyle} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <SwitchTransition mode={mode}>
            <CSSTransition
              key={activeStep}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="fade"
            >
              {/* <div className="page-container"> */}
              {getStepContent(activeStep)}
              {/* </div> */}
            </CSSTransition>
          </SwitchTransition>

        </Paper>
      </main>
    </div>
  );
}