import * as actionTypes from 'store/definitions/actionTypes'
import { percentageNumber } from 'shared/utils/commonfunctions';

const initialState = {
    contentlist: {
        Add: {
            contentstructure: [],
            Subject: [],
            Chapter: [],
            Topic: [],
            SubTopic: [],
        },
        Ludt: ""
    },
    current_course_progress: {
        "CourseCode": "",
        "Progress": 0,
        "HtmlCount": 0,
        "PdfCount": 0,
        "TestCount": 0,
        "VideoCount": 0
    },
    current_subject: {},
    current_operation: {},
    current_content: {},
    current_test: {},

    error: false,
    loading: true,

    error_sr_structure: false,
    loading_sr_structure: true,

    content_ratings_loading: true,
    content_ratings: {
        "likeCount": 0,
        "disLikeCount": 0,
        "viewCount": 0,
        "myRating": null
    },
    content_ratings_error: false,

    previous_content: {}
}

const getMergedArray = (contentList, progress, filterID) => {
    return contentList.map(questContents => {
        let mergedContents = { ...questContents }
        progress.forEach((currentValue) => {
            if (currentValue[filterID] === mergedContents[filterID])
                mergedContents['progress'] = { ...currentValue }
        })
        return mergedContents
    });
}

const getUpdatedProgress = (contentList, currentContent, marksObtained, filterID) => {
    return contentList.map(content => {
        let newContent = { ...content }
        let newContentProgress = { ...content.progress }
        if (content[filterID] === currentContent[filterID]) {
            newContentProgress = getTasksCounts(newContentProgress, currentContent)
            newContentProgress.points = Number(newContentProgress.points) + Number(marksObtained)
            const totalTask = newContentProgress.TotalVideoCount + newContentProgress.TotalTestCount + newContentProgress.TotalHtmlCount + newContentProgress.TotalPdfCount
            const completedTask = newContentProgress.VideoCount + newContentProgress.TestCount + newContentProgress.HtmlCount + newContentProgress.PdfCount

            newContentProgress.Progress = percentageNumber(completedTask, totalTask)
        }
        newContent.progress = newContentProgress
        return newContent
    })
}

const getTasksCounts = (newContentProgress, currentContent) => {
    if (['11', '12', '13'].indexOf(currentContent.ContentTypeCode) >= 0) // is Video 
    {
        newContentProgress.VideoCount = newContentProgress.VideoCount + 1
    }
    if (['15'].indexOf(currentContent.ContentTypeCode) >= 0) // is Test
    {
        newContentProgress.TestCount = newContentProgress.TestCount + 1
    }
    if (['14'].indexOf(currentContent.ContentTypeCode) >= 0) // is web content (HtmlCount)
    {
        newContentProgress.HtmlCount = newContentProgress.HtmlCount + 1
    }
    if (['16'].indexOf(currentContent.ContentTypeCode) >= 0) // is PDF
    {
        newContentProgress.PdfCount = newContentProgress.PdfCount + 1
    }
    return newContentProgress
}

const updatedQuestProgress = (currentQuestProgress, currentContent, marksObtained) => {

    currentQuestProgress = getTasksCounts(currentQuestProgress, currentContent)
    currentQuestProgress.points = Number(currentQuestProgress.points) + Number(marksObtained)

    const totalTask = currentQuestProgress.TotalVideoCount + currentQuestProgress.TotalTestCount + currentQuestProgress.TotalHtmlCount + currentQuestProgress.TotalPdfCount
    const completedTask = currentQuestProgress.VideoCount + currentQuestProgress.TestCount + currentQuestProgress.HtmlCount + currentQuestProgress.PdfCount

    currentQuestProgress.Progress = percentageNumber(completedTask, totalTask)

    return currentQuestProgress
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        //----- Fetch Content List------------------
        case actionTypes.FETCH_CONTENTLIST_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SET_CONTENTLIST:
            let newContentList = { Add: {} };
            [...Object.keys(action.levelmapping), 'contentstructure'].forEach(level => {
                if (level !== 'course') {
                    if (level === 'contentstructure') {
                        newContentList.Add.contentstructure = [...action.contentlist.contentList].map(newData => {
                            newData['progress'] = action.userprogress.contentProgress.filter(current_progress => current_progress.courseContentCode === newData.courseContentCode).reduce((acc, cv) => { return acc = cv }, {
                                courseContentCode: newData.courseContentCode,
                                accessCount: 0
                            })
                            return newData
                        })
                    } else {
                        newContentList.Add[action.levelmapping[level]] = action.contentlist.structureList
                            .filter(structure => structure.levelType.toLowerCase() === level)
                            .map(newData => {
                                newData['progress'] = action.userprogress[level].filter(current_progress => current_progress.levelId === newData.structureId).reduce((acc, cv) => { return acc = cv }, {
                                    levelId: newData.structureId,
                                    progress: 0
                                })
                                newData['collapse'] = false
                                return newData
                            })
                    }
                }
            })


            // console.log("----------------");
            // console.log(newContentList);
            // console.log(action.userprogress);
            // console.log(action.contentlist)

            let currentCourseProgress = action.userprogress.courseProgress.filter(current_progress => current_progress.courseCode === action.coursecode).reduce((acc, cv) => { return acc = cv }, {})

            return {
                ...state,
                contentlist: newContentList,
                current_course_progress: currentCourseProgress,
                error: false,
                loading: false
            }
        case actionTypes.FETCH_CONTENTLIST_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }

        //------------set current subject------------
        case actionTypes.SET_CURRENT_SUBJECT:
            let resetContentList = { ...state.contentlist }
            resetContentList.Add.Chapter.filter(current_value => current_value.SubjectCode === action.subject.SubjectCode).map((value, indx) => {
                value.collapse = indx === 0 ? true : false
                return value
            })
            resetContentList.Add.Chapter = [...resetContentList.Add.Chapter]
            return {
                ...state,
                contentlist: resetContentList,
                current_subject: { ...action.subject }
            }

        //------------set current operation--------------
        case actionTypes.SET_CURRENT_OPERATION:
            let operation = { ...state.current_operation }
            if (state.contentlist.Add.operations.length !== 0) {
                operation = action.operation
                    ? action.operation
                    : [...state.contentlist.Add.operations].filter(op => (op.missionId === state.current_mission.missionId) && op.sequenceNo == '1').reduce((acc, cv) => { return acc = cv }, {})
            }
            return {
                ...state,
                current_operation: operation
            }

        //-----set current content-----------------
        case actionTypes.SET_CURRENT_CONTENT:
            let newCurrent_content = { ...action.content };
            newCurrent_content['marksObtained'] = '0';
            newCurrent_content['pointCardSatus'] = false;
            return {
                ...state,
                current_content: { ...newCurrent_content }
            }



        //-----UPDATE current content-----------------
        case actionTypes.UPDATE_CURRENT_CONTENT: // for marksObtained,pointCardSatus
            let updatedCurrent_content = { ...state.current_content };
            let updatedContentList = { ...state.contentlist }
            let updatedCurrentCourseProgress = { ...state.current_course_progress }

            if (!(action.pointCardSatus && updatedCurrent_content.progress.AccessCount > 0)) {
                updatedCurrent_content['marksObtained'] = action.marksObtained;
                updatedCurrent_content['pointCardSatus'] = action.pointCardSatus;
            }

            if (action.accessCount !== 0) {
                //update Contents

                updatedContentList.Add.contentstructure = updatedContentList.Add.contentstructure.map(content => {
                    let newContent = { ...content }
                    let newContentProgress = { ...content.progress }
                    if (content.CourseContentCode === updatedCurrent_content.CourseContentCode)
                        newContentProgress.AccessCount = newContentProgress.AccessCount + 1
                    newContent.progress = newContentProgress
                    return newContent
                })

                if (updatedCurrent_content.progress.AccessCount === 0) {
                    //update Subject
                    updatedContentList.Add.Subject = getUpdatedProgress(updatedContentList.Add.Subject, updatedCurrent_content, action.marksObtained, 'SubjectCode')
                    //update Chapter
                    updatedContentList.Add.Chapter = getUpdatedProgress(updatedContentList.Add.Chapter, updatedCurrent_content, action.marksObtained, 'ChapterCode')
                    //update Topics
                    updatedContentList.Add.Topic = getUpdatedProgress(updatedContentList.Add.Topic, updatedCurrent_content, action.marksObtained, 'TopicCode')
                    //update SubTopic
                    updatedContentList.Add.SubTopic = getUpdatedProgress(updatedContentList.Add.SubTopic, updatedCurrent_content, action.marksObtained, 'SubTopicCode')
                    //Update Current Course Progress
                    updatedCurrentCourseProgress = updatedQuestProgress(updatedCurrentCourseProgress, updatedCurrent_content, action.marksObtained)
                }
            }

            return {
                ...state,
                current_content: updatedCurrent_content,
                contentlist: updatedContentList,
                current_course_progress: updatedCurrentCourseProgress
            }

        //-----Clear current content-----------------
        case actionTypes.CLEAR_CURRENT_CONTENT:
            const clearedCurrent_content = { ...initialState.current_content };
            return {
                ...state,
                current_content: { ...clearedCurrent_content, 'pointCardSatus': {} }
            }


        //-----update access duration content-----------------
        case actionTypes.UPDATE_ACCESS_DURATION:
            return {
                ...state,
                current_content: { ...state.current_content, 'accessDuration': action.accessDuration }
            }


        //-----set current Test-----------------
        case actionTypes.SET_CURRENT_TESTS:
            let missionTests = { ...action.current_test }
            missionTests.testAttemptedDuration = 0
            missionTests.testAttemptCount = 0
            missionTests.skipQuestionCount = 0
            missionTests.rightAnswerCount = 0
            missionTests.wrongAnswerCount = 0
            return {
                ...state,
                current_test: missionTests
            }

        //-----update Test Attempt Count-----------------
        // For check Current Test is attempted or not 
        case actionTypes.UPDATE_TEST_ATTEMPT_COUNT:
            let updateedMissionTests = { ...state.current_test }
            updateedMissionTests.testAttemptCount = updateedMissionTests.testAttemptCount + 1;
            updateedMissionTests.skipQuestionCount = action.skipCount
            updateedMissionTests.rightAnswerCount = action.rightCount
            updateedMissionTests.wrongAnswerCount = action.wrongCount
            updateedMissionTests.marksObtained = action.marksObtained

            return {
                ...state,
                current_test: updateedMissionTests
            }


        //-----Update Test Attempt Duration-----------------
        case actionTypes.UPDATE_TEST_ATTEMPT_DURATION:
            let updatedTests = { ...state.current_test }
            updatedTests.testAttemptedDuration = updatedTests.testAttemptedDuration + action.duration
            return {
                ...state,
                current_test: updatedTests
            }

        // ------- Post Content Rating-------------
        case actionTypes.POST_CONTENT_RATING_START:
            return {
                ...state,
                content_ratings_error: false,
                content_ratings_loading: true
            }

        case actionTypes.POST_CONTENT_RATING_SUCCESS:
            return {
                ...state,
                content_ratings_error: false,
                content_ratings_loading: false
            }

        case actionTypes.POST_CONTENT_RATING_FAILED:
            return {
                ...state,
                content_ratings_error: true,
                content_ratings_loading: false
            }

        // ------- Fetch Content Rating-------------
        case actionTypes.FETCH_CONTENT_RATING_START:
            return {
                ...state,
                content_ratings_error: false,
                content_ratings_loading: true
            }
        case actionTypes.SET_CONTENT_RATING:
            return {
                ...state,
                content_ratings: action.contentRatings,
                content_ratings_error: false,
                content_ratings_loading: false
            }
        case actionTypes.FETCH_CONTENT_RATING_FAILED:
            return {
                ...state,
                content_ratings_error: true,
                content_ratings_loading: false
            }

        // ------- Post Content Access-------------
        case actionTypes.POST_CONTENT_ACCESS_START:
            return {
                ...state,
                error: false,
                loading: true
            }

        case actionTypes.POST_CONTENT_ACCESS_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false
            }

        case actionTypes.POST_CONTENT_ACCESS_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }

        // ------- Session Recording Structure-------------
        case actionTypes.FETCH_SR_STRUCTURE_START:
            return {
                ...state,
                error_sr_structure: false,
                loading_sr_structure: true
            }

        case actionTypes.SET_SR_STRUCTURE:
            return {
                ...state,
                contentlist: { Add: { ...state.contentlist.Add, contentstructure: action.structure } },
                error_sr_structure: false,
                loading_sr_structure: false
            }

        case actionTypes.FETCH_SR_STRUCTURE_FAILED:
            return {
                ...state,
                error_sr_structure: true,
                loading_sr_structure: false
            }

        default:
            return state;
    }

}





export default reducer