import React, { useEffect, useState } from "react";
import moment from 'moment';


// Views
import LiveSession from './components/LiveSession/LiveSessionCards'
import PreviousSessions from './components/PreviousSessions/PreviousSessionCards'
import Assignments from './components/Assignments/AssignmentCards'
import Counts from "./components/Counts";
import TestsDashboard from "./components/Tests";
import UpcomingClasses from "./components/UpcomingClasses/UpcomingClasses";
// img
 import welcomeClipart from 'assets/images/welcome-clipart.png';

//Redux
import { useSelector } from 'react-redux'

//services
import { DashboardServices } from "services/DashboardServices/dashboard.services";


const HomeDashboard = (props) => {
  const { page__title } = props
  const userInfo = useSelector(state => state.user.userinfo)
  const today = moment().format("YYYY-MM-DD")

  const [assignments, setAssignments] = useState({})
  const [loadingAssignments, setLoadingAssignments] = useState(false)
  const fetchAssessments = () => {
    setLoadingAssignments(true)
    DashboardServices.fetchAssignmentList(
      (data) => {
        setAssignments(data)
      },
      (error) => {
        console.log(error.message);
      },
      () => { setLoadingAssignments(false) }
    )
  }

  
  const [tests, setTests] = useState({})
  const [loadingTests, setLoadingTests] = useState(false)
  const fetchTestList = () => {
    setLoadingTests(true)
    DashboardServices.fetchTestList(
      (data) => {
        setTests(data)
      },
      (error) => {
        console.log(error.message);
      },
      () => { setLoadingTests(false) }
    )
  }

  useEffect(() => {
    page__title('dashboard')
    fetchAssessments()
    fetchTestList()
  }, [])

  return (
    <React.Fragment>
      {/*  <div className="page-content__body"> */}
      <div className="page-content__body" style={{ minHeight: '100vh' }}>
        <section className="component component-welcome  shadow">
          <div className="img-box d-none d-sm-block">
            <img src={welcomeClipart} alt="" />
          </div>
          <h3 className="welcome__msg">welcome <span>{userInfo.firstName}</span>!</h3>
          <p className="welcome__text">
            <div
              dangerouslySetInnerHTML={{
                __html: userInfo.welcomeNote
              }} />
          </p>
        </section>

        <section className="component component-grid">
          <div className="row">
            <div className="col-md-8">


              {/* Counts */}
              <Counts 
              assignments={assignments} 
              tests={tests} 
              loading={loadingAssignments || loadingTests}
              />

               {/* Assignments and PreviousSessions */}
               <section className="component component-grid">
                <div className="row">
                  <div className="col-md-6">
                    <Assignments cdate={today} assignments={assignments} loading={loadingAssignments} />
                  </div>
                  <div className="col-md-6">
                    <TestsDashboard cdate={today} assignments={tests} loading={loadingTests}/>
                  </div>
                </div>
              </section>
            

              {/* LiveSession */}
              <section className="component component-grid">
                <div className="row">
                  <div className="col-md-6">
                    <LiveSession userInfo={userInfo} />
                  </div>
                  <div className="col-md-6">
                    <PreviousSessions />
                  </div>
                </div>
              </section>

              {/* Assignments and PreviousSessions */}
              {/* <section className="component component-grid">
                <div className="row">
                  <div className="col-md-6">
                    <Assignments cdate={today} assignments={assignments} loading={loadingAssignments} />
                  </div>
                  <div className="col-md-6">
                    <TestsDashboard cdate={today} assignments={tests} loading={loadingTests}/>
                  </div>
                </div>
              </section> */}
            </div>

            <div className="col-md-4">
              <UpcomingClasses />
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}


export default HomeDashboard;
