import React, { useEffect, useState } from "react";
import moment from 'moment'
import { useHistory } from "react-router-dom";
//Skeleton
import SkeletonCard from './SkeletonCard';


const Notification = (props) => {
    const { notification, loadingNotify } = props
    let history = useHistory();

    const getTimeDifference = (sendOn) => {
        let diff = moment(new Date()).diff(moment(sendOn), 'hours')
        if (diff > 24) {
            diff = moment(new Date()).diff(moment(sendOn), 'days')
            return `${diff} days ago`
        } else {
            return `${diff} hours ago`
        }
    }

    if (loadingNotify) {
        return (
            <SkeletonCard />
        )
    }
    const onClickNotification = (data) => {
        if (data.typeId === 6 && data.category === "Test")
            history.push({ pathname: "/home/assignments", state: { infoType: 2 } });
        else if (data.typeId === 6 && data.category === "Assignment")
            history.push({ pathname: "/home/assignments", state: { infoType: 1 } });
        else if (data.typeId === 6 && data.category === "Live Session")
            history.push({ pathname: "/home/schedules", state: { mappingId: data.mappingId } });
        else
            history.push({ pathname: "/home/messages", state: { mappingId: data.mappingId } });
    }

    return (
        <section className="component component-notify">
            <div className="card bg-dark">
                <div className="card__body">
                    <div className="card__list">
                        {notification.slice(0, 3).map(data => (
                            <div key={data.messageId} className="card__item" onClick={() => onClickNotification(data)}>
                                <div className="status bg--green">
                                </div>
                                <div className="wrapper">
                                    <div className="d-flex justify-content-between">
                                        <span className="meta name">notes</span>
                                        <span className="meta time">{getTimeDifference(data.sendOn)}</span>
                                    </div>
                                    <span className="meta title">{data.subject}</span>
                                    <span className="meta text" >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: data.messageBody
                                            }}
                                        ></div>
                                    </span>
                                </div>
                            </div>
                        ))}
                                               
                        {/* <div className="card__item">
                            <div className="status bg--green">

                            </div>
                            <div className="wrapper">
                                <div className="d-flex justify-content-between">
                                    <span className="meta name">biology</span>
                                    <span className="meta time">2 hours ago</span>
                                </div>
                                <span className="meta title">Chapter 1: Rational Numbers</span>
                                <span className="meta text">Download the notes for the chapter 03.</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Notification;
